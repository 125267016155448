import markerIcon from '@/assets/marker.png';

export default {
  name: 'GoogleMapMarker',
  props: {
    google: {
      type: Object,
      required: true,
    },
    map: {
      type: Object,
      required: true,
    },
    marker: {
      type: Object,
      required: true,
    },
    icon: {
      type: [Object, String],
      default: '',
    },
    markerClusterer: {
      type: [Object, null],
      default: null,
    },
  },
  render() {
    return '';
  },
  data() {
    return {
      markerInstance: null,
    };
  },
  mounted() {
    let { map } = this;
    if (this.markerClusterer) {
      map = null;
    }

    this.markerInstance = new this.google.maps.Marker({
      position: this.marker.position,
      marker: this.marker,
      title: this.marker.title,
      map,
      icon: this.getIcon(),
    });

    this.markerInstance.addListener('click', () => this.$emit('location:selected', this.marker));

    if (this.markerClusterer) {
      this.markerClusterer.addMarker(this.markerInstance);
    }
  },
  destroyed() {
    if (!this.markerInstance) {
      return;
    }

    if (this.markerClusterer) {
      this.markerClusterer.removeMarker(this.markerInstance, true);
    }

    this.google.maps.event.clearInstanceListeners(this.markerInstance);
    this.markerInstance.setMap(null);
  },
  methods: {
    getIcon() {
      if (typeof this.icon === 'object') {
        return this.icon;
      }

      if (this.icon !== '') {
        return { url: this.icon };
      }

      return markerIcon;
    },
  },
};
