/**
 * @param {Array} headers
 * @param {Array<Array<String,Number,Array>> or Array<String,Number,Array>} data
*/
export function createCsvString(data, headers) {
  let dateInValidFormat = [];
  if (data.some((e) => !(e.constructor === Array))
      && data.some((e) => !(e.constructor === Object))) {
    dateInValidFormat.push(data);
  } else {
    dateInValidFormat = Array.from(data);
  }
  if (dateInValidFormat.some((e) => !(e.constructor === Array))
  || dateInValidFormat.some((element) => element.some((obj) => obj.constructor === Object))) {
    return '';
  }
  let csvString = '';
  if (headers) {
    csvString = `${headers.join(';')}\n`;
  }
  dateInValidFormat.forEach((packOfData) => {
    const packOfDataWithTheSameLengthForAllRecords = [];
    const arraysInPackOfData = packOfData.filter((e) => typeof (e) === 'object');
    let maxLengthOfArray = 1;
    if (arraysInPackOfData.length !== 0) {
      // eslint-disable-next-line
      maxLengthOfArray = Math.max(...arraysInPackOfData.map(el => el.length | 0));
    }
    if (maxLengthOfArray <= 0) maxLengthOfArray = 1;
    packOfData.forEach((e) => {
      const arrayOfElements = [];
      arrayOfElements.push(e);
      const flatedArray = arrayOfElements.flat();
      if (maxLengthOfArray - flatedArray.length <= 0) {
        packOfDataWithTheSameLengthForAllRecords.push(flatedArray);
      } else {
        packOfDataWithTheSameLengthForAllRecords.push(flatedArray.concat(Array(maxLengthOfArray - flatedArray.length).fill('')));
      }
    });
    const matrixOfReorganizedElements = new Array(maxLengthOfArray).fill(0).map(
      () => new Array(packOfData.length).fill(0),
    );
    packOfDataWithTheSameLengthForAllRecords.forEach((element, index) => {
      element.forEach((row, i) => {
        matrixOfReorganizedElements[i][index] = row;
      });
    });
    const arrayOfConectedElements = [];
    matrixOfReorganizedElements.forEach((element) => {
      arrayOfConectedElements.push(element.join(';'));
    });
    csvString = `${csvString + arrayOfConectedElements.join('\n')}\n`;
  });

  return csvString;
}
  
export function downloadCsvFile(title, data, headers) {
  let csvStringCreated = '';
  if (typeof data !== 'string') {
    csvStringCreated = createCsvString(data, headers);
  } else {
    csvStringCreated = data;
  }
  const csvStringToExport = `data:application/csv,${encodeURIComponent(csvStringCreated)}`;
  const x = document.createElement('A');
  x.setAttribute('href', csvStringToExport);
  x.setAttribute('download', (`${title}.csv`));
  document.body.appendChild(x);
  x.click();
  x.remove();
}
