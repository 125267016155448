<template v-slot:extension>
  <div>
    <v-autocomplete
      v-model="currentPartner"
      :label="$t('form.label.partners')"
      :loading="$apollo.queries.partners.loading"
      :items="availablePartners"
      item-text="name"
      item-value="partner_id"
      flat
      solo
      dense
      hide-no-data
      hide-details
      clearable
      @change="updateUrl"
    />
  </div>
</template>

<script>
import { Partners } from '@/graphql/query.graphql';
import { getPartner } from '@/router/helpers';
import { fetchLocationPartners } from '@/data';
import { EventBus } from '@/eventBus';

export default {
  name: 'PartnerFilter',
  data() {
    return {
      currentPartner: '',
      partners: [],
      name: '',
      locationId: '',
      locationPartners: [],
    };
  },
  apollo: {
    partners: {
      query: Partners,
    },
  },
  computed: {
    availablePartners() {
      if (!this.locationId) {
        return this.partners;
      }

      return this.partners.filter((partner) => this.locationPartners.includes(partner.partner_id));
    },
  },
  watch: {
    $route(to) {
      this.name = to.name;
      this.currentPartner = getPartner(to.query);
      this.locationId = to.params.locationId;
      if (this.locationId) {
        this.loadLocationPartners(this.locationId);
      }
    },
  },
  beforeMount() {
    this.name = this.$route.name;
    this.currentPartner = getPartner(this.$route.query);
    this.locationId = this.$route.params.locationId;
    if (this.locationId) {
      this.loadLocationPartners(this.locationId);
    }
  },
  methods: {
    updateUrl(partnerId) {
      const { name, params } = this.$route;
      const oldQuery = this.$route.query;
      const { status } = oldQuery;
      const query = {
        partner: partnerId,
        status,
      };
      this.$router.push({ name, params, query });
    },
    async loadLocationPartners(id) {
      try {
        const fetchedLocationPartners = await fetchLocationPartners(id);
        this.locationPartners = fetchedLocationPartners.map((e) => e.partner_id);
      } catch (e) {
        EventBus.$emit('generalErrorOccurred', {
          message: 'Cannot load data. Try again in a few seconds',
        });
        console.error(e); // eslint-disable-line
      }
    },
  },
};
</script>
