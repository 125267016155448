<template v-slot:extension>
  <div v-if="name==='dashboard' && clientsToDisplay.length" class="reports-filter">
    <v-autocomplete
      v-model="currentClient"
      flat
      solo
      dense
      :items="clientsToDisplay"
      item-text="name"
      item-value="id"
      :label="$t('form.label.clients')"
      :loading="$apollo.queries.clients.loading"
      hide-no-data
      hide-details
      clearable
      @change="updateUrl"
    />
  </div>
</template>

<script>
import { GET_CLIENTS } from '@/graphql/clients.graphql';
import { getClient, getPartner } from '@/router/helpers';

export default {
  name: 'ClientFilter',
  props: {
    email: {
      type: String,
      require: true,
      default: '',
    },
  },
  data() {
    return {
      currentClient: '',
      currentPartner: '',
      name: '',
      clients: [],
    };
  },
  apollo: {
    clients: {
      query: GET_CLIENTS,
      variables() {
        return {
          email: this.email,
        };
      },
    },
  },
  computed: {
    clientsToDisplay() {
      if (this.clients.length) {
        if (this.currentPartner) {
          const clients = this.clients[0].user_has_clis.map((e) => e.n3client);

          return clients.filter((e) => e.partner_has_clis.find((element) => element.n3partner.partner_id === this.currentPartner));
        }

        return this.clients[0].user_has_clis.map((e) => e.n3client);
      }

      return [];
    },
  },
  watch: {
    $route(to) {
      this.currentClient = getClient(to.query);
      this.currentPartner = getPartner(to.query);
      this.name = to.name;
    },
  },
  beforeMount() {
    this.name = this.$route.name;
    this.currentClient = getClient(this.$route.query);
    this.currentPartner = getPartner(this.$route.query);
  },
  methods: {
    updateUrl(client) {
      const { name, params } = this.$route;
      const oldQuery = this.$route.query;
      const { status, midLeader, partner } = oldQuery;
      const query = {
        midLeader,
        status,
        client,
        partner,
      };
      this.$router.push({
        name, params, query, partner,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import './src/styles/variables';

.reports-filter {
  display: flex;
  color: lighten($text-color, 20%);

  &__label {
    margin-right: 10px;
  }

  &__option {
    margin-top: 0;
  }

  &__button {
    &--active {
      color: green;

      span {
        color: black;
      }
    }
  }
}
</style>
