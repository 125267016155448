<template>
  <v-card class="fill-height">
    <ManagementBar :title="$tc('common.client', 2).capitalize()" :listsize="clients.length">
      <v-text-field
        v-model="search"
        prepend-inner-icon="mdi-magnify"
        :label="`${$t('entity.find', { entity: $tc('common.client', 1) })}...`.capitalize()"
        single-line
        hide-details
      />
      <!-- <template v-if="isSuperadminOrPartner" #actions>
        <v-btn color="success" small @click.stop="openDialog">
          <v-icon small>
            mdi-plus
          </v-icon>
          {{ $t("entity.new", { entity: $tc("common.client", 1) }) }}
        </v-btn>
      </template> -->
      <v-select
        v-if="isSuperadmin"
        v-model="selectedPartner"
        :loading="loading"
        :items="partners"
        item-text="name"
        item-value="partner_id"
        class="v-select"
        flat
        solo
        :label="$t('common.partner')"
        clearable
      />
    </ManagementBar>
    <v-data-table
      :headers="headers"
      :items="clientsToDisplay"
      :search="search"
      :loading="loading"
      :footer-props="{
        itemsPerPageOptions: [20, 50, 100, -1]
      }"
      :disable-pagination="false"
      item-key="id"
      fixed-header
      sort-by="[name]"
      :expanded.sync="expanded"
    >
      <template #loading>
        <v-skeleton-loader class="mx-auto" max-width="80%" type="table-row@3" />
      </template>

      <!-- <template v-if="isSuperadminOrPartner" #no-data>
        <v-card-text class="pa-8">
          <v-icon slot="icon" size="64" class="mb-6">
            mdi-city
          </v-icon>
          <p class="display-1 text--primary">
            {{ $t("entity.empty.headline", { entities: $tc("common.client", 2) }) }}
          </p>
          <p>{{ $t("entity.empty.description", { entities: $tc("common.client", 2) }) }}</p>
          <v-btn tile color="blue lighten-4" @click.stop="openDialog">
            {{ $t("entity.add", { entity: $tc("common.client", 1) }) }}
          </v-btn>
        </v-card-text>
      </template> -->
      <template #[`item.partner_has_clis`]="{item}">
        <span v-if="item.partner_has_clis[0]">
          {{ item.partner_has_clis[0].n3partner.name }}
        </span>
        <v-btn v-if="item.partner_has_clis[1]" icon @click="expandRow(item)">
          <v-icon v-if="expanded.includes(item)" size="16">
            mdi-chevron-up
          </v-icon>
          <span v-else class="caption"> {{ item.partner_has_clis.length - 1 }}+ </span>
        </v-btn>
      </template>
      <template #expanded-item="{ item }">
        <td :colspan="2" />
        <td :colspan="3">
          <p class="mt-4 mb-4">
            <span v-for="partner in item.partner_has_clis" :key="partner.partner_id">
              <li>
                {{ partner.n3partner.name }}
              </li>
            </span>
          </p>
        </td>
      </template>

      <!-- <template #[`item.action`]="{ item }">
        <span class="actions">
          <v-icon v-if="isSuperadminOrPartner" small class="mr-2" @click.stop="editItem(item)">
            mdi-pencil
          </v-icon>
          <v-icon v-if="isSuperadminOrPartner" small @click.stop="confirmDeleteItem(item)">
            mdi-delete
          </v-icon>
        </span>
      </template> -->
    </v-data-table>
    <!-- <v-dialog v-model="dialog" max-width="480px">
      <ManagementClientForm
        :client="clientToEdit"
        :partners="partners"
        @submit="submit"
        @close="closeDialog"
      />
    </v-dialog>
    <DialogConfirm
      :show="confirmDialog"
      :danger="true"
      @cancel="closeConfirmDialog"
      @confirm="deleteItem"
    >
      {{ $t("confirmation.delete.title") }}
    </DialogConfirm> -->
  </v-card>
</template>

<script>
import {
  addClient, deleteClient, fetchClients, updateClient, fetchPartners,
} from '@/data';
// import DialogConfirm from '@/components/Dialog/Confirm';
// import ManagementClientForm from '@/views/Management/Clients/Form';
import ManagementBar from '@/components/ManagementBar';
import { EventBus } from '@/eventBus';

export default {
  name: 'DpManagementClients',
  components: {
    // DialogConfirm,
    ManagementBar,
    // ManagementClientForm,
  },
  inject: ['authGuard'],
  props: {
    searchName: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      clients: [],
      expanded: [],
      search: this.searchName,
      loading: false,
      dialog: false,
      confirmDialog: false,
      clientToEdit: null,
      clientToRemove: null,
      headers: [
        { text: this.$t('table.client.header.name'), value: 'name' },
        { text: this.$t('table.client.header.comment'), value: 'comment' },
        {
          text: this.$t('table.zone.header.partner'),
          value: 'partner_has_clis',
          width: '170px',
        },
        {
          text: '',
          value: 'action',
          filterable: false,
          sortable: false,
          width: '80px',
        },
      ],
      loadingPartners: true,
      partners: [],
      selectedPartner: null,
    };
  },
  computed: {
    isSuperadminOrPartner() {
      return this.authGuard.isGranted('n3-superadmin') || this.authGuard.isGranted('n3-partner');
    },
    isSuperadmin() {
      return this.authGuard.isGranted('n3-superadmin');
    },
    clientsToDisplay() {
      if (!this.clients) {
        return [];
      }

      if (this.selectedPartner) {
        return this.clients.filter((e) => e.partner_has_clis.find((element) => element.n3partner.partner_id === this.selectedPartner));
      }

      return this.clients;
    },
  },
  watch: {
    dialog(newVal, oldVal) {
      if (oldVal === true && newVal === false) {
        this.clientToEdit = null;
      }
    },
  },
  created() {
    this.loadClients();
    this.loadPartners();
  },
  methods: {
    async loadClients() {
      this.loading = true;
      try {
        this.clients = await fetchClients();
        this.loading = false;
      } catch (e) {
        EventBus.$emit('generalErrorOccurred', {
          message: 'Cannot load data. Try again in a few seconds',
        });
        console.error(e); // eslint-disable-line
      }
    },
    async loadPartners() {
      try {
        this.partners = await fetchPartners();
        this.loading = false;
      } catch (e) {
        EventBus.$emit('generalErrorOccurred', {
          message: 'Cannot load data. Try again in a few seconds',
        });
        console.error(e); // eslint-disable-line
      }
    },
    openDialog() {
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
    },
    confirmDeleteItem(item) {
      if (this.authGuard.isGranted('n3-superadmin')) {
        this.clientToRemove = item;
        this.confirmDialog = true;
      }
    },
    closeConfirmDialog() {
      this.confirmDialog = false;
    },
    submit(submitted) {
      const {
        name, comment, partnersToAdd, partnersToDelete,
      } = submitted;
      if (this.clientToEdit) {
        const index = this.clients.indexOf(this.clientToEdit);
        updateClient(this.clientToEdit.id, { name, comment }, partnersToAdd, partnersToDelete)
          .then((updatedClient) => {
            this.clients.splice(index, 1, updatedClient);
          })
          .catch((error) => {
            EventBus.$emit('generalErrorOccurred');
            console.error(error); // eslint-disable-line
          })
          .finally(this.closeDialog);
      } else {
        addClient(name, comment, partnersToAdd)
          .then((createdClient) => {
            this.clients.push(createdClient);
          })
          .catch((error) => {
            EventBus.$emit('generalErrorOccurred', {
              message: 'Client already exists',
            });
            console.error(error); // eslint-disable-line
          })
          .finally(this.closeDialog);
      }
    },
    editItem(item) {
      if (this.authGuard.isGranted('n3-superadmin') || this.authGuard.isGranted('n3-partner')) {
        this.clientToEdit = item;
        this.openDialog();
      }
    },
    deleteItem() {
      const index = this.clients.indexOf(this.clientToRemove);
      if (index < 0) {
        console.error("This should not happen"); // eslint-disable-line

        return;
      }
      this.clients.splice(index, 1);
      this.closeConfirmDialog();
      deleteClient(this.clientToRemove.id, this.clients).catch((error) => {
        EventBus.$emit('generalErrorOccurred', {
          message: 'Cannot remove client',
        });
        this.clients.splice(index, 0, this.clientToRemove);
        console.error(error); // eslint-disable-line
      });
    },
    expandRow(value) {
      if (this.expanded.includes(value)) {
        this.expanded = [];
      } else {
        this.expanded = [value];
      }
    },
  },
};
</script>

<style lang="scss" scoped>
table {
  tr {
    .actions {
      .v-icon {
        visibility: hidden;
      }
    }
    &:hover {
      .actions {
        .v-icon {
          visibility: visible;
        }
      }
    }
  }
}
.v-select {
    width: 1%;
    justify-content: center;
    margin-top: 25px;
}
</style>
