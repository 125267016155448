export function compare(a, b, isDescending) {
  if (isDescending[0]) {
    return a.toLowerCase() > b.toLowerCase() ? -1 : 1;
  }

  return a.toLowerCase() < b.toLowerCase() ? -1 : 1;
}

export function compareObjects(a, b, isDescending) {
  if (a === null || a === undefined || a === '') {
    return isDescending[0] ? -1 : 1;
  } if (b === null || b === undefined || b === '') {
    return isDescending[0] ? 1 : -1;
  }

  return compare(a, b, isDescending);
}
