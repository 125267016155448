export default class MonthlyReport {
  constructor(locations, locationsForCalculation) {
    this.locations = locations;
    this.locationsForCalculation = locationsForCalculation;
  }
    
  totalExpectedTime(date) {
    let expetedTime = 0;
    this.locationsForCalculation.forEach((location) => {
      expetedTime += location.getExpetedTime(date);
    });

    return expetedTime;
  }

  totalCleaningTime(date) {
    let cleaningTime = 0;
    this.locationsForCalculation.forEach((location) => {
      cleaningTime += location.getCleaningTime(date);
    });

    return cleaningTime;
  }

  totalDiscrepancy(date) {
    const expetedTime = this.totalExpectedTime(date);
    const cleaningTime = this.totalCleaningTime(date);

    return expetedTime - cleaningTime;
  }

  expectedTimes(dates) {
    const expectedTimes = [];
    dates.forEach((date) => {
      expectedTimes.push(this.totalExpectedTime(date));
    });

    return expectedTimes;
  }

  cleaningTimes(dates) {
    const cleaningTimes = [];
    dates.forEach((date) => {
      cleaningTimes.push(this.totalCleaningTime(date));
    });

    return cleaningTimes;
  }
}
