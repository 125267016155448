<template>
  <div class="fill-height">
    <v-container>
      <v-row no-gutters align="center" justify="center">
        <v-col cols="12">
          <VueApexCharts height="400" type="line" :options="chartOptions" :series="series" />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>

import VueApexCharts from 'vue-apexcharts';

export default {
  name: 'CleaningMonthlyReportChart',
  components: {
    VueApexCharts,
  },
  props: {
    totalExpectedTime: {
      type: Array,
      default: () => [],
    },
    totalCleaningTime: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    series() {
      return [{
        name: this.$t('report.chart.cleaning'),
        type: 'column',
        data: this.totalCleaningTime,
      }, {
        name: this.$t('report.chart.expected'),
        type: 'line',
        data: this.totalExpectedTime,
      }];
    },
    chartOptions() {
      return {
        chart: {
          id: 'vuechart-example',
          toolbar: {
            show: false,
          },
          animations: {
            enabled: false,
          },
        },
        markers: {
          size: 0,
        },
        dataLabels: {
          enabled: true,
          style: {
            colors: [(opts) => this.evaluateColor(opts), '#00E396'],
          },
        },
        xaxis: {
          title: {
            text: this.$t('report.chart.xaxis'),
            style: {
              fontSize: '14px',
              fontWeight: 'bold',
              fontFamily: 'Roboto',
              color: '#000000',
            },
          },
        },
        yaxis: {
          title: {
            text: this.$t('report.chart.yaxis'),
            style: {
              fontSize: '14px',
              fontWeight: 'bold',
              fontFamily: 'Roboto',
              color: '#000000',
            },
          },
        },
        legend: {
          show: true,
          markers: {
            fillColors: ['#FFFFFF', '#00E396'],
          },
        },
        colors: [(opts) => this.evaluateColor(opts),
          '#00E396'],
      };
    },
  },
  methods: {
    evaluateColor({ dataPointIndex }) {
      const expected = this.totalExpectedTime[dataPointIndex];
      const cleaning = this.totalCleaningTime[dataPointIndex];
      const percentageValue = (cleaning / expected) * 100;
      if (expected === 0) {
        return '#006400';
      }
      if (percentageValue >= 81) {
        return '#006400';
      }
      if (percentageValue <= 10) {
        return '#FF0000';
      }

      return '#ff9900';
    },
  },
};

</script>

<style lang="scss" scoped>

  .chart-container {
    position: relative;
    height: 200px;
    width: 100%;
  }

</style>
