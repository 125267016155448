import {
  eachDayOfInterval, format, sub, getDay, parseISO,
} from 'date-fns';
    
export default class LocationReport {
  constructor(payload, startDate, stopDate, color = '#e6e6e6') {
    this.id = payload.loc_id;
    this.name = payload.loc_name;
    this.zones = payload.zones;
    this.startDate = startDate;
    this.stopDate = stopDate;
    this.color = color;
  }

  get cleaningTimes() {
    const values = new Map();
    this.dates.forEach((date) => {
      let cleaningTime = 0;
      this.zones.forEach((zone) => {
        zone.n3device.data.forEach((record) => {
          if (record.dtime.includes(date)) {
            cleaningTime += record.clean_time;
          }
        });
      });
      values.set(date, cleaningTime);
    });

    return values;
  }

  get expectedTimes() {
    const values = new Map();
    this.dates.forEach((date) => {
      const dayOfTheWeek = getDay(parseISO(date));
      let expectedTime = 0;
      this.zones.forEach((zone) => {
        if (!this.__getExludedDays(zone.scenarios).includes(dayOfTheWeek)) {
          expectedTime += zone.expected_cleaning_time;
        }
      });
      values.set(date, expectedTime);
    });

    return values;
  }

  getExpetedTime(date) {
    return this.expectedTimes.get(date);
  }
  
  getCleaningTime(date) {
    return this.cleaningTimes.get(date);
  }

  __getExludedDays(scenarios) {
    const days = [];
    scenarios.forEach((scenario) => {
      days.push(scenario.ddow_id);
    });

    return days;
  }

  get rowColorStyle() {
    return `background-color:${this.color}`;
  }
   
  get dates() {
    const dates = eachDayOfInterval({
      start: new Date(this.startDate),
      end: sub(new Date(this.stopDate), { days: 1 }),
    });
    const datesFormated = [];
    dates.forEach((date) => {
      datesFormated.push(format(date, 'yyyy-MM-dd'));
    });
  
    return datesFormated;
  }
}
