<template>
  <v-icon
    v-if="trendOfValue==trendModel.DOWN"
    :color="color"
    small
  >
    mdi-arrow-bottom-right-thick
  </v-icon>
  <v-icon
    v-else-if="trendOfValue==trendModel.STEADY"
    :color="color"
    small
  >
    mdi-arrow-right-bold
  </v-icon>
  <v-icon
    v-else-if="trendOfValue==trendModel.UP"
    :color="color"
    small
  >
    mdi-arrow-top-right-thick
  </v-icon>
</template>
<script>

import { environmentTheme } from '@/configuration';
import Trend from '@/model/Trend';

export default {
  name: 'TrendIcon',
  props: {
    status: {
      type: Number,
      required: true,
    },
    trend: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      trendModel: Trend,
    };
  },
  computed: {
    color() {
      return environmentTheme.colors.environmentStatusColor[this.status];
    },
    trendOfValue() {
      return this.trend;
    },
  },
};

</script>
