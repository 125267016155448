import { apolloClient } from '@/integration/apollo';
import {
  LocationDataForEnvironmentalCharts,
  LocationEnvironmentZonesData,
  LocationFromCategoryLocations,
  LocationStatusByDates,
  MonthlyDataForLocations,
  LocationEnvironmentGeneralData,
} from '@/graphql/query.graphql';
import { format } from 'date-fns';
import gql from 'graphql-tag';
import { approximationToQuarterHour } from '@/helpers/time';
import { DATETIME_FORMAT, TIME_INTERVAL_1_DAY } from '@/configuration';
import { getKeysArrayByValue } from '@/helpers/objectExtraction';
import { SubcategoriesCategories } from '@/model/CategoriesObjects';
import CleaningLocation from '@/model/location/CleaningLocation';
import EnvironmentLocation from '@/model/location/EnvironmentLocation';
import MonthlyReport from '@/model/reports/MonthlyReport';
import { LocationReportCleaning, LocationReportExpected } from '@/model/reports/SubLocationReport';
/**
 * Converts device route category to category name used in DB
 * @param {String} category
 */
export function getQueryCategory(category) {
  switch (category) {
    case 'zones':
      return 'N3Zones';
    case 'robots':
      return 'N3Assets';
    case 'environment':
      return 'N3Environment';
    default:
      throw Error(`Unknown category. Cannot fetch locations for "${this.category}" category.`);
  }
}

export async function getDeviceLocations(category, date) {
  const subcategoriesArray = getKeysArrayByValue(SubcategoriesCategories,
    getQueryCategory(category));
  const categoryEnv = (category === 'environment');
  const query = gql`
      query getDeviceLocations ($category: String!, $subcategoriesArray: [String!], $date: date, $categoryEnv: Boolean!) {
          locations: n3location(
              where: {
                  deleted_at: {
                        _is_null: true
                  },
                  n3devices: {
                    sub_category_name: {
                      _in: $subcategoriesArray
                    }
                  },
              },
              order_by: {
                    loc_name: asc
              }
          ) {
              partner_has_locs {
                n3partner{
                  partner_id
                }
              }
              loc_id
              loc_name
              gps_latitude
              gps_longitude
              statuses: n3location_statuses(
                  where: {
                      status_date: {
                          _eq: $date
                      }
                  }
              ) {
                  date: status_date
                  status: status_${category}
              }
              exist: n3${category}_zones (limit: 1) {
                zone_id
              }
              user_has_loc {
                user_id
              }
              n3client {
                id
                name
              }
              times: location_cleaning_times_${categoryEnv ? 'zones' : category} @skip(if: $categoryEnv) {
                  cleaning_time
                  expected_time
                  other_activity: total_activity
              }
          }
      }
  `;

  const response = await apolloClient.query({
    query,
    variables: {
      category,
      subcategoriesArray,
      date: format(date, 'yyyy-MM-dd'),
      categoryEnv,
    },
    fetchPolicy: 'no-cache',
  });

  return response.data.locations.map((locationData) => ((category === 'environment') ? new EnvironmentLocation(locationData) : new CleaningLocation(locationData)));
}

export async function getLocationFromCategoryLocations(category, locationId) {
  const categoryDbName = getQueryCategory(category);
  const subcategoriesArray = getKeysArrayByValue(SubcategoriesCategories, categoryDbName);
  const response = await apolloClient.query({
    query: LocationFromCategoryLocations,
    variables: {
      subcategoriesArray,
      locationId,
    },
    fetchPolicy: 'no-cache',
  });

  return response.data.n3location.length;
}

export async function getZoneForLocation(category, locationId) {
  const query = gql`
      query getZoneForLocation ($locationId: Int!) {
          location: n3location(
              where: {
                  deleted_at: {
                        _is_null: true
                  },
                  loc_id: {
                    _eq: $locationId
                  }
                }
          )
          {
            zone: n3${category}_zones {
              zone_id
            }
          }
      }
  `;

  const response = await apolloClient.query({
    query,
    variables: {
      locationId,
    },
    fetchPolicy: 'no-cache',
  });

  return response.data.location[0].zone.length;
}

/**
 * @TODO needs to be rethinked then redesigned then refactored
 * @param ids
 * @param {Boolean} onlyZones
 * @param dateStart
 * @param dateStop
 * @returns {Promise<any[]>}
 */
export async function findById(
  ids = [],
  onlyZones,
  dateStart = format(new Date(), 'yyyy-MM-dd'),
  dateStop = format(new Date((new Date()).valueOf() + 86400000), 'yyyy-MM-dd'),
) {
  const response = await apolloClient.query({
    query: LocationStatusByDates,
    variables: {
      locations: ids,
      date_start_t: dateStart,
      date_stop_t: dateStop,
      date_start_d: dateStart,
      date_stop_d: dateStop,
      categoryZone: !!onlyZones,
      categoryRobot: !onlyZones,
    },
    fetchPolicy: 'no-cache',
  });

  const location = new CleaningLocation(response.data.n3location[0]);

  return location;
}

export async function fetchDataForEnvironemntalChart(
  id,
  name,
  dateStartT,
  dateStopT,
) {
  const temperature = (name === 'temperature');
  const humidity = (name === 'humidity');
  const dustLevel = (name === 'dust_level');
  const co2 = (name === 'co2');
  const response = await apolloClient.query({
    query: LocationDataForEnvironmentalCharts,
    variables: {
      id,
      date_start_t: dateStartT,
      date_stop_t: dateStopT,
      temperature,
      humidity,
      dustLevel,
      co2,
    },
  });

  return response.data.n3device[0]?.singleMeasures;
}

export async function fetchEnvironmentGeneralData(id,
  dateStartDayT = format(approximationToQuarterHour(new Date(new Date().valueOf() - TIME_INTERVAL_1_DAY)), DATETIME_FORMAT),
  dateStopT = format(approximationToQuarterHour(Date.now()), DATETIME_FORMAT)) {
  const response = await apolloClient.query({
    query: LocationEnvironmentGeneralData,
    variables: {
      id,
      date_start_day_t: dateStartDayT,
      date_stop_day_t: dateStopT,
    },
  });

  return response.data.n3device[0];
}

export async function fetchEnvironmentZonesCoordinatesAndData(ids = [],
  dateStartD = format(new Date(), 'yyyy-MM-dd'),
  dateStopD = format(new Date((new Date()).valueOf() + 86400000), 'yyyy-MM-dd')) {
  const response = await apolloClient.query({
    query: LocationEnvironmentZonesData,
    variables: {
      locations: ids,
      date_start_d: dateStartD,
      date_stop_d: dateStopD,
    },
  });
  const location = new EnvironmentLocation(response.data.n3location[0]);

  return location;
}

export async function fetchMonthlyDataForLocations(ids, dateStart, dateStop, categoryZone, categoryRobot) {
  const response = await apolloClient.query({
    query: MonthlyDataForLocations,
    variables: {
      ids,
      date_start: dateStart,
      date_stop: dateStop,
      categoryZone,
      categoryRobot,
    },
    fetchPolicy: 'no-cache',
  });

  const { locations } = response.data;
  const allLocations = [];
  const locationsForCaluculation = [];
  let color = (locations.length % 2 === 0) ? '#e6e6e6' : '#ffffff';
  locations.forEach((location) => {
    const locExpected = new LocationReportExpected(location, dateStart, dateStop, color);
    const locCleaning = new LocationReportCleaning(location, dateStart, dateStop, color);
    color = (color === '#e6e6e6') ? '#ffffff' : '#e6e6e6';
    allLocations.push(locExpected, locCleaning);
    locationsForCaluculation.push(locCleaning);
  });

  const report = new MonthlyReport(allLocations, locationsForCaluculation);

  return report;
}
