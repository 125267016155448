export function getStatus({ status }) {
  if (status) {
    return status === '' ? [] : status.split(',').map((numStr) => parseInt(numStr, 10));
  }

  return [];
}
export function getMidLeader({ midLeader }) {
  if (midLeader) {
    return parseInt(midLeader, 10);
  }

  return '';
}

export function getClient({ client }) {
  if (client) {
    return parseInt(client, 10);
  }

  return '';
}

export function getPartner({ partner }) {
  if (partner) {
    return parseInt(partner, 10);
  }

  return '';
}
