import {
  eachDayOfInterval, format, formatISO, sub,
} from 'date-fns';
import { CleaningStatus } from '@/model/CleaningStatus';
import Zone from '@/model/zones/Zone';

export default class CleaningZone extends Zone {
  constructor(payload) {
    super(payload);
    this.scenarios = payload.scenarios;
    this.roomUUID = payload.room_id;
    this.expectedCleaningTime = payload.expected_cleaning_time;
    this.roomId = payload.room_id;
    this.url = payload.server_url;
    this.sendCleanPilot = payload.send_clean_pilot;
  }

  get singleCleanings() { // private
    return this.device?.singleCleanings;
  }

  get excludedDays() { // private
    if (this.scenarios) {
      return this.scenarios
        .map((exclusion) => {
          if (exclusion.dayOff === 0) {
            return 7;
          }

          return exclusion.dayOff;
        });
    }

    return [];
  }

  get cleaningDates() { // private
    return (this.singleCleanings) ? this.singleCleanings.map((e) => e.dtime) : [];
  }

  get cleaningTimesSumForToday() {
    return (this.singleCleanings) ? this.singleCleanings.reduce((prev, cur) => {
      if (new Date(cur.dtime) >= new Date().setHours(0, 0, 0, 0)) {
        return prev + cur.clean_time;
      }

      return prev;
    }, 0) : 0;
  }

  get cleaningTimeSum() {
    return this.device?.cleaning?.aggregate.sum.cleaningTime || 0;
  }

  get zoneStatuses() {
    const zoneStatuses = [];
    if (this.zoneStatus) {
      this.zoneStatus.forEach((singleStatusData) => {
        const dayOfWeek = parseInt(format(Date.parse(singleStatusData.status_date), 'i'), 10);
        if (this.excludedDays.includes(dayOfWeek)) {
          zoneStatuses.push(
            {
              cleanStatus: (CleaningStatus.CLEANED_NOT_REQUIRED),
              cleanDate: singleStatusData.status_date,
            },
          );
        } else {
          zoneStatuses.push(
            {
              cleanStatus: (singleStatusData.status === 1
                ? CleaningStatus.CLEANED_FULL : CleaningStatus.CLEANED_NONE),
              cleanDate: singleStatusData.status_date,
            },
          );
        }
      });
      zoneStatuses.sort((a, b) => {
        if (a.cleanDate > b.cleanDate) return 1;

        return -1;
      });
    }

    return zoneStatuses;
  }

  get averageCleaningDuration() {
    const cumulativeCleaningDuration = this.cleaningTimeSum;
    if (this.cleanings.size - this.excludedDays.length - 1 === 0) {
      return '0.00';
    }

    return (
      cumulativeCleaningDuration / (this.cleanings.size - this.excludedDays.length - 1)
    ).toPrecision(3);
  }

  get parseCleaningData() { // private
    const cleanings = [];
    if (this.singleCleanings) {
      this.singleCleanings.forEach((data) => {
        cleanings.push({
          date: new Date(data.dtime),
          duration: data.clean_time,
        });
      });
    }

    return cleanings;
  }

  get tomorrowDay() { // private
    const tomorrowDay = new Date();
    tomorrowDay.setDate(tomorrowDay.getDate() + 1);

    return tomorrowDay;
  }

  get cleanings() { // private
    const cleanings = new Map();
    this.dates(this.tomorrowDay).forEach((date) => cleanings.set(formatISO(date, { representation: 'date' }), []));
    this.parseCleaningData.forEach((data) => {
      const cleaningDate = formatISO(data.date, { representation: 'date' });
      if (cleanings.has(cleaningDate)) {
        cleanings.get(cleaningDate).push(data);
      }
    });

    return cleanings;
  }

  get todayCleaningDates() {
    // eslint-disable-next-line max-len
    return this.cleaningDates.filter((date) => (new Date(date).getTime() >= new Date().setHours(0, 0, 0, 0)));
  }

  get cleaningTimeDiscrepancy() {
    return this.cleaningTimesSumForToday - this.expectedCleaningTime;
  }

  get todayCleaningStatus() {
    if (this.zoneStatuses.length > 0) {
      const zoneStatusForToday = this.zoneStatuses.filter((status) => status.cleanDate === format(new Date(), 'yyyy-MM-dd'));
      if (zoneStatusForToday.length) {
        return zoneStatusForToday[0].cleanStatus;
      }

      return 0;
    }

    return 0;
  }

  dates(tomorrowDay) { // private
    const dates = eachDayOfInterval({
      start: sub(tomorrowDay, { days: 7 }),
      end: tomorrowDay,
    });

    return dates;
  }

  getCleaningDuration(date) {
    const formattedDate = formatISO(date, { representation: 'date' });
    const cleanings = new Map(this.cleanings);
    const activities = cleanings.get(formattedDate);
    if (!activities) {
      return 0;
    }

    return activities.reduce((acc, cur) => acc + cur.duration, 0);
  }

  isWorkingAt(date) {
    const dayOfWeek = parseInt(format(date, 'i'), 10);

    return this.excludedDays.indexOf(dayOfWeek) < 0;
  }

  getCleaningStatus(date) {
    const formattedDate = formatISO(date, { representation: 'date' });
    const cleanings = new Map(this.cleanings);
    const arrayOfValues = [];
    cleanings.forEach((value, key) => {
      if (key.includes(formattedDate)) {
        arrayOfValues.push(value);
      }
    });
    const activities = arrayOfValues;
    if (!activities || activities.length === 0 || activities[0].length === 0) {
      const dayIsExcluded = this.excludedDays.find((exclusion) => parseInt(exclusion, 10) === parseInt(format(date, 'i'), 10));

      return dayIsExcluded !== undefined
        ? CleaningStatus.CLEANED_NOT_REQUIRED : CleaningStatus.CLEANED_NONE;
    }

    const cleaningTime = activities[0].map((cleaning) => cleaning.duration);
    if (cleaningTime.reduce((a, b) => a + b, 0) >= this.expectedCleaningTime) {
      return CleaningStatus.CLEANED_FULL;
    }

    return CleaningStatus.CLEANED_NONE;
  }
}
