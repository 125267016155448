export function calculateTrend(arrayOfValues = []) {
  const derrivative = [];
  for (let i = 0; i < arrayOfValues.length - 1; i += 1) {
    derrivative[i] = arrayOfValues[i + 1] - arrayOfValues[i];
  }
  const sum = derrivative.reduce((a, b) => a + b, 0);
  const avg = (sum / derrivative.length) || 0;
  if (avg > 0) {
    return 1;
  } if (avg < 0) {
    return -1;
  }

  return 0;
}
