<template v-slot:extension>
  <div class="reports-filter">
    <div class="reports-filter__label">
      {{ $t('dashboard.filter') }}
    </div>
    <div>
      <v-menu
        v-model="menu"
        :close-on-content-click="false"
        offset-y
        dense
      >
        <template #activator="{ on }">
          <div v-on="on">
            <v-badge
              left
              overlap
              :value="selected.length"
              :content="selected.length"
              color="red"
            >
              <v-btn
                x-small
                text
                class="reports-filter__button"
                :class="{'reports-filter__button&#45;&#45;active': selected.length}"
              >
                <v-icon size="18">
                  mdi-filter
                </v-icon>
                <span class="">
                  {{ $t('dashboard.status') }}
                </span>
              </v-btn>
            </v-badge>
          </div>
        </template>
        <v-card>
          <v-list dense>
            <v-list-item v-for="filter in filters" :key="filter.value">
              <v-checkbox
                v-model="selected"
                :label="$t(filter.name)"
                :value="filter.value"
                hide-details
                dense
                class="reports-filter__option"
                @change="updateUrl"
              />
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>
    </div>
  </div>
</template>

<script>

import { getStatus } from '@/router/helpers';
import { EnvironmentStatus } from '@/model/EnvironmentStatus';
import { CleaningStatus } from '@/model/CleaningStatus';

export default {
  name: 'DashboardFilter',
  data() {
    return {
      selected: [],
      menu: false,
      zones_filters: [{ name: 'form.label.notCleaned', value: CleaningStatus.CLEANED_NONE },
        { name: 'form.label.partlyCleaned', value: CleaningStatus.CLEANED_PARTLY },
        { name: 'form.label.cleaned', value: CleaningStatus.CLEANED_FULL }],
      drilldown_zones_filters: [{ name: 'form.label.notCleaned', value: CleaningStatus.CLEANED_NONE },
        { name: 'form.label.cleaned', value: CleaningStatus.CLEANED_FULL }],
      robots_filters: [{ name: 'form.label.notCleaned', value: CleaningStatus.CLEANED_NONE },
        { name: 'form.label.partlyCleaned', value: CleaningStatus.CLEANED_PARTLY },
        { name: 'form.label.cleaned', value: CleaningStatus.CLEANED_FULL }],
      drilldown_robots_filters: [{ name: 'form.label.notCleaned', value: CleaningStatus.CLEANED_NONE },
        { name: 'form.label.cleaned', value: CleaningStatus.CLEANED_FULL }],
      environment_filters: [{ name: 'form.label.notOk', value: EnvironmentStatus.BAD_CONDITIONS },
        { name: 'form.label.partlyOk', value: EnvironmentStatus.PARTIALY_GOOD_CONDITIONS },
        { name: 'form.label.ok', value: EnvironmentStatus.GOOD_CONDITIONS }],
      drilldown_environment_filters: [{ name: 'form.label.notOk', value: EnvironmentStatus.BAD_CONDITIONS },
        { name: 'form.label.ok', value: EnvironmentStatus.GOOD_CONDITIONS }],
    };
  },
  computed: {
    filters() {
      switch (this.$route.name) {
        case 'dashboard':
          return this[`${this.$route.params.category}_filters`];
        case 'dashboard.location':
          return this[`drilldown_${this.$route.params.category}_filters`];
        default:
          return [];
      }
    },
  },
  watch: {
    $route(to) {
      this.selected = getStatus(to.query);
    },
  },
  beforeMount() {
    this.selected = getStatus(this.$route.query);
    if (this.$route.params.id !== undefined) {
      this.selectedLocation = parseInt(this.$route.params.id, 10);
    }
  },
  methods: {
    updateUrl(statuses) {
      const { name, params } = this.$route;
      const { midLeader, client, partner } = this.$route.query;
      let query = null;
      if (statuses.length) {
        query = {
          midLeader, status: statuses.join(','), client, partner,
        };
      } else {
        query = { midLeader, client, partner };
      }
      this.$router.push({ name, params, query });
    },
  },
};
</script>

<style lang="scss" scoped>
@import './src/styles/variables';

.reports-filter {
  display: flex;
  color: lighten($text-color, 20%);

  &__label {
    margin-right: 10px;
  }

  &__option {
    margin-top: 0;
  }

  &__button {
    &--active {
      color: green;

      span {
        color: black;
      }
    }
  }
}
</style>
