<template>
  <v-card class="fill-height">
    <ManagementBar
      :title="$tc('common.location', 2).capitalize()"
      :listsize="locationsToDisplay.length"
    >
      <v-text-field
        v-model="search"
        prepend-inner-icon="mdi-magnify"
        :label="`${$t('entity.find', { entity: $tc('common.location', 1) })}...`.capitalize()"
        single-line
        hide-details
      />
      <!-- <template v-if="isAdmin" #actions>
        <v-btn color="success" small @click.stop="openDialog">
          <v-icon small>
            mdi-plus
          </v-icon>
          {{ $t("entity.new", { entity: $tc("common.location", 1) }) }}
        </v-btn>
      </template> -->
      <v-select
        v-if="isSuperAdmin"
        v-model="selectedPartner"
        :loading="loading"
        :items="partners"
        item-text="name"
        item-value="partner_id"
        class="v-select"
        flat
        solo
        :label="$t('common.partner')"
        clearable
      />
    </ManagementBar>
    <v-data-table
      :headers="headers"
      :items="locationsToDisplay"
      :search="search"
      :loading="loading"
      :footer-props="{
        itemsPerPageOptions: [20, 50, 100, -1]
      }"
      :disable-pagination="false"
      :expanded.sync="expanded"
      :custom-sort="customSort"
      :custom-filter="customSearch"
      item-key="id"
      fixed-header
    >
      <template #[`item.name`]="{item}">
        <span style="">
          {{ item.name }}
        </span>
      </template>

      <template #expanded-item="{ item }">
        <td />
        <td />
        <td />
        <td :colspan="1">
          <p class="mt-4 mb-4">
            <span v-for="zone in item.zoneZone" :key="zone.id + 'Zone'">
              <v-chip @click="redirect('zones', zone.name)">
                {{ zone.name }}
              </v-chip>
            </span>
            <span v-for="robotzone in item.robotZone" :key="robotzone.id + 'Robot'">
              <v-chip @click="redirect('zones', robotzone.name)">
                {{ robotzone.name }}
              </v-chip>
            </span>
            <span v-for="environmentzone in item.environmentZone" :key="environmentzone.id + 'Env'">
              <v-chip @click="redirect('zones', environmentzone.name)">
                {{ environmentzone.name }}
              </v-chip>
            </span>
          </p>
        </td>
        <td>
          <p class="mt-4 mb-4">
            <span v-for="device in item.devices" :key="device.id">
              <v-chip @click="redirect('devices', device.name)">
                {{ device.name }}
              </v-chip>
            </span>
          </p>
        </td>
        <td />
        <td>
          <p class="mt-4 mb-4">
            <span v-for="partner in item.partnersIdentifier" :key="partner.id">
              <v-chip @click="(isSuperAdmin) ? redirect('partners', partner.name) : () => {}">
                {{ partner.name }}
              </v-chip>
            </span>
          </p>
        </td>
        <td :colspan="headers.length - 2" />
      </template>

      <template #[`item.zoneZone`]="{item}">
        <v-chip
          v-if="item.zoneZone[0]"
          color="white"
          @click="redirect('zones', item.zoneZone[0].name)"
        >
          {{ item.zoneZone[0].name }}
        </v-chip>
        <v-chip
          v-if="item.robotZone[0] && !item.zoneZone[0]"
          color="white"
          @click="redirect('zones', item.robotZone[0].name)"
        >
          {{ item.robotZone[0].name }}
        </v-chip>
        <v-chip
          v-if="item.environmentZone[0] && !item.zoneZone[0] && !item.robotZone[0]"
          color="white"
          @click="redirect('zones', item.environmentZone[0].name)"
        >
          {{ item.environmentZone[0].name }}
        </v-chip>
        <v-btn
          v-if="item.zoneZone.length + item.robotZone.length + item.environmentZone.length > 1"
          icon
          @click="expandRow(item)"
        >
          <v-icon v-if="expanded.includes(item)" size="16">
            mdi-chevron-up
          </v-icon>
          <span v-else class="caption">
            {{ item.zoneZone.length + item.robotZone.length + item.environmentZone.length - 1 }}+
          </span>
        </v-btn>
      </template>

      <template #[`item.devices`]="{item}">
        <v-chip
          v-if="item.devices[0]"
          color="white"
          @click="redirect('devices', item.devices[0].name)"
        >
          {{ item.devices[0].name }}
        </v-chip>
        <v-btn v-if="item.devices[1]" icon @click="expandRow(item)">
          <v-icon v-if="expanded.includes(item)" size="16">
            mdi-chevron-up
          </v-icon>
          <span v-else class="caption"> {{ item.devices.length - 1 }}+ </span>
        </v-btn>
      </template>

      <template #[`item.partnersIdentifier`]="{item}">
        <v-chip
          v-if="item.partnersIdentifier[0]"
          color="white"
          @click="(isSuperAdmin) ? redirect('partners', item.partnersIdentifier[0].name) : () => {}"
        >
          {{ item.partnersIdentifier[0].name }}
        </v-chip>
        <v-btn v-if="item.partnersIdentifier[1]" icon @click="expandRow(item)">
          <v-icon v-if="expanded.includes(item)" size="16">
            mdi-chevron-up
          </v-icon>
          <span v-else class="caption"> {{ item.partnersIdentifier.length - 1 }}+ </span>
        </v-btn>
      </template>

      <template #[`item.gpsLatitude`]="{item}">
        {{ item.gpsLatitude }}, {{ item.gpsLongitude }}
      </template>

      <template #[`item.client`]="{item}">
        <v-chip
          v-if="item.client.name != ''"
          color="white"
          @click="redirect('clients', item.client.name)"
        >
          {{ item.client.name }}
        </v-chip>
      </template>

      <template #[`item.created`]="{item}">
        {{ item.created | gmtTime | dateWithTime }}
      </template>

      <template #loading>
        <v-skeleton-loader class="mx-auto" max-width="80%" type="table-row@3" />
      </template>
      <!-- <template #no-data>
        <v-card-text class="pa-8">
          <v-icon slot="icon" size="64" class="mb-6">
            mdi-city
          </v-icon>
          <p class="display-1 text--primary">
            {{ $t("entity.empty.headline", { entities: $tc("common.location", 2) }) }}
          </p>
          <p>{{ $t("entity.empty.description", { entities: $tc("common.location", 2) }) }}</p>
          <v-btn tile color="blue lighten-4" @click="openDialog">
            {{ $t("entity.add", { entity: $tc("common.location", 1) }) }}
          </v-btn>
        </v-card-text>
      </template> -->
      <!-- <template #[`item.action`]="{ item }">
        <span class="actions">
          <v-icon small class="mr-2" @click.stop="editItem(item)">
            mdi-pencil
          </v-icon>
          <v-icon small class="mr-2" @click.stop="addZoneOnly(item)">
            mdi-domain
          </v-icon>
          <v-icon small class="mr-2" @click.stop="addDeviceOnly(item)">
            mdi-robot-vacuum
          </v-icon>
          <v-icon v-if="isAdmin" small @click.stop="confirmDeleteItem(item)">
            mdi-delete
          </v-icon>
        </span>
      </template> -->
    </v-data-table>
    <!-- <v-dialog v-model="dialog" max-width="640px">
      <LocationFormContainer
        :location="locationToEdit"
        :names="locationsNames"
        :coordinates="locationsCoordinates"
        @close="closeDialog"
        @updateLocation="updateLocation"
        @createLocation="createLocation"
      />
    </v-dialog>
    <DialogConfirm
      :show="confirmDialog"
      :danger="true"
      @cancel="closeConfirmDialog"
      @confirm="deleteItem"
    >
      {{ $t("confirmation.delete.title") }}
    </DialogConfirm>
    <v-dialog v-model="addFormDialog" max-width="640px" @click:outside="closeAddFormDialog">
      <AddForm
        :add-only-zone="addOnlyZone"
        :add-only-device="addOnlyDevice"
        :location="addedLocation"
        @close="closeAddFormDialog"
        @addZone="addZone"
        @addDevice="addDevice"
      />
    </v-dialog> -->
  </v-card>
</template>

<script>
import {
  deleteLocation,
  fetchSimpleLocations,
  fetchLocationsZones,
  fetchLocationsRobots,
  fetchLocationsEnv,
  fetchLocationsDev,
  fetchPartners,
} from '@/data';
// import LocationFormContainer from '@/views/Management/Locations/FormContainer';
// import AddForm from '@/views/Management/Locations/AddForm';
import ManagementBar from '@/components/ManagementBar';
// import DialogConfirm from '@/components/Dialog/Confirm';
import { EventBus } from '@/eventBus';
import Location from '@/model/location/Location';
import { headerSearch } from '@/helpers/filteringManagementList';
import { compare } from '@/helpers/sortManagementList';

export default {
  name: 'ManagementLocationList',
  components: {
    ManagementBar,
    // LocationFormContainer,
    // DialogConfirm,
    // AddForm,
  },
  inject: ['authGuard'],
  props: {
    searchName: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      partners: [],
      selectedPartner: null,
      zones: [],
      robotZones: [],
      envZones: [],
      devices: [],
      addOnlyZone: false,
      addOnlyDevice: false,
      expanded: [],
      newLocation: null,
      locations: [],
      locationToEdit: null,
      locationToRemove: null,
      search: this.searchName,
      dialog: false,
      confirmDialog: false,
      addFormDialog: false,
      headers: [
        { text: this.$t('table.location.header.name'), value: 'name', width: '220px' },
        {
          text: this.$t('table.location.header.address'),
          value: 'address',
          width: '270px',
          sortable: false,
        },
        {
          text: 'GPS',
          value: 'gpsLatitude',
          width: '180px',
          sortable: false,
        },
        {
          text: this.$t('table.location.header.assignedZones'),
          value: 'zoneZone',
          width: '300px',
          sortable: false,
        },
        {
          text: this.$t('table.location.header.assignedMachines'),
          value: 'devices',
          width: '400px',
          sortable: false,
        },
        { text: this.$t('table.location.header.client'), value: 'client', width: '180px' },
        {
          text: this.$t('table.location.header.partners'), value: 'partnersIdentifier', sortable: false, width: '180px',
        },
        { text: this.$t('table.location.header.created'), value: 'created', width: '170px' },
        {
          text: '',
          value: 'action',
          filterable: false,
          sortable: false,
          width: '150px',
        },
      ],
      loadingLoc: true,
      loadingZone: true,
      loadingRobot: true,
      loadingEnv: true,
      loadingDevice: true,
      loadingPartners: true,
    };
  },
  computed: {
    addedLocation: {
      get() {
        return this.newLocation;
      },
      set(location) {
        this.newLocation = location;
      },
    },
    locationsNames() {
      const locationsNames = this.locationsToDisplay.map((e) => e.name.toLowerCase().trim());

      if (this.locationToEdit) {
        const index = locationsNames.indexOf(this.locationToEdit.name.toLowerCase().trim());
        if (index >= 0) {
          locationsNames.splice(index, 1);
        }
      }

      return locationsNames;
    },
    locationsCoordinates() {
      const locationsCoordinates = [];
      this.locationsToDisplay.forEach((location) => {
        if (!this.locationToEdit) {
          locationsCoordinates.push({
            lat: location.gpsLatitude,
            long: location.gpsLongitude,
            clientId: location.clientIdentifier,
            name: location.name,
          });
        } else if (this.locationToEdit.id !== location.id) {
          locationsCoordinates.push({
            lat: location.gpsLatitude,
            long: location.gpsLongitude,
            clientId: location.clientIdentifier,
            name: location.name,
          });
        }
      });

      return locationsCoordinates;
    },
    isSuperAdmin() {
      return this.authGuard.isGranted('n3-superadmin');
    },
    isAdmin() {
      return (
        this.authGuard.isGranted('n3-superadmin')
        || this.authGuard.isGranted('n3-admin')
        || this.authGuard.isGranted('n3-admin-mid-leader')
        || this.authGuard.isGranted('n3-partner')
      );
    },
    disabledInputs() {
      if (
        this.authGuard.isGranted('n3-admin')
        || this.authGuard.isGranted('n3-superadmin')
        || this.authGuard.isGranted('n3-admin-mid-leader')
        || this.authGuard.isGranted('n3-partner')
      ) {
        return [];
      }

      return ['client', 'name'];
    },
    mergedLocations() {
      if (this.loading) {
        return [];
      }
      const mergedLocations = [];
      this.locations.forEach((location) => {
        const newLocation = JSON.parse(JSON.stringify(location));
        newLocation.zones = newLocation.zones
          ? newLocation.zones
          : this.zones.filter((e) => e.location_id === location.loc_id);
        newLocation.robotszone = newLocation.robotszone
          ? newLocation.robotszone
          : this.robotZones.filter((e) => e.location_id === location.loc_id);
        newLocation.environmentszone = newLocation.environmentszone
          ? newLocation.environmentszone
          : this.envZones.filter((e) => e.location_id === location.loc_id);
        newLocation.n3devices = newLocation.n3devices
          ? newLocation.n3devices
          : this.devices.filter((e) => e.location_id === location.loc_id);
        mergedLocations.push(newLocation);
      });

      return mergedLocations;
    },
    locationsToDisplay() {
      if (!this.mergedLocations) {
        return [];
      }
      const locationsToDisplay = [];
      this.mergedLocations.forEach((location) => {
        if (location.n3client == null) {
          /* eslint-disable-next-line */
          location.n3client = { name: "" };
        }
        locationsToDisplay.push(new Location(location));
      });

      if (this.selectedPartner) {
        return locationsToDisplay.filter((location) => location.partnersIds.includes(this.selectedPartner));
      }

      return locationsToDisplay;
    },
    loading() {
      return (
        this.loadingLoc
        || this.loadingZone
        || this.loadingRobot
        || this.loadingEnv
        || this.loadingDevice
        || this.loadingPartners
      );
    },
  },
  watch: {
    dialog(newVal, oldVal) {
      if (oldVal === true && newVal === false) {
        this.locationToEdit = null;
      }
    },
  },
  created() {
    this.loadLocations();
    this.loadZones();
    this.loadRobotZones();
    this.loadEnvZones();
    this.loadDevices();
    this.loadPartners();
  },
  methods: {
    async loadLocations() {
      try {
        this.locations = await fetchSimpleLocations();
        this.loadingLoc = false;
      } catch (e) {
        EventBus.$emit('generalErrorOccurred', {
          message: 'Cannot load data. Try again in a few seconds',
        });
        console.error(e); // eslint-disable-line
      }
    },
    async loadZones() {
      try {
        this.zones = await fetchLocationsZones();
        this.loadingZone = false;
      } catch (e) {
        EventBus.$emit('generalErrorOccurred', {
          message: 'Cannot load data. Try again in a few seconds',
        });
        console.error(e); // eslint-disable-line
      }
    },
    async loadRobotZones() {
      try {
        this.robotZones = await fetchLocationsRobots();
        this.loadingRobot = false;
      } catch (e) {
        EventBus.$emit('generalErrorOccurred', {
          message: 'Cannot load data. Try again in a few seconds',
        });
        console.error(e); // eslint-disable-line
      }
    },
    async loadEnvZones() {
      try {
        this.envZones = await fetchLocationsEnv();
        this.loadingEnv = false;
      } catch (e) {
        EventBus.$emit('generalErrorOccurred', {
          message: 'Cannot load data. Try again in a few seconds',
        });
        console.error(e); // eslint-disable-line
      }
    },
    async loadDevices() {
      try {
        this.devices = await fetchLocationsDev();
        this.loadingDevice = false;
      } catch (e) {
        EventBus.$emit('generalErrorOccurred', {
          message: 'Cannot load data. Try again in a few seconds',
        });
        console.error(e); // eslint-disable-line
      }
    },
    async loadPartners() {
      try {
        this.partners = await fetchPartners();
        this.loadingPartners = false;
      } catch (e) {
        EventBus.$emit('generalErrorOccurred', {
          message: 'Cannot load data. Try again in a few seconds',
        });
        console.error(e); // eslint-disable-line
      }
    },
    editItem(item) {
      this.locationToEdit = item;
      this.openDialog();
    },
    addZoneOnly(item) {
      this.addedLocation = item;
      this.addOnlyDevice = false;
      this.addOnlyZone = true;
      this.openAddFormDialog();
    },
    addDeviceOnly(item) {
      this.addedLocation = item;
      this.addOnlyZone = false;
      this.addOnlyDevice = true;
      this.openAddFormDialog();
    },
    openDialog() {
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
    },
    openAddFormDialog() {
      this.addFormDialog = true;
    },
    closeAddFormDialog() {
      this.addedLocation = null;
      this.addFormDialog = false;
    },
    addDevice(device) {
      const [location] = this.locationsToDisplay.filter((e) => e.id === this.addedLocation.id);
      location.addDevice(device);
    },
    addZone({ createdZone, category }) {
      const [location] = this.locationsToDisplay.filter((e) => e.id === this.addedLocation.id);
      location.addZone(createdZone, category);
      const existingDevice = location.devices.filter((e) => e.id === createdZone.n3device.device_id);
      if (!existingDevice.length) {
        location.addDevice(createdZone.n3device);
      }
    },
    confirmDeleteItem(item) {
      if (
        this.authGuard.isGranted('n3-admin')
        || this.authGuard.isGranted('n3-superadmin')
        || this.authGuard.isGranted('n3-admin-mid-leader')
      ) {
        this.locationToRemove = item;
        this.confirmDialog = true;
      }
    },
    closeConfirmDialog() {
      this.confirmDialog = false;
    },
    updateLocation(location) {
      const index = this.locationsToDisplay.indexOf(this.locationToEdit);
      this.locations.splice(index, 1, location);
    },
    createLocation(location) {
      this.locations.push(location);
      this.addedLocation = new Location(location);
      this.addOnlyZone = false;
      this.addOnlyDevice = false;
      this.openAddFormDialog();
    },
    deleteItem() {
      const index = this.locationsToDisplay.indexOf(this.locationToRemove);
      if (index < 0) {
        console.error("This should not happen"); // eslint-disable-line

        return;
      }
      this.locations.splice(index, 1);
      this.closeConfirmDialog();
      deleteLocation(this.locationToRemove.id).catch((error) => {
        EventBus.$emit('generalErrorOccurred', {
          message: 'Cannot remove location',
        });
        this.locations.splice(index, 0, {
          loc_id: this.locationToRemove.id,
          loc_name: this.locationToRemove.name,
          address: this.locationToRemove.address,
          n3devices: this.locationToRemove.devices,
          zones: this.locationToRemove.zoneZone,
          robotszone: this.locationToRemove.robotZone,
          environmentszone: this.locationToRemove.environmentZone,
          gps_latitude: this.locationToRemove.gpsLatitude,
          gps_longitude: this.locationToRemove.gpsLongitude,
          n3client: this.locationToRemove.client,
          created: this.locationToRemove.created,
        });
        console.error(error); // eslint-disable-line
      });
    },
    expandRow(value) {
      if (this.expanded.includes(value)) {
        this.expanded = [];
      } else {
        this.expanded = [value];
      }
    },
    redirect(type, item) {
      this.$router.push({ name: `management.${type}`, params: { searchName: `${item}` } });
    },
    customSort(items, index, isDescending) {
      items.sort((a, b) => {
        switch (index[0]) {
          case 'name':
            return compare(a.name, b.name, isDescending);
          case 'client':
            return compare(a.client.name, b.client.name, isDescending);
          case 'created':
            return compare(a.created, b.created, isDescending);
          default:
            return items;
        }
      });

      return items;
    },

    customSearch(value, search, location) {
      let toReturn;
      const clientSearched = location.client.name.toString().toLowerCase();
      toReturn = headerSearch(value, search, clientSearched);
      if (toReturn === false && location.zoneZone.length) {
        location.zoneZone.forEach((element) => {
          if (toReturn === false) {
            const zoneSearched = element.name.toString().toLowerCase();
            toReturn = headerSearch(value, search, zoneSearched);
          }
        });
      }
      if (toReturn === false && location.robotZone.length) {
        location.robotZone.forEach((element) => {
          if (toReturn === false) {
            const robotSearched = element.name.toString().toLowerCase();
            toReturn = headerSearch(value, search, robotSearched);
          }
        });
      }
      if (toReturn === false && location.environmentZone.length) {
        location.environmentZone.forEach((element) => {
          if (toReturn === false) {
            const environmentSearched = element.name.toString().toLowerCase();
            toReturn = headerSearch(value, search, environmentSearched);
          }
        });
      }
      if (toReturn === false && location.devices !== null) {
        location.devices.forEach((element) => {
          if (toReturn === false) {
            const deviceSearched = element.name.toString().toLowerCase();
            toReturn = headerSearch(value, search, deviceSearched);
          }
        });
      }

      return toReturn;
    },
  },
};
</script>
<style lang="scss" scoped>
table {
  tr {
    .actions {
      .v-icon {
        visibility: hidden;
      }
    }
    &:hover {
      .actions {
        .v-icon {
          visibility: visible;
        }
      }
    }
  }
}
.v-select {
    width: 1%;
    justify-content: center;
    margin-top: 25px;
}
</style>
