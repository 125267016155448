<template>
  <div class="fill-height">
    <LoaderWidgetLoader v-if="loading" />
    <ChartHorizontalBar
      v-else
      class="chart-container"
      :chart-data="chartData"
      :options="chartOptions"
    />
  </div>
</template>

<script>
import ChartHorizontalBar from '@/components/Chart/HorizontalBar';
import LoaderWidgetLoader from '@/components/Loader/WidgetLoader';

/**
 * @TODO needs to be rethinked then redesigned then refactored
 * Note that `WidgetCleaningActivityChart` is very similar
 * also note that this component is used in two context: DrillDown and Dashboard
 */
export default {
  name: 'WidgetZoneCleaningActivityChart',
  components: {
    ChartHorizontalBar,
    LoaderWidgetLoader,
  },
  props: {
    location: {
      type: Object,
      default: () => {},
    },
    status: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: null,
    },
    category: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      chartOptions: {
        maintainAspectRatio: false,
        legend: {
          display: false,
        },
        scales: {
          xAxes: [{
            ticks: {
              suggestedMin: 0,
            },
          }],
        },
      },
    };
  },
  computed: {
    chartData() {
      let cleaningTime = 0;
      let expected = 0;
      let otherActivity = 0;
      let locationZones = this.location.zone;
      otherActivity += this.location.otherActivity;
      if (this.status.length) {
        locationZones = locationZones
          .filter((zone) => this.status.indexOf(zone.todayCleaningStatus) >= 0);
      }
      locationZones.forEach((zone) => {
        cleaningTime += zone.cleaningTimesSumForToday;
        expected += zone.expectedCleaningTime;
      });

      return {
        labels: this.labels(),
        datasets: [{
          backgroundColor: [
            '#0D91E3',
            '#8644A2',
            '#737373',
          ],
          data: this.dataToDisplay(cleaningTime, expected, otherActivity),
        }],
      };
    },
    loading() {
      if (Object.keys(this.location).length) {
        return false;
      }

      return true;
    },
  },
  created() {
    if (typeof this.title === 'string') {
      this.chartOptions.title = {
        display: true,
        text: this.title,
      };
    }
  },
  methods: {
    labels() {
      if (this.category === 'zones') {
        return [this.$t('dashboard.location.cleaningTime'), this.$t('dashboard.expected'), this.$t('dashboard.otherActivity')];
      }

      return [this.$t('dashboard.location.cleaningTime'), this.$t('dashboard.expected')];
    },
    dataToDisplay(cleaningTime, expected, otherActivity) {
      if (this.category === 'zones') {
        return [cleaningTime, expected, otherActivity];
      }

      return [cleaningTime, expected];
    },
  },
};

</script>

<style lang="scss" scoped>
  .chart-container {
    position: relative;
    height: 100%;
    width: 100%;
  }
</style>
