<template>
  <div>
    <LoaderWidgetLoader v-if="loading" />
    <div v-else>
      <table>
        <thead>
          <tr>
            <th id="type" role="columnheader">
              <span>{{ '' }}</span>
            </th>
            <th id="locationName" role="columnheader">
              <span>{{ $t('report.table.location') }}</span>
            </th>
            <th
              v-for="(date, index) in dates"
              :key="index"
              role="columnheader"
            >
              <span>{{ new Intl.DateTimeFormat(language,
                                               { weekday: 'short' }).format(new Date(date)) }}</span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr :style="dateColor">
            <td>
              {{ $t('report.table.date') }}
            </td>
            <td>
              {{ "" }}
            </td>
            <td
              v-for="(date, index) in dates"
              :key="index"
              class="days"
            >
              <span>
                {{ index + 1 }}
              </span>
            </td>
          </tr>
          <tr v-for="location in report.locations" :key="location.id + location.type" :style="location.rowColorStyle">
            <td v-if="location.type==='expected'">
              {{ $t('report.table.expected') }}
            </td>
            <td v-else>
              {{ $t('report.table.cleaning') }}
            </td>
            <td>
              {{ location.name }}
            </td>
            <td
              v-for="(date, index) in dates"
              :key="index"
            >
              <span v-if="location.type==='expected'">
                {{ location.getExpetedTime(date) }}
              </span>
              <span v-else>
                {{ location.getCleaningTime(date) }}
              </span>
            </td>
          </tr>
          <tr
            v-if="typeOfReport==='many'"
            style="background-color:#cccccc"
          >
            <td>
              {{ $t('report.table.totalExpected') }}
            </td>
            <td>
              {{ "" }}
            </td>
            <td
              v-for="(date, index) in dates"
              :key="index"
            >
              <span>
                {{ report.totalExpectedTime(date) }}
              </span>
            </td>
          </tr>
          <tr
            v-if="typeOfReport==='many'"
            style="background-color:#cccccc"
          >
            <td>
              {{ $t('report.table.totalCleaning') }}
            </td>
            <td>
              {{ "" }}
            </td>
            <td
              v-for="(date, index) in dates"
              :key="index"
            >
              <span>
                {{ report.totalCleaningTime(date) }}
              </span>
            </td>
          </tr>
          <tr
            style="background-color:#cccccc"
          >
            <td>
              <span v-if="typeOfReport==='many'">{{ $t('report.table.totalDiscrepancy') }}</span>
              <span v-else>{{ $t('report.table.discrepancy') }}</span>
            </td>
            <td>
              {{ "" }}
            </td>
            <td
              v-for="(date, index) in dates"
              :key="index"
              :style="evaluateColor(date)"
            >
              <span :style="evaluateColor(date)">
                {{ report.totalDiscrepancy(date) }}
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>

import LoaderWidgetLoader from '@/components/Loader/WidgetLoader';
import i18n from '@/plugin/i18n';

export default {
  name: 'CleaningMonthlyReport',
  components: {
    LoaderWidgetLoader,
  },
  props: {
    report: {
      type: Object,
      required: true,
    },
    dates: {
      type: Array,
      required: true,
    },
    typeOfReport: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      language: i18n.locale,
    };
  },
  computed: {
    loading() {
      return !Object.keys(this.report).length;
    },
    dateColor() {
      return (this.report?.locationsForCalculation?.length % 2 === 0)
        ? 'background-color:#ffffff'
        : 'background-color:#e6e6e6';
    },
  },
  methods: {
    evaluateColor(date) {
      if (this.report) {
        const discrepancy = this.report.totalDiscrepancy(date);
        const expected = this.report.totalExpectedTime(date);
        const percentageValue = ((expected - discrepancy) / expected) * 100;
        if (expected === 0) {
          return 'color:#006400;font-weight:bold';
        }
        if (percentageValue >= 81) {
          return 'color:#006400;font-weight:bold';
        }
        if (percentageValue <= 10) {
          return 'color:#FF0000;font-weight:bold';
        }

        return 'color:#ff9900;font-weight:bold';
      }

      return '';
    },
  },
};

</script>
<style  lang="scss" scoped>
  table {
    border-collapse: collapse;
    text-align: center;
    font-family: 'Roboto', sans-serif;
 }
 table thead tr th {
    font-size: 10px;
    color: gray;
    border-bottom: 1px solid gray;
 }
 table tbody tr {
   page-break-inside: avoid;
 }
  table tbody tr td {
    font-size: 10px;
    height: 30px;
    border-bottom: 1px solid lightgray;
    page-break-inside: avoid;
 }
 #type {
     width: 100px;
 }
  #locationName {
     width: 100px;
 }
  .days {
     width: 30px;
 }
</style>
