<template>
  <v-progress-circular
    indeterminate
    color="primary"
    :size="size"
    :width="width"
  />
</template>

<script>
export default {
  name: 'LoaderWidgetLoader',
  props: {
    size: {
      type: Number,
      default: 22,
    },
    width: {
      type: Number,
      default: 2,
    },
  },
};

</script>
