import { SubcategoriesNames, CategoriesNames } from '@/model/CategoriesObjects';
import { ZoneZone, RobotZone } from '@/model/zones/SubZones';
import EnvironmentZone from '@/model/zones/EnvironmentZone';
import {
  N3Evo, N3Robot, N3IAQ, N3Connector, Beacon, N3RobotB,
} from '@/model/devices/SubDevices';

export default class Location {
  constructor(payload) {
    this.id = payload.loc_id;
    this.name = payload.loc_name;
    this.address = payload.address;
    this.devices = this.assignDevice(payload.n3devices);
    this.zoneZone = this.assignZoneZones(payload.zones);
    this.robotZone = this.assignRobotZones(payload.robotszone);
    this.partnersLocs = payload.partner_has_locs;
    this.partnersIdentifier = this.partners;
    this.partnersIds = this.partners.map((e) => e.partner_id);
    this.environmentZone = this.assignEnvironmentZones(payload.environmentszone);
    this.gpsLatitude = payload.gps_latitude;
    this.gpsLongitude = payload.gps_longitude;
    this.client = payload.n3client;
    this.clientIdentifier = this.clientId;
    this.created = payload.created;
    this.statuses = payload.statuses;
    this.exist = payload.exist;
    this.times = payload.times;
    this.userLoc = payload.user_has_loc;
    this.filteredZoneZone = this.zoneZone;
    this.filteredRobotZone = this.robotZone;
  }

  get clientId() {
    return this.client?.id;
  }

  get hasZone() {
    return !!this.exist?.length;
  }

  get usersIds() {
    return this.userLoc.map((e) => e.user_id);
  }

  get partners() {
    return this.partnersLocs ? this.partnersLocs.map((e) => e.n3partner) : [];
  }

  assignZoneZones(zones) {
    const savedZones = [];
    if (zones && Array.isArray(zones)) {
      zones.forEach((zone) => {
        if (zone.zone_id) {
          savedZones.push(new ZoneZone(zone));
        }
      });
    }

    return savedZones;
  }

  assignRobotZones(zones) {
    const savedZones = [];
    if (zones && Array.isArray(zones)) {
      zones.forEach((zone) => {
        if (zone.zone_id) {
          savedZones.push(new RobotZone(zone));
        }
      });
    }

    return savedZones;
  }

  assignEnvironmentZones(zones) {
    const savedZones = [];
    if (zones && Array.isArray(zones)) {
      zones.forEach((zone) => {
        if (zone.zone_id) {
          savedZones.push(new EnvironmentZone(zone));
        }
      });
    }

    return savedZones;
  }

  assignDevice(devices) {
    const savedDevices = [];
    if (devices && Array.isArray(devices)) {
      devices.forEach((device) => {
        switch (SubcategoriesNames[device.sub_category_name]) {
          case SubcategoriesNames.N3Evo:
            savedDevices.push(new N3Evo(device));
            break;
          case SubcategoriesNames.N3Robot:
            savedDevices.push(new N3Robot(device));
            break;
          case SubcategoriesNames.Beacon:
            savedDevices.push(new Beacon(device));
            break;
          case SubcategoriesNames.N3RobotB:
            savedDevices.push(new N3RobotB(device));
            break;
          case SubcategoriesNames.N3IAQ:
            savedDevices.push(new N3IAQ(device));
            break;
          case SubcategoriesNames.N3Connector:
            savedDevices.push(new N3Connector(device));
            break;
          default:
            break;
        }
      });
    }

    return savedDevices;
  }

  addDevice(device) {
    switch (SubcategoriesNames[device.sub_category_name]) {
      case SubcategoriesNames.N3Evo:
        this.devices.push(new N3Evo(device));
        break;
      case SubcategoriesNames.N3Robot:
        this.devices.push(new N3Robot(device));
        break;
      case SubcategoriesNames.Beacon:
        this.devices.push(new Beacon(device));
        break;
      case SubcategoriesNames.N3RobotB:
        this.devices.push(new N3RobotB(device));
        break;
      case SubcategoriesNames.N3IAQ:
        this.devices.push(new N3IAQ(device));
        break;
      case SubcategoriesNames.N3Connector:
        this.devices.push(new N3Connector(device));
        break;
      default:
        break;
    }
  }

  addZone(zone, category) {
    switch (category) {
      case CategoriesNames.N3Zone:
        this.zoneZone.push(new ZoneZone(zone));
        break;
      case CategoriesNames.N3Robot:
        this.robotZone.push(new RobotZone(zone));
        break;
      case CategoriesNames.N3Environment:
        this.environmentZone.push(new EnvironmentZone(zone));
        break;
      default:
        break;
    }
  }

  filterZone(partnerId) {
    if (!partnerId) {
      this.filteredZoneZone = this.zoneZone;
      this.filteredRobotZone = this.robotZone;
    } else {
      this.filteredZoneZone = this.zoneZone.filter((zone) => zone.partnerId === partnerId);
      this.filteredRobotZone = this.robotZone.filter((zone) => zone.partnerId === partnerId);
    }
  }
}
