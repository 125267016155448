<template>
  <v-card class="fill-height">
    <ManagementBar :title="$tc('common.device', 2).capitalize()" :listsize="selectedDevices.length">
      <v-text-field
        v-model="search"
        prepend-inner-icon="mdi-magnify"
        :label="`${$t('entity.find', { entity: $tc('common.device', 1) })}...`.capitalize()"
        single-line
        hide-details
      />
      <!-- <template #actions>
        <v-btn
          color="success"
          small
          @click.stop="openDialog"
        >
          <v-icon small>
            mdi-plus
          </v-icon>
          {{ $t('entity.new', { entity: $tc('common.device', 1) }) }}
        </v-btn>
      </template> -->
      <v-select
        v-model="category"
        class="v-select"
        flat
        solo
        :loading="loading"
        :items="categoriesArray"
        label="Category"
        hide-no-data
      />
      <v-select
        v-if="isSuperAdmin"
        v-model="selectedPartner"
        :loading="loading"
        :items="partners"
        item-text="name"
        item-value="partner_id"
        class="v-select"
        flat
        solo
        :label="$t('common.partner')"
        clearable
      />
    </ManagementBar>
    <v-data-table
      :headers="headers"
      :items="selectedDevices"
      :search="search"
      :loading="loading"
      :footer-props="{
        itemsPerPageOptions:[20, 50, 100, -1]
      }"
      :disable-pagination="false"
      :custom-sort="customSort"
      :custom-filter="customSearch"
      item-key="id"
      fixed-header
    >
      <template #loading>
        <v-skeleton-loader
          class="mx-auto"
          max-width="80%"
          type="table-row@3"
        />
      </template>
      <template #[`item.client`]="{item}">
        <v-chip v-if="item.client" color="white" @click="redirect('clients', item.client.name)">
          {{ item.client.name }}
        </v-chip>
      </template>
      <template #[`item.location`]="{item}">
        <v-chip v-if="item.location" color="white" @click="redirect('locations', item.location.loc_name)">
          {{ item.location.loc_name }}
        </v-chip>
      </template>
      <template #[`item.gpsLatitude`]="{item}">
        {{ item.gpsLatitude }}, {{ item.gpsLongitude }}
      </template>

      <template #[`item.created`]="{item}">
        {{ item.created | gmtTime | dateWithTime }}
      </template>

      <!-- <template #no-data>
        <v-card-text class="pa-8">
          <v-icon
            slot="icon"
            size="64"
            class="mb-6"
          >
            mdi-city
          </v-icon>
          <p class="display-1 text--primary">
            {{ $t('entity.empty.headline', { entities: $tc('common.device', 2) }) }}
          </p>
          <p>{{ $t('entity.empty.description', { entities: $tc('common.device', 2) }) }}</p>
          <v-btn
            tile
            color="blue lighten-4"
            @click.stop="openDialog"
          >
            {{ $t('entity.add', { entity: $tc('common.device', 1) }) }}
          </v-btn>
        </v-card-text>
      </template> -->
      <template #[`item.n3partner`]="{item}">
        <span>
          <v-chip v-if="item.n3partner" color="white" @click="(isSuperAdmin) ? redirect('partners', item.n3partner.name) : () => {}">
            {{ item.n3partner.name }}
          </v-chip>
        </span>
      </template>
      <!-- <template #[`item.action`]="{ item }">
        <span class="actions">
          <v-icon
            small
            class="mr-2"
            @click.stop="editItem(item)"
          >
            mdi-pencil
          </v-icon>
          <v-icon
            small
            @click.stop="confirmDeleteItem(item)"
          >
            mdi-delete
          </v-icon>
        </span>
      </template> -->
    </v-data-table>
    <!-- <v-dialog v-model="dialog" max-width="600px">
      <DeviceFormContainer
        :locations="locations"
        :device="deviceToEdit"
        :disabled="disabledInputs"
        :is-update="update"
        @updateDevice="updateDevice"
        @createDevice="createDevice"
        @closeDeviceDialog="closeDialog"
      />
    </v-dialog>
    <DialogConfirm
      :show="confirmDialog"
      :danger="true"
      @cancel="closeConfirmDialog"
      @confirm="deleteItem"
    >
      {{ $t('confirmation.delete.title') }}
    </DialogConfirm> -->
  </v-card>
</template>

<script>
import {
  fetchDevices, deleteDevice, fetchLocationForZonesAndDevices,
  deleteZone, deleteRobotZone, deleteEnvironmentZone, fetchPartners,
} from '@/data';
// import DeviceFormContainer from '@/views/Management/Devices/FormContainer';
import ManagementBar from '@/components/ManagementBar';
// import DialogConfirm from '@/components/Dialog/Confirm';
import { EventBus } from '@/eventBus';
import { returnCategoryFromSubcategoryName } from '@/helpers/categories';
import {
  N3Evo, N3Robot, N3IAQ, N3Connector, Beacon, N3RobotB,
} from '@/model/devices/SubDevices';
import {
  CategoriesNames, SubcategoriesNames,
} from '@/model/CategoriesObjects';
import { headerSearch } from '@/helpers/filteringManagementList';
import { compare, compareObjects } from '@/helpers/sortManagementList';

export default {
  name: 'DpManagementDevices',
  components: {
    // DialogConfirm,
    ManagementBar,
    // DeviceFormContainer,
  },
  inject: ['authGuard'],
  props: {
    searchName: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      update: false,
      devices: [],
      locations: [],
      category: 'All',
      search: this.searchName,
      loading: false,
      dialog: false,
      confirmDialog: false,
      deviceToEdit: null,
      deviceToRemove: null,
      headerGPS: { text: this.$t('table.device.header.gps'), value: 'gpsLatitude' },
      headersAll: [
        { text: this.$t('table.device.header.name'), value: 'name', width: '220px' },
        { text: this.$t('table.device.header.externalEquipment'), value: 'externalEquipment', width: '170px' },
        { text: this.$t('table.device.header.sensoridtype'), value: 'type', width: '110px' },
        { text: this.$t('table.device.header.sensoridvalue'), value: 'sensorIdentifier', width: '200px' },
        { text: this.$t('table.device.header.category'), value: 'category', width: '140px' },
        { text: this.$t('table.device.header.subcategory'), value: 'subcategory', width: '120px' },
        { text: this.$t('table.device.header.location'), value: 'location', width: '200px' },
        { text: this.$t('table.device.header.client'), value: 'client', width: '180px' },
        {
          text: this.$t('table.zone.header.partner'),
          value: 'n3partner',
          width: '170px',
        },
        { text: this.$t('table.device.header.created'), value: 'created', width: '170px' },
        {
          text: '',
          value: 'action',
          filterable: false,
          sortable: false,
          width: '80px',
        },
      ],
      ALL_CATEGORIES: 'All',
      disabledInputs: ['category', 'subcategory'],
      partners: [],
      selectedPartner: null,
    };
  },
  computed: {
    isSuperAdmin() {
      return this.authGuard.isGranted('n3-superadmin');
    },
    selectedDevices() {
      const devices = (this.category === this.ALL_CATEGORIES)
        ? this.devicesToDisplay : this.devicesToDisplay.filter((e) => e.category === this.category);

      if (this.selectedPartner) {
        return devices.filter((device) => device.partnerId === this.selectedPartner);
      }

      return devices;
    },
    headers() {
      const headersToDisplay = this.headersAll.slice();
      if (this.category === CategoriesNames.N3Gateway) {
        headersToDisplay.splice(headersToDisplay.length - 2, 0, this.headerGPS);
      }

      return headersToDisplay;
    },
    categoriesArray() {
      const tempCategories = Object.values(CategoriesNames).slice();
      tempCategories.push('All');

      return tempCategories;
    },
    devicesToDisplay() {
      if (!this.devices) {
        return [];
      }
      const devicesToDisplay = [];
      this.devices.forEach((device) => {
        const newAssignDevice = this.assignSensorToCategory(device);
        if (newAssignDevice) {
          devicesToDisplay.push(newAssignDevice);
        }
      });

      return devicesToDisplay;
    },
  },
  watch: {
    dialog(newVal, oldVal) {
      if (oldVal === true && newVal === false) {
        this.deviceToEdit = null;
        this.update = false;
      }
    },
  },
  created() {
    this.loadDevices();
    this.loadLocations();
    this.loadPartners();
  },
  methods: {
    async loadDevices() {
      this.loading = true;
      try {
        this.devices = await fetchDevices();

        this.loading = false;
      } catch (e) {
        EventBus.$emit('generalErrorOccurred', {
          message: 'Cannot load data. Try again in a few seconds',
        });
        console.error(e); // eslint-disable-line
      }
    },
    async loadLocations() {
      try {
        this.locations = await fetchLocationForZonesAndDevices();
      } catch (e) {
        EventBus.$emit('generalErrorOccurred', {
          message: 'Cannot load data. Try again in a few seconds',
        });
        console.error(e) // eslint-disable-line
      }
    },
    async loadPartners() {
      try {
        this.partners = await fetchPartners();
      } catch (e) {
        EventBus.$emit('generalErrorOccurred', {
          message: 'Cannot load data. Try again in a few seconds',
        });
        console.error(e); // eslint-disable-line
      }
    },
    returnCategoryFromSubcategoryName,
    openDialog() {
      this.dialog = true;
    },
    closeDialog() {
      this.update = false;
      this.dialog = false;
    },
    confirmDeleteItem(item) {
      this.deviceToRemove = item;
      this.confirmDialog = true;
    },
    closeConfirmDialog() {
      this.confirmDialog = false;
    },
    createDevice(createdDevice) {
      this.devices.push(createdDevice);
    },
    updateDevice(updatedDevice) {
      const device = this.devices.filter((e) => e.device_id === this.deviceToEdit.id);
      const index = this.devices.indexOf(device[0]);
      this.devices.splice(index, 1, updatedDevice);
    },
    editItem(item) {
      this.update = true;
      this.deviceToEdit = item;
      this.openDialog();
    },
    deleteItem() {
      const filteredArray = this.devices.filter((e) => e.device_id === this.deviceToRemove.id);
      const index = this.devices.indexOf(filteredArray[0]);
      if (index < 0) {
        console.error('This should not happen'); // eslint-disable-line

        return;
      }
      this.devices.splice(index, 1);
      this.closeConfirmDialog();
      deleteDevice(this.deviceToRemove.id, this.deviceToRemove.subcategory)
        .then(this.deleteZoneIfExists())
        .catch((error) => {
          EventBus.$emit('generalErrorOccurred', {
            message: 'Cannot remove device',
          });
          this.devices.splice(index, 0, {
            device_id: this.deviceToRemove.id,
            name: this.deviceToRemove.name,
            external_equipment: this.deviceToRemove.externalEquipment,
            sensor_id_value: this.deviceToRemove.sensorIdentifier,
            sub_category_name: this.deviceToRemove.subcategory,
            n3location: this.deviceToRemove.location,
            created: this.deviceToRemove.created,
            n3zones: this.deviceToRemove.n3zones,
            n3robot_zones: this.deviceToRemove.n3robot_zones,
            n3environment_zones: this.deviceToRemove.n3environment_zones,
          });
          console.error(error); // eslint-disable-line
        });
    },
    async deleteZoneIfExists() {
      switch (this.deviceToRemove.category) {
        case CategoriesNames.N3Robot:
          if (this.deviceToRemove.n3robot_zones.length) {
            deleteRobotZone(this.deviceToRemove.n3robot_zones[0].zone_id);
          }
          break;
        case CategoriesNames.N3Zone:
          if (this.deviceToRemove.n3zones.length) {
            deleteZone(this.deviceToRemove.n3zones[0].zone_id);
          }
          break;
        case CategoriesNames.N3Environment:
          if (this.deviceToRemove.n3environment_zones.length) {
            deleteEnvironmentZone(this.deviceToRemove.n3environment_zones[0].zone_id);
          }
          break;
        default:
          break;
      }
    },
    assignSensorToCategory(device) {
      switch (SubcategoriesNames[device.sub_category_name]) {
        case SubcategoriesNames.N3Evo:
          return new N3Evo(device);
        case SubcategoriesNames.N3Robot:
          return new N3Robot(device);
        case SubcategoriesNames.Beacon:
          return new Beacon(device);
        case SubcategoriesNames.N3RobotB:
          return new N3RobotB(device);
        case SubcategoriesNames.N3IAQ:
          return new N3IAQ(device);
        case SubcategoriesNames.N3Connector:
          return new N3Connector(device);
        default:
          return null;
      }
    },
    redirect(type, item) {
      this.$router.push({ name: `management.${type}`, params: { searchName: `${item}` } });
    },
    customSort(items, index, isDescending) {
      items.sort((a, b) => {
        switch (index[0]) {
          case 'name':
            return compare(a.name, b.name, isDescending);
          case 'externalEquipment':
            return compareObjects(a?.externalEquipment, b?.externalEquipment, isDescending);
          case 'type':
            return compare(a.type, b.type, isDescending);
          case 'sensorIdentifier':
            return compare(a.sensorIdentifier, b.sensorIdentifier, isDescending);
          case 'category':
            return compare(a.category, b.category, isDescending);
          case 'subcategory':
            return compare(a.subcategory, b.subcategory, isDescending);
          case 'location':
            /* eslint-disable-next-line */
            return compareObjects(a?.location?.loc_name, b?.location?.loc_name, isDescending);
          case 'client':
            return compareObjects(a?.location?.n3client?.name, b?.location?.n3client?.name, isDescending);
          case 'created':
            return compare(a.created, b.created, isDescending);
          case 'n3partner':
            return compareObjects(a?.n3partner?.name, b?.n3partner?.name, isDescending);
          default: return items;
        }
      });

      return items;
    },
    customSearch(value, search, device) {
      let toReturn = false;
      if (device.location !== null && device.location.n3client !== null) {
        const clientSearched = device.location.n3client.name.toString().toLowerCase();
        toReturn = headerSearch(value, search, clientSearched);
      }
      if (toReturn === false && device.location !== null) {
        const locationSearched = device.location.loc_name.toString().toLowerCase();
        toReturn = headerSearch(value, search, locationSearched);
      }
      if (toReturn === false) {
        toReturn = headerSearch(value, search, '');
      }

      return toReturn;
    },

  },
};
</script>
<style lang="scss" scoped>
  table {
    tr {
      .actions {
        .v-icon {
          visibility: hidden;
        }
      }
      &:hover {
        .actions {
          .v-icon {
            visibility: visible;
          }
        }
      }
    }
  }
  .v-select {
    width: 1%;
    justify-content: center;
    margin-top: 25px;
}
</style>
