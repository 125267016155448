import Location from '@/model/location/Location';
import { CleaningStatus, fromN3Status } from '@/model/CleaningStatus';
import { format } from 'date-fns';
import { N3Connector } from '@/model/devices/SubDevices';

export default class CleaningLocation extends Location {
  constructor(payload) {
    super(payload);
    this.locationStatuses = payload.locStatus;
  }

  get gateways() {
    return this.devices.filter((device) => device instanceof N3Connector);
  }

  get otherActivity() {
    return this.times?.other_activity || 0; // eslint-disable-line
  }

  get cleaningTime() {
    return this.times?.cleaning_time || 0; // eslint-disable-line
  }

  get expectedCleaningTime() {
    return this.times?.expected_time || 0; // eslint-disable-line
  }

  get zone() {
    return (this.filteredZoneZone.length > this.filteredRobotZone.length) ? this.filteredZoneZone : this.filteredRobotZone;
  }
    
  get status() {
    if (this.statuses?.[0]) {
      const { status: n3status } = this.statuses[0];

      return fromN3Status(n3status);
    }

    return CleaningStatus.CLEANED_NONE;
  }

  get computedLocationStatuses() {
    const locStatus = [];
    if (this.locationStatuses) {
      this.locationStatuses.forEach((singleLocStatusData) => {
        locStatus.push(
          {
            // eslint-disable-next-line
              cleanStatusZone: (singleLocStatusData.status_zones === 2)
              ? CleaningStatus.CLEANED_FULL
              : (singleLocStatusData.status_zones === 1)
                ? CleaningStatus.CLEANED_PARTLY
                : CleaningStatus.CLEANED_NONE,
            // eslint-disable-next-line
              cleanStatusRobot: (singleLocStatusData.status_robots === 2)
              ? CleaningStatus.CLEANED_FULL
              : (singleLocStatusData.status_robots === 1)
                ? CleaningStatus.CLEANED_PARTLY
                : CleaningStatus.CLEANED_NONE,
            cleanDate: singleLocStatusData.status_date,
          },
        );
      });
      locStatus.sort((a, b) => {
        if (a.cleanDate > b.cleanDate) return 1;
  
        return -1;
      });
    }
  
    return locStatus;
  }

  getStatusForSpecificDay(date, category) {
    if (category !== 'zones' && category !== 'robots') {
      throw Error(`Wrong category provided: ${category}. Category must by zones or robots`);
    }

    const allZones = [...this.zone];
    if (this.computedLocationStatuses.length > 0) {
      const dayOfWeek = parseInt(format(Date.parse(date), 'i'), 10);
      const notRequired = allZones
        .filter((zone) => zone.excludedDays.includes(dayOfWeek));

      if (allZones.length === notRequired.length) {
        return CleaningStatus.CLEANED_NOT_REQUIRED;
      }
      const cleaningStatus = this.computedLocationStatuses
        .find((status) => status.cleanDate === format(date, 'yyyy-MM-dd'));

      if (typeof cleaningStatus === 'undefined') {
        return CleaningStatus.CLEANED_NONE;
      }
      
      return (category === 'zones') ? cleaningStatus.cleanStatusZone : cleaningStatus.cleanStatusRobot;
    }

    return CleaningStatus.CLEANED_NONE;
  }
}
