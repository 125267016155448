<template>
  <v-chip :color="color" dark>
    {{ text }}
  </v-chip>
</template>
<script>

import { EnvironmentStatus } from '@/model/EnvironmentStatus';
import { environmentTheme } from '@/configuration';

export default {
  name: 'BadgeEnvironmentStatus',
  props: {
    status: {
      type: Number,
      required: true,
    },
  },
  computed: {
    color() {
      return environmentTheme.colors.environmentStatusColor[this.status];
    },
    text() {
      switch (this.status) {
        case EnvironmentStatus.GOOD_CONDITIONS:
          return 'G';
        case EnvironmentStatus.PARTIALY_GOOD_CONDITIONS:
          return 'P';
        case EnvironmentStatus.BAD_CONDITIONS:
          return 'B';
        default:
          return '';
      }
    },
  },
};

</script>
