<template>
  <div class="map-wrapper">
    <div ref="googleMap" />
    <template v-if="Boolean(google) && Boolean(map)">
      <slot :google="google" :map="map" />
    </template>
  </div>
</template>

<script>

import googleMapsApiLoader from 'google-maps-api-loader';
import { GOOGLE_MAP_SETTINGS } from '@/configuration';

const MINIMAL_DIAGONAL_DISTANCE = 100;

export default {
  name: 'GoogleMapLoader',
  props: {
    apiKey: {
      type: String,
      required: true,
    },
    mapOptions: {
      type: Object,
      default: () => (GOOGLE_MAP_SETTINGS),
    },
    bounds: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      google: null,
      map: null,
    };
  },
  watch: {
    bounds(newBounds, oldBounds) {
      if (newBounds.length && newBounds !== oldBounds) {
        this.fitBounds(newBounds);
      }
    },
  },
  async mounted() {
    this.google = await googleMapsApiLoader({
      libraries: ['geometry'],
      apiKey: this.apiKey,
    });
    this.map = new this.google.maps.Map(this.$refs.googleMap, this.mapOptions);
    if (this.bounds.length) {
      this.fitBounds(this.bounds);
    }
  },
  methods: {
    fitBounds(bounds) {
      if (!bounds.length) {
        return;
      }

      const listener = this.google.maps.event.addListener(this.map, 'idle', () => {
        if (this.map.getZoom() > 17) {
          this.map.setZoom(17);
        }
        this.google.maps.event.removeListener(listener);
      });

      const latLngBounds = new this.google.maps.LatLngBounds();
      bounds.forEach(({ lat, lng }) => {
        latLngBounds.extend({ lat, lng });
      });

      // I don't know why, but in our app when bounds are small then map is zoomed out.
      // Below is hack to check if diagonal bounds distance is small and then only set map center,
      // but this requires a `geometry` which cost ~7kB.
      const boundsDiagonalDistance = this.google.maps.geometry.spherical.computeDistanceBetween(
        latLngBounds.getNorthEast(),
        latLngBounds.getSouthWest(),
      );

      if (bounds.length === 1 || boundsDiagonalDistance < MINIMAL_DIAGONAL_DISTANCE) {
        this.map.setCenter(latLngBounds.getCenter());
      } else {
        this.map.fitBounds(latLngBounds);
      }
    },
  },
};

</script>

<style scoped>
  .map-wrapper, .map-wrapper > div {
    width: 100%;
    height: 100%;
  }
</style>
