export function approximationToQuarterHour(date) {
  let newDate;
  if (Number.isInteger(date)) {
    newDate = new Date(date);
  } else {
    newDate = date;
  }
  const minutes = newDate.getMinutes();
  const approximatedDate = new Date(newDate.getTime());
  approximatedDate.setSeconds(0);
  if (minutes < 15) {
    return approximatedDate.setMinutes(0);
  }
  if (minutes < 30) {
    return approximatedDate.setMinutes(15);
  }
  if (minutes < 45) {
    return approximatedDate.setMinutes(30);
  }

  return approximatedDate.setMinutes(45);
}
