<template v-slot:extension>
  <div v-if="name==='dashboard'" class="reports-filter">
    <v-autocomplete
      v-model="currentMidLeader"
      flat
      solo
      dense
      :items="midLeaders"
      item-text="full_name"
      item-value="user_id"
      :label="$t('form.label.midLeader')"
      :loading="$apollo.queries.midLeaders.loading"
      hide-no-data
      hide-details
      clearable
      @change="updateUrl"
    />
  </div>
</template>

<script>
import { GET_MID_LEADERS } from '@/graphql/midLeaders.graphql';
import { getMidLeader } from '@/router/helpers';

export default {
  name: 'MidLeaderFilter',
  props: {
    email: {
      type: String,
      require: true,
      default: '',
    },
  },
  data() {
    return {
      currentMidLeader: '',
      name: '',
      midLeaders: [],
    };
  },
  apollo: {
    midLeaders: {
      query: GET_MID_LEADERS,
      variables() {
        return {
          email: this.email,
        };
      },
    },
  },
  watch: {
    $route(to) {
      this.currentMidLeader = getMidLeader(to.query);
      this.name = to.name;
    },
  },
  beforeMount() {
    this.name = this.$route.name;
    this.currentMidLeader = getMidLeader(this.$route.query);
  },
  methods: {
    updateUrl(leader) {
      const { name, params } = this.$route;
      const oldQuery = this.$route.query;
      const { status, client, partner } = oldQuery;
      const query = {
        midLeader: leader,
        status,
        client,
        partner,
      };
      this.$router.push({ name, params, query });
    },
  },
};
</script>

<style lang="scss" scoped>
@import './src/styles/variables';

.reports-filter {
  display: flex;
  color: lighten($text-color, 20%);

  &__label {
    margin-right: 10px;
  }

  &__option {
    margin-top: 0;
  }

  &__button {
    &--active {
      color: green;

      span {
        color: black;
      }
    }
  }
}
</style>
