import { EnvironmentStatus, fromN3StatusEnvironment } from '@/model/EnvironmentStatus';
import { N3Connector } from '@/model/devices/SubDevices';
import Location from '@/model/location/Location';

export default class EnvironmentLocation extends Location {
  get status() {
    if (this.statuses?.[0]) {
      const { status: n3status } = this.statuses[0];
  
      return fromN3StatusEnvironment(n3status);
    }
      
    return EnvironmentStatus.BAD_CONDITIONS;
  }

  get gateways() {
    return this.devices.filter((device) => device instanceof N3Connector);
  }

  get zone() {
    return this.environmentZone;
  }
}
