var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"7"}},[_c('v-card',{staticClass:"fill-height widget"},[_c('div',{staticClass:"map-wrapper"},[_c('WidgetZoneMap',{attrs:{"zones":_vm.zones,"gateways":_vm.gateways,"category":_vm.category}})],1)])],1),_c('v-col',{attrs:{"cols":"5"}},[_c('v-card',{staticClass:"widget"},[_c('div',{staticClass:"chart-wrapper"},[_c('WidgetZoneEnvironmentStatusChart',{attrs:{"status":_vm.statuses,"title":_vm.$tc('dashboard.location.environmentStatus'),"zones-chart":_vm.zonesChart},on:{"chart:clicked":_vm.onChartClicked}})],1)])],1)],1),_c('v-row',[_c('v-col',[_c('v-card',{staticClass:"fill-height widget "},[_c('div',{staticClass:"wrapper"},[(_vm.environmentData)?_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.items,"loading":_vm.loading,"single-expand":_vm.singleExpand,"expanded":_vm.expanded,"item-key":"id","show-expand":"","disable-pagination":"","hide-default-footer":""},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([_vm._l((_vm.headers),function(h){return {key:("header." + (h.value)),fn:function(ref){
var header = ref.header;
return [_c('span',{key:h.value},[_vm._v(_vm._s(h.text)+" ")]),(
                    h.value == 'averagedMeasures.dustLevel' ||
                      h.value == 'averagedMeasures.co2' ||
                      h.value == 'averagedMeasures.temperature' ||
                      h.value == 'averagedMeasures.humidity'
                  )?_c('v-tooltip',{key:h.text,attrs:{"top":"","max-width":"200","open-delay":"200"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary","dark":"","small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-help-circle-outline ")])]}}],null,true)},[(h.value == 'averagedMeasures.dustLevel')?_c('span',[_vm._v(_vm._s(_vm.$t("tooltip.environment.PM"))+" ")]):_vm._e(),(h.value == 'averagedMeasures.co2')?_c('span',[_vm._v(_vm._s(_vm.$t("tooltip.environment.PPM"))+" ")]):_vm._e(),(h.value == 'averagedMeasures.temperature')?_c('span',[_vm._v(_vm._s(_vm.$t("tooltip.environment.Temperature"))+" ")]):_vm._e(),(h.value == 'averagedMeasures.humidity')?_c('span',[_vm._v(_vm._s(_vm.$t("tooltip.environment.Humidity"))+" ")]):_vm._e()]):_vm._e()]}}}),{key:"item.averagedMeasures.co2",fn:function(ref){
                  var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.averagedMeasures.co2)+" "),(item.averagedMeasures.co2)?_c('TrendIcon',{attrs:{"status":item.measureStatuses.co2,"trend":item.trend.co2}}):_vm._e()],1)]}},{key:"item.averagedMeasures.dustLevel",fn:function(ref){
                  var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.averagedMeasures.dustLevel)+" "),(item.averagedMeasures.dustLevel)?_c('TrendIcon',{attrs:{"status":item.measureStatuses.dustLevel,"trend":item.trend.dustLevel}}):_vm._e()],1)]}},{key:"item.averagedMeasures.temperature",fn:function(ref){
                  var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.averagedMeasures.temperature)+" "),(item.averagedMeasures.temperature)?_c('TrendIcon',{attrs:{"status":item.measureStatuses.temperature,"trend":item.trend.temperature}}):_vm._e()],1)]}},{key:"item.averagedMeasures.humidity",fn:function(ref){
                  var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.averagedMeasures.humidity)+" "),(item.averagedMeasures.humidity)?_c('TrendIcon',{attrs:{"status":item.measureStatuses.humidity,"trend":item.trend.humidity}}):_vm._e()],1)]}},{key:"item.lastMeasureDate",fn:function(ref){
                  var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.lastMeasureDate)+" ")])]}},{key:"item.zoneStatuses",fn:function(ref){
                  var item = ref.item;
return [_c('BadgeEnvironmentStatus',{attrs:{"status":item.todayEnvironmentStatus}})]}},{key:"item.data-table-expand",fn:function(ref){
                  var expand = ref.expand;
                  var isExpanded = ref.isExpanded;
return [_c('v-btn',{attrs:{"small":"","fab":"","color":"grey"},on:{"click":function($event){return expand(!isExpanded)}}},[_c('v-icon',[_vm._v("mdi-unfold-more-horizontal")])],1)]}},{key:"expanded-item",fn:function(ref){
                  var headers = ref.headers;
                  var item = ref.item;
return [_c('td',{staticClass:"expanded-row",attrs:{"colspan":headers.length}},[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v(" "+_vm._s(_vm.periodOfTimeForTitle(item.id))+" ")]),_c('v-spacer'),_c('v-btn-toggle',{attrs:{"group":"","mandatory":""},model:{value:(_vm.timeRange[_vm.zoneIdArray.indexOf(item.id)]),callback:function ($$v) {_vm.$set(_vm.timeRange, _vm.zoneIdArray.indexOf(item.id), $$v)},expression:"timeRange[zoneIdArray.indexOf(item.id)]"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-calendar-today -align-left")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.day))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-calendar-week -align-left")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.week))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-calendar-month -align-left")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.month))])])],1)],1),_c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},_vm._l((item.chartsData),function(measures){return _c('v-row',{key:measures.title,attrs:{"no-gutters":""}},[_c('v-col',[_c('v-card',{staticClass:"elevation-0"},[_c('div',[_c('WidgetDataLineChartWithGradient',{attrs:{"device-id":measures.id,"edge-values":measures.bounds,"title":measures.title,"time-range-index":_vm.timeRange[_vm.zoneIdArray.indexOf(item.id)],"unit":measures.unit,"icon":measures.icon,"icon-color":measures.iconColor,"name":measures.name,"min-y":measures.minY,"max-y":measures.maxY}})],1)])],1)],1)}),1)],1)]}}],null,true)},[_vm._v(" // eslint-disable-next-line ")]):_vm._e()],1)])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }