<template>
  <div class="fill-height">
    <ChartDoughnut class="chart-container" :chart-data="chartData" :options="chartOptions" />
  </div>
</template>

<script>

import ChartDoughnut from '@/components/Chart/Doughnut';
import { CleaningStatus } from '@/model/CleaningStatus';
import { theme } from '@/configuration';
import { lightenColor } from '@/helpers/lightenColor';
import { getStatus } from '../../router/helpers';

/**
 * @TODO needs to be rethinked then redesigned then refactored
 * Note that this component is used in two context: DrillDown and Dashboard
 */
export default {
  name: 'WidgetCleaningStatusChart',
  components: {
    ChartDoughnut,
  },
  props: {
    status: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: null,
    },
    zonesChart: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      referenceDate: new Date(), // fetch from filters
      cleaningStatusValuesSort: [],
      backgroundColorDefault: [
        theme.colors.cleaningStatusColor[CleaningStatus.CLEANED_NONE],
        theme.colors.cleaningStatusColor[CleaningStatus.CLEANED_FULL],
        theme.colors.cleaningStatusColor[CleaningStatus.CLEANED_NOT_REQUIRED],
      ],
      backgroundColorDisable: [],
    };
  },
  computed: {
    rawChartData() {
      const rawData = {};
      rawData[CleaningStatus.CLEANED_NONE] = 0;
      rawData[CleaningStatus.CLEANED_FULL] = 0;
      rawData[CleaningStatus.CLEANED_NOT_REQUIRED] = 0;
      this.zonesChart.forEach((zone) => {
        if (zone.zoneStatuses.length === 0) {
          rawData[CleaningStatus.CLEANED_NONE] += 1;
        } else {
          switch (zone.todayCleaningStatus) {
            case CleaningStatus.CLEANED_FULL:
              rawData[CleaningStatus.CLEANED_FULL] += 1;
              break;
            case CleaningStatus.CLEANED_NOT_REQUIRED:
              rawData[CleaningStatus.CLEANED_NOT_REQUIRED] += 1;
              break;
              
            default:
              rawData[CleaningStatus.CLEANED_NONE] += 1;
              break;
          }
        }
      });

      return rawData;
    },
    chartData() {
      const chartData = [
        this.rawChartData[CleaningStatus.CLEANED_NONE],
        this.rawChartData[CleaningStatus.CLEANED_FULL],
        this.rawChartData[CleaningStatus.CLEANED_NOT_REQUIRED],
      ];

      return {
        labels: [this.$t('form.label.notCleaned'), this.$t('form.label.cleaned'), this.$t('form.label.dayOff')],
        datasets: [
          {
            backgroundColor: this.backgroundArray,
            data: chartData,
          },
        ],
      };
    },
    chartOptions() {
      return {
        maintainAspectRatio: false,
        legend: {
          display: false,
        },
        onClick: (e, evt) => {
          if (evt[0]) {
            /* eslint-disable-next-line */
            this.$emit('chart:clicked', this.cleaningStatusValuesSort[evt[0]._index]);
          }
        },
      };
    },
    stateURL() {
      const getUrl = getStatus(this.$route.query);
      const url = getUrl.filter((element) => this.cleaningStatusValuesSort.includes(element));

      return url;
    },
    backgroundArray() {
      if (this.stateURL.length) {
        const tempColorsArray = [];
        this.cleaningStatusValuesSort.forEach((element, indexOfElement) => (
          this.stateURL.includes(element)
            ? tempColorsArray.push(this.backgroundColorDefault[indexOfElement])
            : tempColorsArray.push(this.backgroundColorDisable[indexOfElement])));

        return tempColorsArray;
      }

      return this.backgroundColorDefault;
    },
  },
  created() {
    if (typeof this.title === 'string') {
      this.chartOptions.title = {
        display: true,
        text: this.title,
      };
    }
    this.cleaningStatusValuesSort = [
      CleaningStatus.CLEANED_NONE,
      CleaningStatus.CLEANED_FULL,
      CleaningStatus.CLEANED_NOT_REQUIRED,
    ];
     
    this.backgroundColorDefault.forEach((item) => {
      this.backgroundColorDisable.push(lightenColor(item));
    });
  },

};

</script>

<style lang="scss" scoped>

  .chart-container {
    position: relative;
    height: 100%;
    width: 100%;
  }

</style>
