<template>
  <div class="fill-height">
    <ChartDoughnut class="chart-container" :chart-data="chartData" :options="chartOptions" />
  </div>
</template>

<script>
import ChartDoughnut from '@/components/Chart/Doughnut';
import { CleaningStatus } from '@/model/CleaningStatus';
import { theme } from '@/configuration';
import { lightenColor } from '@/helpers/lightenColor';
import { getStatus } from '../../router/helpers';

export default {
  name: 'WidgetCleaningStatusChart',
  components: {
    ChartDoughnut,
  },
  props: {
    locationsCharts: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      rawChartData: [],
      cleaningStatusValuesSort: [],
      backgroundColorDefault: [
        theme.colors.cleaningStatusColor[CleaningStatus.CLEANED_NONE],
        theme.colors.cleaningStatusColor[CleaningStatus.CLEANED_PARTLY],
        theme.colors.cleaningStatusColor[CleaningStatus.CLEANED_FULL],
      ],
      backgroundColorDisable: [],
    };
  },
  computed: {
    chartData() {
      let noneQty = 0;
      let partlyQty = 0;
      let fullQty = 0;

      this.locationsCharts.forEach((location) => {
        switch (location.status) {
          case CleaningStatus.CLEANED_FULL:
            fullQty += 1;
            break;
          case CleaningStatus.CLEANED_PARTLY:
            partlyQty += 1;
            break;
          default:
            noneQty += 1;
            break;
        }
      });
      
      return {
        labels: [this.$t('form.label.notCleaned'), this.$t('form.label.partlyCleaned'), this.$t('form.label.cleaned')],
        datasets: [{
          backgroundColor: this.backgroundArray,
          data: [
            noneQty,
            partlyQty,
            fullQty,
          ],
        }],
      };
    },
    chartOptions() {
      return {
        maintainAspectRatio: false,
        legend: {
          display: false,
        },
        onClick: (e, evt) => {
          if (evt[0]) {
            /* eslint-disable-next-line */
            this.$emit('chart:clicked', this.cleaningStatusValuesSort[evt[0]._index]);
          }
        },
      };
    },
    stateURL() {
      const getUrl = getStatus(this.$route.query);
      const url = getUrl.filter((element) => this.cleaningStatusValuesSort.includes(element));

      return url;
    },
    backgroundArray() {
      if (this.stateURL.length) {
        const tempColorsArray = [];
        this.cleaningStatusValuesSort.forEach((element, indexOfElement) => (
          this.stateURL.includes(element)
            ? tempColorsArray.push(this.backgroundColorDefault[indexOfElement])
            : tempColorsArray.push(this.backgroundColorDisable[indexOfElement])));

        return tempColorsArray;
      }

      return this.backgroundColorDefault;
    },

  },

  created() {
    this.cleaningStatusValuesSort = [
      CleaningStatus.CLEANED_NONE,
      CleaningStatus.CLEANED_PARTLY,
      CleaningStatus.CLEANED_FULL,
    ];

    this.backgroundColorDefault.forEach((item) => {
      this.backgroundColorDisable.push(lightenColor(item));
    });
  },

};

</script>

<style lang="scss" scoped>
  .chart-container {
    position: relative;
    height: 100%;
    width: 100%;
  }
</style>
