export default class Device {
  constructor(payload) {
    this.id = payload.device_id;
    this.name = payload.name;
    this.externalEquipment = payload.external_equipment;
    this.value = payload.sensor_id_value;
    this.subcategory = payload.sub_category_name;
    this.location = payload.n3location;
    this.created = payload.created;
    this.n3zones = payload.n3zones;
    this.n3robot_zones = payload.n3robot_zones;
    this.n3environment_zones = payload.n3environment_zones;
    this.singleCleanings = payload.singleCleanings;
    this.cleaning = payload.cleaning;
    this.lastMeasureDate = payload.lastMeasureDate;
    this.chartData = payload.chartData;
    this.locationId = this.locationIdentifier;
    this.zoneName = this.nameOfZone;
    this.partnerId = payload.partner_id;
    this.n3partner = payload.n3partner;
  }

  get nameOfZone() {
    if (this.n3zones?.length > 0) {
      return this.n3zones[0].name;
    }
    if (this.n3robot_zones?.length > 0) {
      return this.n3robot_zones[0].name;
    }
    if (this.n3environment_zones?.length > 0) {
      return this.n3environment_zones[0].name;
    }

    return null;
  }

  get client() {
    return this.location ? this.location.n3client : null;
  }

  get type() {
    throw Error('This Device has no type');
  }

  get locationIdentifier() {
    return this.location ? this.location.loc_id : null;
  }

  get identifier() {
    if (!this.category) {
      return null;
    }
    if (this.type === 'bleid' || this.type === 'beacon') {
      const decimalValue = parseInt(this.value, 10);

      return decimalValue.toString(16).toUpperCase();
    }

    return this.value;
  }
}
