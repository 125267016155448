<template>
  <div>
    <v-container fluid>
      <v-row>
        <v-col cols="7">
          <v-card class="fill-height widget">
            <div class="map-wrapper">
              <WidgetZoneMap :zones="zones" :gateways="gateways" :category="category" />
            </div>
          </v-card>
        </v-col>
        <v-col cols="5">
          <v-card class="widget">
            <div class="chart-wrapper">
              <WidgetZoneEnvironmentStatusChart
                :status="statuses"
                :title="$tc('dashboard.location.environmentStatus')"
                :zones-chart="zonesChart"
                @chart:clicked="onChartClicked"
              />
            </div>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-card class="fill-height widget ">
            <div class="wrapper">
              <v-data-table
                v-if="environmentData"
                :headers="headers"
                :items="items"
                :loading="loading"
                :single-expand="singleExpand"
                :expanded.sync="expanded"
                item-key="id"
                show-expand
                disable-pagination
                hide-default-footer
                class="elevation-1"
              >
                <!-- eslint-disable-next-line -->
                <template v-for="h in headers" #[`header.${h.value}`]="{header}">
                  <span :key="h.value">{{ h.text }} </span>
                  <v-tooltip
                    v-if="
                      h.value == 'averagedMeasures.dustLevel' ||
                        h.value == 'averagedMeasures.co2' ||
                        h.value == 'averagedMeasures.temperature' ||
                        h.value == 'averagedMeasures.humidity'
                    "
                    :key="h.text"
                    top
                    max-width="200"
                    open-delay="200"
                  >
                    <template #activator="{ on,attrs }">
                      <v-icon
                        color="primary"
                        dark
                        v-bind="attrs"
                        small
                        v-on="on"
                      >
                        mdi-help-circle-outline
                      </v-icon>
                    </template>
                    <span
                      v-if="h.value == 'averagedMeasures.dustLevel'"
                    >{{ $t("tooltip.environment.PM") }}
                    </span>
                    <span
                      v-if="h.value == 'averagedMeasures.co2'"
                    >{{ $t("tooltip.environment.PPM") }}
                    </span>
                    <span
                      v-if="h.value == 'averagedMeasures.temperature'"
                    >{{ $t("tooltip.environment.Temperature") }}
                    </span>
                    <span
                      v-if="h.value == 'averagedMeasures.humidity'"
                    >{{ $t("tooltip.environment.Humidity") }}
                    </span>
                  </v-tooltip>
                </template>
                <template #[`item.averagedMeasures.co2`]="{item}">
                  <span>
                    {{ item.averagedMeasures.co2 }}
                    <TrendIcon
                      v-if="item.averagedMeasures.co2"
                      :status="item.measureStatuses.co2"
                      :trend="item.trend.co2"
                    />
                  </span>
                </template>
                <template #[`item.averagedMeasures.dustLevel`]="{item}">
                  <span>
                    {{ item.averagedMeasures.dustLevel }}
                    <TrendIcon
                      v-if="item.averagedMeasures.dustLevel"
                      :status="item.measureStatuses.dustLevel"
                      :trend="item.trend.dustLevel"
                    />
                  </span>
                </template>
                <template #[`item.averagedMeasures.temperature`]="{item}">
                  <span>
                    {{ item.averagedMeasures.temperature }}
                    <TrendIcon
                      v-if="item.averagedMeasures.temperature"
                      :status="item.measureStatuses.temperature"
                      :trend="item.trend.temperature"
                    />
                  </span>
                </template>
                <template #[`item.averagedMeasures.humidity`]="{item}">
                  <span>
                    {{ item.averagedMeasures.humidity }}
                    <TrendIcon
                      v-if="item.averagedMeasures.humidity"
                      :status="item.measureStatuses.humidity"
                      :trend="item.trend.humidity"
                    />
                  </span>
                </template>
                <template #[`item.lastMeasureDate`]="{item}">
                  <span>
                    {{ item.lastMeasureDate }}
                  </span>
                </template>
                <template #[`item.zoneStatuses`]="{item}">
                  <BadgeEnvironmentStatus :status="item.todayEnvironmentStatus" />
                </template>
                <template #[`item.data-table-expand`]="{expand, isExpanded }">
                  <v-btn small fab color="grey" @click="expand(!isExpanded)">
                    <v-icon>mdi-unfold-more-horizontal</v-icon>
                  </v-btn>
                </template>
                // eslint-disable-next-line
                <template #expanded-item="{ headers, item }">
                  <td :colspan="headers.length" class="expanded-row">
                    <v-toolbar flat>
                      <v-toolbar-title>
                        {{ periodOfTimeForTitle(item.id) }}
                      </v-toolbar-title>
                      <v-spacer />
                      <v-btn-toggle
                        v-model="timeRange[zoneIdArray.indexOf(item.id)]"
                        group
                        mandatory
                      >
                        <v-tooltip top>
                          <template #activator="{ on, attrs }">
                            <v-btn v-bind="attrs" v-on="on">
                              <v-icon>mdi-calendar-today -align-left</v-icon>
                            </v-btn>
                          </template>
                          <span>{{ day }}</span>
                        </v-tooltip>
                        <v-tooltip top>
                          <template #activator="{ on, attrs }">
                            <v-btn v-bind="attrs" v-on="on">
                              <v-icon>mdi-calendar-week -align-left</v-icon>
                            </v-btn>
                          </template>
                          <span>{{ week }}</span>
                        </v-tooltip>
                        <v-tooltip top>
                          <template #activator="{ on, attrs }">
                            <v-btn v-bind="attrs" v-on="on">
                              <v-icon>mdi-calendar-month -align-left</v-icon>
                            </v-btn>
                          </template>
                          <span>{{ month }}</span>
                        </v-tooltip>
                      </v-btn-toggle>
                    </v-toolbar>
                    <v-container class="pa-0" fluid>
                      <v-row v-for="measures in item.chartsData" :key="measures.title" no-gutters>
                        <v-col>
                          <v-card class="elevation-0">
                            <div>
                              <WidgetDataLineChartWithGradient
                                :device-id="measures.id"
                                :edge-values="measures.bounds"
                                :title="measures.title"
                                :time-range-index="timeRange[zoneIdArray.indexOf(item.id)]"
                                :unit="measures.unit"
                                :icon="measures.icon"
                                :icon-color="measures.iconColor"
                                :name="measures.name"
                                :min-y="measures.minY"
                                :max-y="measures.maxY"
                              />
                            </div>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-container>
                  </td>
                </template>
              </v-data-table>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import {
  fetchEnvironmentZonesCoordinatesAndData,
  fetchEnvironmentGeneralData,
} from '@/data/locations';
import BadgeEnvironmentStatus from '@/components/Badge/EnvironmentStatus';
import TrendIcon from '@/components/Badge/TrendIcon';
import WidgetZoneMap from '@/components/Widget/ZoneMap';
import WidgetZoneEnvironmentStatusChart from '@/components/Widget/ZoneEnvironmentStatusChart';
import WidgetDataLineChartWithGradient from '@/components/Widget/DataLineChartWithGradient';
import i18n from '@/plugin/i18n';
import { getPartner } from '@/router/helpers';

export default {
  name: 'LocationEnvironment',
  components: {
    BadgeEnvironmentStatus,
    TrendIcon,
    WidgetZoneMap,
    WidgetZoneEnvironmentStatusChart,
    WidgetDataLineChartWithGradient,
  },
  props: {
    locationId: {
      type: Number,
      required: true,
    },
    statuses: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      loading: true,
      singleExpand: false,
      expanded: [],
      environmentData: [],
      environmentCoordinates: [],
      headers: [
        { text: this.$t('dashboard.table.name'), value: 'name' },
        { text: this.$t('dashboard.table.pm'), value: 'averagedMeasures.dustLevel' },
        { text: this.$t('dashboard.table.ppm'), value: 'averagedMeasures.co2' },
        { text: this.$t('dashboard.table.temperature'), value: 'averagedMeasures.temperature' },
        { text: this.$t('dashboard.table.humidity'), value: 'averagedMeasures.humidity' },
        { text: this.$t('dashboard.table.lastMeasure'), value: 'lastMeasureDate' },
        { text: this.$t('dashboard.table.status'), value: 'zoneStatuses' },
        { text: '', value: 'data-table-expand' },
      ],
      category: 'environments',
      generalZoneData: [],
      timeRange: [],
      zoneIdArray: [],
      day: i18n.t('dashboard.day'),
      week: i18n.t('dashboard.week'),
      month: i18n.t('dashboard.month'),
      partnerId: '',
    };
  },
  computed: {
    gateways() {
      if (!this.generalZoneData) {
        return [];
      }
      if (this.partnerId) {
        return this.generalZoneData.gateways.filter((gateway) => gateway.partnerId);
      }

      return this.generalZoneData.gateways;
    },
    items() {
      return !this.loading ? this.zones : [];
    },
    zones() {
      if (!this.environmentData.length) {
        return [];
      }
      if (!this.statuses.length) {
        if (this.partnerId) {
          return this.environmentData.filter((zone) => zone.partnerId === this.partnerId);
        }

        return this.environmentData;
      }

      return this.environmentData.filter((zone) => {
        const status = zone.todayEnvironmentStatus;

        return this.statuses.indexOf(status) >= 0;
      });
    },
    zonesChart() {
      if (!this.environmentData.length) {
        return [];
      }
      if (this.partnerId) {
        return this.environmentData.filter((zone) => zone.partnerId === this.partnerId);
      }

      return this.environmentData;
    },
  },
  watch: {
    locationId() {
      this.loadData(this.locationId);
    },
    $route(to) {
      this.partnerId = getPartner(to.query);
    },
  },
  beforeMount() {
    this.partnerId = getPartner(this.$route.query);
  },
  created() {
    this.loadData(this.locationId);
  },
  methods: {
    async loadData(locationId) {
      this.loading = true;
      try {
        const promises = [];
        this.generalZoneData = await fetchEnvironmentZonesCoordinatesAndData(locationId);
        this.environmentData = this.generalZoneData.zone;
        this.environmentData.forEach((zone) => {
          promises.push(fetchEnvironmentGeneralData(zone.device.id));
          this.zoneIdArray.push(zone.id);
        });
        Promise.all(promises).then((devices) => {
          devices.forEach((device) => {
            const zone = this.environmentData.find((e) => e.device.id === device.device_id);
            if (zone) {
              zone.setDevice(device);
            }
          });
          this.loading = false;
        });
        this.timeRange = Array(this.zoneIdArray.length).fill(0);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
    },
    periodOfTimeForTitle(id) {
      switch (this.timeRange[this.zoneIdArray.indexOf(id)]) {
        case 0:
          return i18n.t('dashboard.table.day');
        case 1:
          return i18n.t('dashboard.table.week');
        case 2:
          return i18n.t('dashboard.table.month');
        default:
          return '';
      }
    },
    onChartClicked(enviromentstatus) {
      this.$emit('status:selected', enviromentstatus);
    },
  },
};
</script>

<style lang="scss" scoped>
.chart-wrapper {
  height: 520px;
  padding: 16px;
}

.wrapper {
  padding: 16px;
}

.map-wrapper {
  //  height: calc(110% - 1px);
  height: 520px;
  padding: 16px;
}

$headerHeight: 48px;
$headerBorderHeight: 1px;

.widget {
  &__header {
    height: $headerHeight;

    &:after {
      content: "";
      position: absolute;
      bottom: -1px;
      left: 16px;
      right: 16px;
      background-color: rgba(0, 42, 76, 0.1);
      height: 1px;
      transition: left 0.3s ease, right 0.3s ease;
    }
  }

  &__actions {
    &:before {
      display: none;
    }
  }

  &__subtitle {
    font-size: 13px;
    line-height: 30px;
    font-weight: bold;
  }

  &__content {
    padding: 16px;

    &--scrollable {
      position: relative;
      height: calc(100% - #{$headerHeight} - #{$headerBorderHeight});
      margin: $headerBorderHeight auto auto auto;
    }
  }
}

.widget-loader {
  position: fixed;
  top: 33%;
  left: 50%;
}

.data-wrapper {
  &.loading {
    opacity: 0.5;
  }
}

.expanded-row {
  padding: 0px;
}
</style>
