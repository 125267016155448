import CleaningZone from '@/model/zones/CleaningZone';
import { CategoriesNames } from '@/model/CategoriesObjects';

export class ZoneZone extends CleaningZone {
  constructor(payload) {
    super(payload);
    this.zoneSize = payload.room_size;
    this.category = CategoriesNames.N3Zone;
  }
}

export class RobotZone extends CleaningZone {
  constructor(payload) {
    super(payload);
    this.floorId = payload.floor_id;
    this.category = CategoriesNames.N3Robot;
  }
}
