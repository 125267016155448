var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"fill-height"},[_c('ManagementBar',{attrs:{"title":_vm.$tc('common.zone', 2).capitalize(),"listsize":_vm.zones.length}},[_c('v-text-field',{attrs:{"prepend-inner-icon":"mdi-magnify","label":((_vm.$t('entity.find', { entity: _vm.$tc('common.zone', 1) })) + "...").capitalize(),"single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-select',{staticClass:"v-select",attrs:{"flat":"","solo":"","loading":_vm.loading,"items":_vm.categoriesArray,"item-text":"category_name","item-value":"category_id","label":"Category","hide-no-data":""},model:{value:(_vm.category),callback:function ($$v) {_vm.category=$$v},expression:"category"}}),(_vm.isSuperAdmin)?_c('v-select',{staticClass:"v-select",attrs:{"loading":_vm.loading,"items":_vm.partners,"item-text":"name","item-value":"partner_id","flat":"","solo":"","label":_vm.$t('common.partner'),"clearable":""},model:{value:(_vm.selectedPartner),callback:function ($$v) {_vm.selectedPartner=$$v},expression:"selectedPartner"}}):_vm._e()],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.zones,"search":_vm.search,"loading":_vm.loading,"item-key":"key","footer-props":{
      itemsPerPageOptions:[20, 50, 100, -1]
    },"disable-pagination":false,"custom-sort":_vm.customSort,"custom-filter":_vm.customSearch,"fixed-header":""},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('v-skeleton-loader',{staticClass:"mx-auto",attrs:{"max-width":"80%","type":"table-row@3"}})]},proxy:true},{key:"item.client",fn:function(ref){
    var item = ref.item;
return [(item.client)?_c('v-chip',{attrs:{"color":"white"},on:{"click":function($event){return _vm.redirect('clients', item.client.name)}}},[_vm._v(" "+_vm._s(item.client.name)+" ")]):_vm._e()]}},{key:"item.location",fn:function(ref){
    var item = ref.item;
return [(item.location)?_c('v-chip',{attrs:{"color":"white"},on:{"click":function($event){return _vm.redirect('locations', item.location.loc_name)}}},[_vm._v(" "+_vm._s(item.location.loc_name)+" ")]):_vm._e()]}},{key:"item.device",fn:function(ref){
    var item = ref.item;
return [_c('v-chip',{attrs:{"color":"white"},on:{"click":function($event){return _vm.redirect('devices', item.device.name)}}},[_vm._v(" "+_vm._s(item.device.name)+" ")])]}},{key:"item.n3partner",fn:function(ref){
    var item = ref.item;
return [(item.n3partner)?_c('v-chip',{attrs:{"color":"white"},on:{"click":function($event){(_vm.isSuperAdmin) ? _vm.redirect('partners', item.n3partner.name) : function () {}}}},[_vm._v(" "+_vm._s(item.n3partner.name)+" ")]):_vm._e()]}},{key:"item.gpsLatitude",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.gpsLatitude)+", "+_vm._s(item.gpsLongitude)+" ")]}},{key:"item.created",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateWithTime")(_vm._f("gmtTime")(item.created)))+" ")]}},{key:"item.sendCleanPilot",fn:function(ref){
    var item = ref.item;
return [(item.sendCleanPilot)?_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v(" mdi-check-circle ")]):_vm._e()]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }