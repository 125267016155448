<template>
  <v-card>
    <v-container>
      <v-form
        ref="form"
        v-model="valid"
        novalidate="true"
        @submit.prevent="handleSubmit"
      >
        <v-card-title>
          <v-row>
            <v-col cols="11">
              <span class="headline">{{ headline.capitalize() }}</span>
            </v-col>
            <v-col cols="1" class="text-right">
              <span @click.stop="closeForm">X</span>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-autocomplete
            v-model="client"
            :items="clients"
            item-text="value"
            item-value="key"
            :label="$t('form.label.client')"
            single-line
            hide-no-data
            chips
            :rules="[rules.required]"
            @change="clearSelectedLocations"
          />
          <v-row justify="center" align="center">
            <v-col cols="11">
              <v-autocomplete
                v-model="selectedLocations"
                :disabled="!client"
                :items="filteredLocations"
                item-text="name"
                item-value="id"
                :label="$t('form.label.location')"
                multiple
                single-line
                hide-no-data
                chips
                :rules="[rules.required]"
              />
            </v-col>
            <v-col cols="1">
              <v-tooltip bottom>
                <template #activator="{ on,attrs }">
                  <v-btn
                    v-bind="attrs"
                    icon
                    outlined
                    :disabled="!client"
                    v-on="on"
                    @click="toogleSelectedLocations"
                  >
                    <v-icon v-if="iconAll" small>
                      mdi-check-all
                    </v-icon>
                    <v-icon v-else small>
                      mdi-close
                    </v-icon>
                  </v-btn>
                </template>
                <span v-if="iconAll">{{ $t('form.label.selectAll') }}</span>
                <span v-else>{{ $t('form.label.removeAll') }}</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="success"
            type="submit"
            :disabled="!valid"
            block
          >
            {{ submitLabel }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-container>
  </v-card>
</template>

<script>
import { required } from '@/validation/rules';

export default {
  name: 'ReportForm',
  props: {
    locations: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      headline: this.$t('report.title'),
      submitLabel: this.$t('report.generate'),
      valid: false,
      selectedLocations: '',
      client: '',
      rules: {
        required,
      },
    };
  },
  computed: {
    clients() {
      const filteredClients = new Map();
      this.locations.forEach((location) => {
        if (location.client) {
          filteredClients.set(location.client.id, location.client.name);
        }
      });

      return Array.from(filteredClients, ([key, value]) => ({ key, value }));
    },
    clientToSend() {
      return this.clients.filter((e) => e.key === this.client);
    },
    filteredLocations() {
      if (!this.locations.length) return [];

      return this.locations.filter((e) => e.client?.id === this.client);
    },
    iconAll() {
      return !(this.selectedLocations.length === this.filteredLocations.length
        && this.selectedLocations.length !== 0);
    },
  },
  methods: {
    handleSubmit() {
      this.$emit('submit', { locations: this.selectedLocations, client: this.clientToSend[0].value });
      this.$refs.form.reset();
    },
    closeForm() {
      this.$emit('close');
    },
    toogleSelectedLocations() {
      if (this.iconAll) {
        this.selectedLocations = [];
        this.filteredLocations.forEach((e) => {
          this.selectedLocations.push(e.id);
        });
      } else {
        this.selectedLocations = [];
      }
    },
    clearSelectedLocations() {
      this.selectedLocations = [];
    },
  },
};
</script>

<style lang="scss" scoped></style>
