<template>
  <div>
    <v-container fluid>
      <v-row>
        <v-col cols="9">
          <v-card class="fill-height widget ">
            <div class="wrapper">
              <v-data-table
                v-if="locationData"
                v-model="selectedZones"
                :headers="headers"
                :items="zones"
                :loading="loading"
                item-key="id"
                :expanded.sync="expanded"
                disable-pagination
                hide-default-footer
                show-select
              >
                <!-- eslint-disable-next-line -->
                <template v-for="h in headers" #[`header.${h.value}`]="{header}">
                  <span :key="h.value">{{ h.text }} </span>
                  <v-tooltip
                    v-if="h.value == 'cleaningTimesSumForToday' ||
                      h.value == 'expectedCleaningTime' ||
                      h.value == 'cleaningTimeDiscrepancy' ||
                      h.value == 'todayCleaningDates' ||
                      h.value == 'cleaningStatus'"
                    :key="h.text"
                    top
                    max-width="200"
                    open-delay="200"
                  >
                    <template #activator="{ on,attrs }">
                      <v-icon
                        color="primary"
                        dark
                        v-bind="attrs"
                        small
                        v-on="on"
                      >
                        mdi-help-circle-outline
                      </v-icon>
                    </template>
                    <span v-if="h.value == 'cleaningTimesSumForToday'">{{ $t('tooltip.zones.CleaningTime') }} </span>
                    <span v-if="h.value == 'expectedCleaningTime'">{{ $t('tooltip.zones.ExpectedTime') }} </span>
                    <span v-if="h.value == 'cleaningTimeDiscrepancy'">{{ $t('tooltip.zones.Discrepancy') }} </span>
                    <span v-if="h.value == 'todayCleaningDates'">{{ $t('tooltip.zones.FinishTime') }} </span>
                    <span v-if="h.value == 'cleaningStatus'">{{ $t('tooltip.zones.Status') }} </span>
                  </v-tooltip>
                </template>
                <template #[`header.data-table-select`]>
                  <v-menu
                    ref="menu"
                    :close-on-content-click="false"
                    :return-value.sync="date"
                    offset-y
                    offset-x
                    right
                    :disabled="!checkIfZoneSelected"
                  >
                    <template #activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        small
                        color="white"
                        depressed
                        v-on="on"
                        @click="displayMessage"
                      >
                        <v-icon>mdi-file-export</v-icon>
                        {{ exportText }}
                      </v-btn>
                    </template>
                    <v-date-picker
                      v-model="date"
                      no-title
                      scrollable
                      range
                      :locale="language"
                      color="grey"
                      first-day-of-week="1"
                      :max="todayDate"
                    >
                      <v-spacer />
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.menu.save(date)"
                        @click.stop="exportToFile"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </template>
                <template #[`item.todayCleaningDates`]="{item}">
                  <span
                    v-for="(cleaningDate, index) in item.todayCleaningDates"
                    :key="cleaningDate"
                  >
                    <span v-if="index < 5">
                      {{ cleaningDate | formatDate('HH:mm') }},
                    </span>
                  </span>
                  <v-btn v-if="item.todayCleaningDates[5]" icon @click="expandRow(item)">
                    <v-icon v-if="expanded.includes(item)" size="16">
                      mdi-chevron-up
                    </v-icon>
                    <span v-else class="caption">
                      {{ item.todayCleaningDates.length - 5 }}+
                    </span>
                  </v-btn>
                </template>
                <template #expanded-item="{ item }">
                  <td />
                  <td :colspan="headers.length - 1">
                    <p class="mt-4 mb-4">
                      <span v-for="cleaningDate in item.todayCleaningDates" :key="cleaningDate">
                        {{ cleaningDate | formatDate('HH:mm') }},
                      </span>
                    </p>
                  </td>
                </template>
                <template #[`item.expectedCleaningTime`]="{item}">
                  <span v-if="item.cleaningStatus===cleaningStatusNotRequired">
                    off
                  </span>
                  <span v-else>
                    {{ item.expectedCleaningTime }}
                  </span>
                </template>
                <template #[`item.cleaningTimeDiscrepancy`]="{item}">
                  <span v-if="item.cleaningStatus===cleaningStatusNotRequired">
                    0
                  </span>
                  <span v-else>
                    {{ item.cleaningTimeDiscrepancy }}
                  </span>
                </template>
                <template #[`item.cleaningStatus`]="{item}">
                  <BadgeCleaningStatus :cleaning-status="item.todayCleaningStatus" />
                </template>
              </v-data-table>
            </div>
          </v-card>
        </v-col>
        <v-col cols="3" class="">
          <v-card class="fill-height widget ">
            <div class="chart-wrapper">
              <WidgetZoneCleaningStatusChart
                :title="$tc('dashboard.location.todayStatus')"
                :zones-chart="zonesChart"
                @chart:clicked="onChartClicked"
              />
            </div>
          </v-card>
        </v-col>
        <v-col cols="12">
          <v-card class="fill-height widget">
            <div class="wrapper">
              <WidgetCleaningStatusWeekly
                :category="category"
                :location="location"
                :dates="dates"
              />
            </div>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="5" class="">
          <v-card class="fill-height widget ">
            <div class="wrapper">
              <WidgetZoneCleaningActivityChart
                :location="location"
                :status="cleaningStatuses"
                :title="$tc('dashboard.cleaningStatusChart')"
                :category="category"
              />
            </div>
          </v-card>
        </v-col>
        <v-col cols="7">
          <v-card class="fill-height widget">
            <WidgetZoneMap
              :zones="zones"
              :gateways="gateways"
              :category="category"
            />
          </v-card>
        </v-col>
      </v-row>
      <v-row />
    </v-container>
  </div>
</template>
<script>

import { findById } from '@/data/locations';
import { getZonesDataForExport, getRobotsDataForExport } from '@/data/zones';
import BadgeCleaningStatus from '@/components/Badge/CleaningStatus';
import WidgetCleaningStatusWeekly from '@/components/Widget/CleaningStatusWeekly';
import WidgetZoneCleaningActivityChart from '@/components/Widget/ZoneCleaningActivityChart';
import WidgetZoneCleaningStatusChart from '@/components/Widget/ZoneCleaningStatusChart';
import WidgetZoneMap from '@/components/Widget/ZoneMap';
import { CleaningStatus } from '@/model/CleaningStatus';
import {
  format, add, sub, eachDayOfInterval, getISOWeek,
} from 'date-fns';
import i18n from '@/plugin/i18n';
import { downloadCsvFile } from '@/helpers/csv';
import { EventBus } from '@/eventBus';
import { getPartner } from '@/router/helpers';

export default {
  name: 'LocationCleaning',
  components: {
    BadgeCleaningStatus,
    WidgetCleaningStatusWeekly,
    WidgetZoneCleaningStatusChart,
    WidgetZoneCleaningActivityChart,
    WidgetZoneMap,
  },
  props: {
    locationId: {
      type: Number,
      required: true,
    },
    category: {
      type: String,
      required: true,
    },
    cleaningStatuses: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      exportText: this.$t('dashboard.table.export'),
      language: i18n.locale,
      menu: false,
      date: [new Date().toISOString().substr(0, 10), new Date().toISOString().substr(0, 10)],
      selectedZones: [],
      cleaningStatusNotRequired: CleaningStatus.CLEANED_NOT_REQUIRED,
      loading: true,
      locationData: {},
      headers: [
        { text: this.$t('dashboard.table.name'), value: 'name' },
        { text: this.$t('dashboard.table.externalEquipment'), value: 'device.externalEquipment', align: (this.category === 'robots') ? 'center' : ' d-none' },
        { text: this.$t('dashboard.table.cleaningTimeSum'), value: 'cleaningTimesSumForToday' },
        { text: this.$t('dashboard.table.expectedCleaningTime'), value: 'expectedCleaningTime' },
        { text: this.$t('dashboard.table.cleaningTimeDiscrepancy'), value: 'cleaningTimeDiscrepancy' },
        { text: this.$t('dashboard.table.cleaningDates'), value: 'todayCleaningDates' },
        { text: this.$t('dashboard.table.status'), value: 'cleaningStatus' },
        { text: '', value: 'data-table-select', align: 'center' },
      ],
      expanded: [],
      partnerId: '',
    };
  },
  computed: {
    checkIfZoneSelected() {
      return (this.selectedZones.length !== 0);
    },
    dates() {
      const today = new Date();

      return eachDayOfInterval({
        start: sub(today, { days: 6 }),
        end: today,
      });
    },
    zones() {
      if (!this.locationData.zone) {
        return [];
      }
      if (!this.cleaningStatuses.length) {
        if (this.partnerId) {
          return this.locationData.zone.filter((zone) => zone.partnerId === this.partnerId);
        }
 
        return this.locationData.zone;
      }

      return this.locationData.zone.filter((zone) => {
        const cleaningStatus = zone.getCleaningStatus(new Date());

        return this.cleaningStatuses.indexOf(cleaningStatus) >= 0;
      });
    },
    zonesChart() {
      if (!this.locationData) {
        return [];
      }
      if (this.partnerId && this.locationData.zone) {
        return this.locationData.zone.filter((zone) => zone.partnerId === this.partnerId);
      }

      return this.locationData.zone ? this.locationData.zone : [];
    },
    gateways() {
      if (!Object.keys(this.locationData).length) {
        return [];
      }
      if (this.partnerId) {
        return this.locationData.gateways.filter((gateway) => gateway.partnerId === this.partnerId);
      }

      return this.locationData.gateways;
    },
    location() {
      if (!Object.keys(this.locationData).length) {
        return {};
      }

      this.locationData.filterZone(this.partnerId);

      return this.locationData;
    },
    todayDate() {
      return format(new Date(), 'yyyy-MM-dd');
    },

  },
  watch: {
    locationId() {
      this.loadData(this.locationId);
    },
    $route(to) {
      this.partnerId = getPartner(to.query);
      this.selectedZones = [];
    },
  },
  beforeMount() {
    this.partnerId = getPartner(this.$route.query);
  },
  created() {
    this.loadData(this.locationId);
  },
  methods: {
    async loadData(locationId) {
      this.loading = true;
      this.selectedZones = [];
      try {
        const endDate = add(this.dates[this.dates.length - 1], { days: 1 });
        endDate.setHours(2, 1, 0);
        const startDate = this.dates[0];
        const interval = { start: new Date(startDate), end: new Date(endDate) };
        this.locationData = await findById(locationId, this.category === 'zones', interval.start, interval.end);
        this.loading = false;
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
    },
    expandRow(value) {
      if (this.expanded.includes(value)) {
        this.expanded = [];
      } else {
        this.expanded = [value];
      }
    },
    async exportToFile() {
      const formatedDate = this.date.map((x) => new Date(x));
      const maxDate = Math.max.apply(null, formatedDate);
      const minDate = Math.min.apply(null, formatedDate);
      const intervalMaxDate = new Date(maxDate);
      intervalMaxDate.setDate(intervalMaxDate.getDate() + 1);
      const interval = { start: new Date(minDate), end: new Date(intervalMaxDate) };
      const zoneData = await this.loadDeviceData(interval);
      const headers = [
        this.$t('dashboard.csv.name'),
        this.$t('dashboard.csv.expectedTime'),
        this.$t('dashboard.csv.cleanTime'),
        this.$t('dashboard.csv.startDate'),
        this.$t('dashboard.csv.startTime'),
        this.$t('dashboard.csv.endTime'),
        this.$t('dashboard.csv.week'),
      ];
      if (this.category === 'robots') {
        headers.splice(1, 0, this.$t('dashboard.csv.externalEquipment'));
      }
      const dataPrepered = this.prepareDataToExport(zoneData);
      const title = `${this.locationData.name.trim()}_${this.category}_${
        this.$t('dashboard.csv.previewFrom')}_${format(minDate, 'yyyy-MM-dd')}_${
        this.$t('dashboard.csv.previewTo')}_${format(maxDate, 'yyyy-MM-dd')}`
        .replaceAll(' ', '_');
      downloadCsvFile(title, dataPrepered, headers);
    },
    async loadDeviceData(interval) {
      const ids = [];
      this.selectedZones.forEach((zone) => {
        ids.push(zone.id);
      });
      // eslint-disable-next-line
      return (this.category === 'zones') ? await getZonesDataForExport(ids, interval) : await getRobotsDataForExport(ids, interval);
    },
    prepareDataToExport(zoneData) {
      const dataPrepered = [];
      zoneData.forEach((item) => {
        const name = item.name === null ? '' : item.name;
        const expectedCleaningTime = item.expected_cleaning_time === null ? '' : item.expected_cleaning_time;
        const itemLength = item.n3device.data.length;
        const externalEquipment = item.n3device.external_equipment === null ? '' : item.n3device.external_equipment;
        let totalDayCleaningTime = 0;
        let totalCleaningTime = 0;
        if (itemLength === 0) {
          const row = [name, expectedCleaningTime, '', '', '', '', ''];
          if (this.category === 'robots') {
            row.splice(1, 0, '');
          }
          dataPrepered.push(row);
        }
        item.n3device.data.forEach((element, index) => {
          const time = element.dtime === null ? '' : Date.parse(element.dtime);
          const startTime = time === '' ? '' : time - (element.clean_time * 60 * 1000);
          if (index < itemLength && item.n3device.data[index].dtime != null) {
            const cleanTime = element.clean_time === null ? 0 : element.clean_time;
            totalCleaningTime += element.clean_time;
            const row = [
              name,
              '',
              cleanTime,
              this.formatTime(time, 'yyyy-MM-dd'),
              this.formatTime(startTime, 'HH:mm'),
              this.formatTime(time, 'HH:mm'),
              this.getWeekNumber(time)];
            if (item.n3device.data[index].dtime.substr(0, 10) !== item.n3device.data[index - 1]?.dtime?.substr(0, 10)
            && item.n3device.data[index].dtime.substr(0, 10) !== item.n3device.data[index + 1]?.dtime?.substr(0, 10)) {
              row[1] = expectedCleaningTime;
            }
            
            if (this.category === 'robots') {
              row.splice(1, 0, externalEquipment);
            }

            if (item.n3device.data[index].dtime.substr(0, 10) === item.n3device.data[index - 1]?.dtime?.substr(0, 10)) {
              totalDayCleaningTime += element.clean_time;
            } else {
              totalDayCleaningTime = element.clean_time;
            }
            dataPrepered.push(row);
            if (item.n3device.data[index].dtime.substr(0, 10) !== item.n3device.data[index + 1]?.dtime?.substr(0, 10)
            && item.n3device.data[index].dtime.substr(0, 10) === item.n3device.data[index - 1]?.dtime?.substr(0, 10)) {
              const totalRow = [
                name + this.$t('dashboard.csv.total'),
                expectedCleaningTime,
                totalDayCleaningTime,
                this.formatTime(time, 'yyyy-MM-dd'),
                '',
                '',
                ''];
              if (this.category === 'robots') {
                totalRow.splice(1, 0, externalEquipment);
              }
              dataPrepered.push(totalRow);
              totalDayCleaningTime = 0;
            }
          }
          if (index === itemLength - 1) {
            const totalOfPeriodRow = [this.$t('dashboard.csv.totalOfPeriod'), '', totalCleaningTime, '', '', '', ''];
            if (this.category === 'robots') {
              totalOfPeriodRow.splice(1, 0, '');
            }
            dataPrepered.push(totalOfPeriodRow);
          }
        });
      });

      return dataPrepered;
    },
    formatTime(time, timeType) {
      return time === '' ? '' : format(time, timeType);
    },
    getWeekNumber(time) {
      return time === '' ? '' : getISOWeek(time);
    },
    displayMessage() {
      if (!this.checkIfZoneSelected) {
        EventBus.$emit('generalErrorOccurred', {
          message: this.$t('dashboard.csv.message'),
        });
      }
    },
    onChartClicked(cleaningstatus) {
      this.$emit('status:selected', cleaningstatus);
    },
  },
};

</script>

<style lang="scss" scoped>
.chart-wrapper {
  height: 260px;
  padding: 16px;
}

.wrapper {
  padding: 16px;
}

.map-wrapper {
  //  height: calc(110% - 1px);
  height: 100%;
  padding: 16px;
}

$headerHeight: 48px;
$headerBorderHeight: 1px;

.widget {
  &__header {
    height: $headerHeight;

    &:after {
      content: "";
      position: absolute;
      bottom: -1px;
      left: 16px;
      right: 16px;
      background-color: rgba(0, 42, 76, 0.1);
      height: 1px;
      transition: left 0.3s ease, right 0.3s ease;
    }
  }

  &__actions {
    &:before {
      display: none;
    }
  }

  &__subtitle {
    font-size: 13px;
    line-height: 30px;
    font-weight: bold;
  }

  &__content {
    padding: 16px;

    &--scrollable {
      position: relative;
      height: calc(100% - #{$headerHeight} - #{$headerBorderHeight});
      margin: $headerBorderHeight auto auto auto;
    }
  }
}

.widget-loader {
  position: fixed;
  top: 33%;
  left: 50%;
}

.data-wrapper {
  &.loading {
    opacity: 0.5;
  }
}

</style>
