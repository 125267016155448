export function getKeysArrayByValue(object, value) {
  const keys = [];
  Object.keys(object).forEach((key) => {
    if (object[key] === value) {
      keys.push(key);
    }
  });
  
  return keys;
}
