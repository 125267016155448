<template>
  <div class="fill-height">
    <ChartDoughnut class="chart-container" :chart-data="chartData" :options="chartOptions" />
  </div>
</template>

<script>

import ChartDoughnut from '@/components/Chart/Doughnut';
import { EnvironmentStatus } from '@/model/EnvironmentStatus';
import { environmentTheme } from '@/configuration';
import { lightenColor } from '@/helpers/lightenColor';
import { getStatus } from '../../router/helpers';

/**
 * @TODO needs to be rethinked then redesigned then refactored
 * Note that this component is used in two context: DrillDown and Dashboard
 */
export default {
  name: 'WidgetZoneEnvironmentStatusChart',
  components: {
    ChartDoughnut,
  },
  props: {
    status: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: null,
    },
    zonesChart: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      referenceDate: new Date(), // fetch from filters
      backgroundColorDefault: [
        environmentTheme.colors.environmentStatusColor[EnvironmentStatus.BAD_CONDITIONS],
        environmentTheme.colors.environmentStatusColor[EnvironmentStatus.GOOD_CONDITIONS],
      ],
      backgroundColorDisable: [],
      environmentStatusValuesSort: [],
    };
  },
  computed: {
    rawChartData() {
      const rawData = {};
      rawData[EnvironmentStatus.BAD_CONDITIONS] = 0;
      rawData[EnvironmentStatus.GOOD_CONDITIONS] = 0;
      this.zonesChart.forEach((zone) => {
        if (zone.zoneStatuses.length === 0) {
          rawData[EnvironmentStatus.BAD_CONDITIONS] += 1;
        } else {
          switch (zone.zoneStatuses[0].measureStatus) {
            case EnvironmentStatus.GOOD_CONDITIONS:
              rawData[EnvironmentStatus.GOOD_CONDITIONS] += 1;
              break;
            default:
              rawData[EnvironmentStatus.BAD_CONDITIONS] += 1;
              break;
          }
        }
      });

      return rawData;
    },
    chartData() {
      const chartData = [
        this.rawChartData[EnvironmentStatus.BAD_CONDITIONS],
        this.rawChartData[EnvironmentStatus.GOOD_CONDITIONS],
      ];
   
      return {
        labels: [this.$t('form.label.badConditions'), this.$t('form.label.goodConditions')],
        datasets: [
          {
            backgroundColor: this.backgroundArray,
            data: chartData,
          },
        ],
      };
    },
    chartOptions() {
      return {
        maintainAspectRatio: false,
        legend: {
          display: false,
        },
        onClick: (e, evt) => {
          if (evt[0]) {
            /* eslint-disable-next-line */
            this.$emit('chart:clicked', this.environmentStatusValuesSort[evt[0]._index]);
          }
        },
      };
    },
    stateURL() {
      const getUrl = getStatus(this.$route.query);
      const url = getUrl.filter((element) => this.environmentStatusValuesSort.includes(element));

      return url;
    },
    backgroundArray() {
      if (this.stateURL.length) {
        const tempColorsArray = [];
        this.environmentStatusValuesSort.forEach((element, indexOfElement) => (
          this.stateURL.includes(element)
            ? tempColorsArray.push(this.backgroundColorDefault[indexOfElement])
            : tempColorsArray.push(this.backgroundColorDisable[indexOfElement])));

        return tempColorsArray;
      }

      return this.backgroundColorDefault;
    },
  },

  created() {
    if (typeof this.title === 'string') {
      this.chartOptions.title = {
        display: true,
        text: this.title,
      };
    }
    this.environmentStatusValuesSort = [
      EnvironmentStatus.BAD_CONDITIONS,
      EnvironmentStatus.GOOD_CONDITIONS,
    ];
    this.backgroundColorDefault.forEach((item) => {
      this.backgroundColorDisable.push(lightenColor(item));
    });
  },

};

</script>

<style lang="scss" scoped>

  .chart-container {
    position: relative;
    height: 100%;
    width: 100%;
  }

</style>
