import MarkerClusterer from '@googlemaps/markerclustererplus';

export default {
  name: 'GoogleMarkerClusterer',
  props: {
    google: {
      type: Object,
      required: true,
    },
    map: {
      type: Object,
      required: true,
    },
  },
  render(h) {
    if (Boolean(this.google) && Boolean(this.map)) {
      return h(
        'span',
        this.$scopedSlots.default({
          clusterer: this.markerClusterer,
        }),
      );
    }

    return '';
  },
  data() {
    return {
      markerClusterer: null,
    };
  },
  beforeMount() {
    this.markerClusterer = new MarkerClusterer(this.map, [], {
      imagePath: '/marker/cluster/m',
    });
  },
  destroyed() {
    if (this.markerClusterer) {
      this.markerClusterer.clearMarkers();
    }
  },
  beforeUpdate() {
    const repaintListener = this.google.maps.event.addListener(this.map, 'idle', () => {
      if (this.markerClusterer && this.markerClusterer.getMarkers().length > 0) {
        this.markerClusterer.repaint();
      }
      this.google.maps.event.removeListener(repaintListener);
    });
  },
  updated() {
    // This is necessary because when all markers are removed map is not modified
    if (this.markerClusterer.getMarkers().length === 0) {
      this.markerClusterer.repaint();
    }
  },
};
