export const CategoriesNames = {
  N3Zone: 'N3Zones',
  N3Robot: 'N3Assets',
  N3Environment: 'N3Environment',
  N3Gateway: 'N3Gateway',
};

export const SubcategoriesNames = {
  N3Evo: 'N3Evo',
  N3Robot: 'N3Robot',
  Beacon: 'Beacon',
  N3RobotB: 'N3RobotB',
  N3IAQ: 'N3IAQ',
  N3Connector: 'N3Connector',
};

export const SubcategoriesCategories = {
  N3Evo: 'N3Zones',
  N3Robot: 'N3Assets',
  Beacon: 'N3Zones',
  N3RobotB: 'N3Assets',
  N3IAQ: 'N3Environment',
  N3Connector: 'N3Gateway',
};

export const SensorsTypes = {
  IMEI: [SubcategoriesNames.N3Evo,
    SubcategoriesNames.N3Robot,
    SubcategoriesNames.N3IAQ,
    SubcategoriesNames.N3Connector],
  BEACONID: [SubcategoriesNames.Beacon],
  BLEID: [SubcategoriesNames.N3RobotB],
};

export const RobotSubcategories = {
  N3Robot: 'N3Robot',
  N3RobotB: 'N3RobotB',
};

export const ZoneSubcategories = {
  N3Evo: 'N3Evo',
  Beacon: 'Beacon',
};

export const EnvironmentSubcategories = {
  N3IAQ: 'N3IAQ',
};

export const GatewaySubcategories = {
  N3Connector: 'N3Connector',
};

export const SubcategoriesTypes = {
  N3Zones: Object.values(ZoneSubcategories),
  N3Assets: Object.values(RobotSubcategories),
  N3Environment: Object.values(EnvironmentSubcategories),
  N3Gateway: Object.values(GatewaySubcategories),
};

export const CategoryTypeName = {
  N3Zone: 'n3zone_zone',
  N3Robot: 'n3robot_zone',
  N3Environment: 'n3environment_zone',
};
