<template>
  <component
    :is="component.component"
    v-bind="component.props"
    @status:selected="handleFiltersSelect"
  />
</template>

<script>

import LocationCleaning from '@/components/DrillDown/LocationCleaning';
import LocationEnvironment from '@/components/DrillDown/LocationEnvironment';
import { getStatus } from '@/router/helpers';
import { getLocationFromCategoryLocations, getZoneForLocation } from '@/data/locations';
import i18n from '@/plugin/i18n';
import { EventBus } from '@/eventBus';
import { CleaningStatus } from '@/model/CleaningStatus';

export default {
  name: 'DashboardDrillDown',
  components: {
    LocationCleaning,
    LocationEnvironment,
  },
  async beforeRouteEnter(to, from, next) {
    try {
      if (await getLocationFromCategoryLocations(to.params.category, to.params.locationId)) {
        if (await getZoneForLocation(to.params.category, to.params.locationId)) {
          next();
        } else {
          EventBus.$emit('generalErrorOccurred', {
            message: i18n.t('common.error.noZone'),
          });
        }
      } else {
        next({ path: `/${to.params.category}` });
      }
    } catch (error) {
      next({ path: `/${to.params.category}` });
    }
  },
  props: {
    category: {
      type: String,
      required: true,
    },
  },
  computed: {
    component() {
      if (this.category === 'environment') {
        return {
          component: LocationEnvironment,
          props: {
            locationId: parseInt(this.$route.params.locationId, 10),
            statuses: getStatus(this.$route.query),
          },
        };
      }

      return {
        component: LocationCleaning,
        props: {
          category: this.category,
          locationId: parseInt(this.$route.params.locationId, 10),
          cleaningStatuses: getStatus(this.$route.query),
        },
      };
    },
  },
  methods: {
    handleFiltersSelect(filters) {
      if (filters !== CleaningStatus.CLEANED_NOT_REQUIRED) {
        const { name, params, path } = this.$route;
        const url = getStatus(this.$route.query);
        let query = null;
        if (!url.includes(filters)) {
          url.unshift(filters);
          query = { status: url.join(',') };
          this.$router.push({ name, params, query });
        } else {
          const urlFiltered = url.filter((item) => item !== filters);
          query = { status: urlFiltered.join(',') };
          if (!urlFiltered.length) {
            this.$router.push({ path });
          } else {
            this.$router.push({ name, params, query });
          }
        }
      }
    },
  },
};

</script>
