<template>
  <GoogleMapLoader :api-key="apiKey" :bounds="bounds">
    <template #default="{ google, map }">
      <GoogleMarkerClusterer :google="google" :map="map">
        <template #default="{ clusterer }">
          <GoogleMapMarker
            v-for="marker in markers"
            :key="marker.id"
            :marker="marker"
            :google="google"
            :map="map"
            :marker-clusterer="clusterer"
            @location:selected="toLocationDetails"
          />
        </template>
      </GoogleMarkerClusterer>
    </template>
  </GoogleMapLoader>
</template>

<script>

import GoogleMapLoader from '@/components/Map/GoogleMapLoader';
import GoogleMapMarker from '@/components/Map/GoogleMapMarker.js';
import GoogleMarkerClusterer from '@/components/Map/GoogleMarkerClusterer.js';
import { GOOGLE_API_KEY } from '@/configuration';

export default {
  name: 'WidgetLocationMap',
  components: {
    GoogleMapLoader,
    GoogleMapMarker,
    GoogleMarkerClusterer,
  },
  props: {
    locations: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      apiKey: GOOGLE_API_KEY,
    };
  },
  computed: {
    markers() {
      // eslint-disable-next-line arrow-body-style
      return this.locations.map(({
        id, gpsLatitude, gpsLongitude, name,
      }) => ({ id, position: { lat: gpsLatitude, lng: gpsLongitude }, title: name }));
    },
    bounds() {
      return this.locations.map(({ gpsLatitude, gpsLongitude }) => ({ lat: gpsLatitude, lng: gpsLongitude }));
    },
  },
  methods: {
    toLocationDetails(location) {
      this.$emit('location:selected', location);
    },
  },
};

</script>
