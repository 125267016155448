import Device from '@/model/devices/Device';
import { CategoriesNames } from '@/model/CategoriesObjects';

export class N3Evo extends Device {
  constructor(payload) {
    super(payload);
    this.category = CategoriesNames.N3Zone;
    this.sensorIdentifier = this.identifier;
  }

  get type() {
    return 'imei';
  }
}
export class N3Robot extends Device {
  constructor(payload) {
    super(payload);
    this.category = CategoriesNames.N3Robot;
    this.sensorIdentifier = this.identifier;
  }

  get type() {
    return 'imei';
  }
}

export class N3IAQ extends Device {
  constructor(payload) {
    super(payload);
    this.category = CategoriesNames.N3Environment;
    this.sensorIdentifier = this.identifier;
  }

  get type() {
    return 'imei';
  }
}

export class N3Connector extends Device {
  constructor(payload) {
    super(payload);
    this.gpsLatitude = payload.gps_latitude;
    this.gpsLongitude = payload.gps_longitude;
    this.category = CategoriesNames.N3Gateway;
    this.sensorIdentifier = this.identifier;
  }

  get type() {
    return 'imei';
  }
}

export class Beacon extends Device {
  constructor(payload) {
    super(payload);
    this.category = CategoriesNames.N3Zone;
    this.sensorIdentifier = this.identifier;
  }

  get type() {
    return 'beacon';
  }
}

export class N3RobotB extends Device {
  constructor(payload) {
    super(payload);
    this.category = CategoriesNames.N3Robot;
    this.sensorIdentifier = this.identifier;
  }

  get type() {
    return 'bleid';
  }
}
