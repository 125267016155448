<template>
  <div>
    <template v-if="place=='daily'">
      <div align="center">
        <v-card-text class="center">
          <img
            src="@/assets/empty-box.png"
          >
          <p class="display-2 text--primary">
            {{ $t('entity.empty.headlineDashboard') }}
          </p>
          <p>{{ $t('entity.empty.dashboard') }}</p>
        </v-card-text>
      </div>
    </template>
    <template v-if="place=='location'">
      <div align="center">
        <v-card-text class="center">
          <img
            src="@/assets/empty-box.png"
          >
          <p class="display-2 text--primary">
            {{ $t('entity.empty.headlineDashboard') }}
          </p>
          <p>{{ $t('entity.empty.descriptionLocation') }}</p>
        </v-card-text>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'EmptyData',
  props: {
    place: {
      type: String,
      default: 'location',
    },
  },
};
</script>
