var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"fill-height"},[_c('ManagementBar',{attrs:{"title":_vm.$tc('common.location', 2).capitalize(),"listsize":_vm.locationsToDisplay.length}},[_c('v-text-field',{attrs:{"prepend-inner-icon":"mdi-magnify","label":((_vm.$t('entity.find', { entity: _vm.$tc('common.location', 1) })) + "...").capitalize(),"single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),(_vm.isSuperAdmin)?_c('v-select',{staticClass:"v-select",attrs:{"loading":_vm.loading,"items":_vm.partners,"item-text":"name","item-value":"partner_id","flat":"","solo":"","label":_vm.$t('common.partner'),"clearable":""},model:{value:(_vm.selectedPartner),callback:function ($$v) {_vm.selectedPartner=$$v},expression:"selectedPartner"}}):_vm._e()],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.locationsToDisplay,"search":_vm.search,"loading":_vm.loading,"footer-props":{
      itemsPerPageOptions: [20, 50, 100, -1]
    },"disable-pagination":false,"expanded":_vm.expanded,"custom-sort":_vm.customSort,"custom-filter":_vm.customSearch,"item-key":"id","fixed-header":""},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
    var item = ref.item;
return [_c('span',{},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"expanded-item",fn:function(ref){
    var item = ref.item;
return [_c('td'),_c('td'),_c('td'),_c('td',{attrs:{"colspan":1}},[_c('p',{staticClass:"mt-4 mb-4"},[_vm._l((item.zoneZone),function(zone){return _c('span',{key:zone.id + 'Zone'},[_c('v-chip',{on:{"click":function($event){return _vm.redirect('zones', zone.name)}}},[_vm._v(" "+_vm._s(zone.name)+" ")])],1)}),_vm._l((item.robotZone),function(robotzone){return _c('span',{key:robotzone.id + 'Robot'},[_c('v-chip',{on:{"click":function($event){return _vm.redirect('zones', robotzone.name)}}},[_vm._v(" "+_vm._s(robotzone.name)+" ")])],1)}),_vm._l((item.environmentZone),function(environmentzone){return _c('span',{key:environmentzone.id + 'Env'},[_c('v-chip',{on:{"click":function($event){return _vm.redirect('zones', environmentzone.name)}}},[_vm._v(" "+_vm._s(environmentzone.name)+" ")])],1)})],2)]),_c('td',[_c('p',{staticClass:"mt-4 mb-4"},_vm._l((item.devices),function(device){return _c('span',{key:device.id},[_c('v-chip',{on:{"click":function($event){return _vm.redirect('devices', device.name)}}},[_vm._v(" "+_vm._s(device.name)+" ")])],1)}),0)]),_c('td'),_c('td',[_c('p',{staticClass:"mt-4 mb-4"},_vm._l((item.partnersIdentifier),function(partner){return _c('span',{key:partner.id},[_c('v-chip',{on:{"click":function($event){(_vm.isSuperAdmin) ? _vm.redirect('partners', partner.name) : function () {}}}},[_vm._v(" "+_vm._s(partner.name)+" ")])],1)}),0)]),_c('td',{attrs:{"colspan":_vm.headers.length - 2}})]}},{key:"item.zoneZone",fn:function(ref){
    var item = ref.item;
return [(item.zoneZone[0])?_c('v-chip',{attrs:{"color":"white"},on:{"click":function($event){return _vm.redirect('zones', item.zoneZone[0].name)}}},[_vm._v(" "+_vm._s(item.zoneZone[0].name)+" ")]):_vm._e(),(item.robotZone[0] && !item.zoneZone[0])?_c('v-chip',{attrs:{"color":"white"},on:{"click":function($event){return _vm.redirect('zones', item.robotZone[0].name)}}},[_vm._v(" "+_vm._s(item.robotZone[0].name)+" ")]):_vm._e(),(item.environmentZone[0] && !item.zoneZone[0] && !item.robotZone[0])?_c('v-chip',{attrs:{"color":"white"},on:{"click":function($event){return _vm.redirect('zones', item.environmentZone[0].name)}}},[_vm._v(" "+_vm._s(item.environmentZone[0].name)+" ")]):_vm._e(),(item.zoneZone.length + item.robotZone.length + item.environmentZone.length > 1)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.expandRow(item)}}},[(_vm.expanded.includes(item))?_c('v-icon',{attrs:{"size":"16"}},[_vm._v(" mdi-chevron-up ")]):_c('span',{staticClass:"caption"},[_vm._v(" "+_vm._s(item.zoneZone.length + item.robotZone.length + item.environmentZone.length - 1)+"+ ")])],1):_vm._e()]}},{key:"item.devices",fn:function(ref){
    var item = ref.item;
return [(item.devices[0])?_c('v-chip',{attrs:{"color":"white"},on:{"click":function($event){return _vm.redirect('devices', item.devices[0].name)}}},[_vm._v(" "+_vm._s(item.devices[0].name)+" ")]):_vm._e(),(item.devices[1])?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.expandRow(item)}}},[(_vm.expanded.includes(item))?_c('v-icon',{attrs:{"size":"16"}},[_vm._v(" mdi-chevron-up ")]):_c('span',{staticClass:"caption"},[_vm._v(" "+_vm._s(item.devices.length - 1)+"+ ")])],1):_vm._e()]}},{key:"item.partnersIdentifier",fn:function(ref){
    var item = ref.item;
return [(item.partnersIdentifier[0])?_c('v-chip',{attrs:{"color":"white"},on:{"click":function($event){(_vm.isSuperAdmin) ? _vm.redirect('partners', item.partnersIdentifier[0].name) : function () {}}}},[_vm._v(" "+_vm._s(item.partnersIdentifier[0].name)+" ")]):_vm._e(),(item.partnersIdentifier[1])?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.expandRow(item)}}},[(_vm.expanded.includes(item))?_c('v-icon',{attrs:{"size":"16"}},[_vm._v(" mdi-chevron-up ")]):_c('span',{staticClass:"caption"},[_vm._v(" "+_vm._s(item.partnersIdentifier.length - 1)+"+ ")])],1):_vm._e()]}},{key:"item.gpsLatitude",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.gpsLatitude)+", "+_vm._s(item.gpsLongitude)+" ")]}},{key:"item.client",fn:function(ref){
    var item = ref.item;
return [(item.client.name != '')?_c('v-chip',{attrs:{"color":"white"},on:{"click":function($event){return _vm.redirect('clients', item.client.name)}}},[_vm._v(" "+_vm._s(item.client.name)+" ")]):_vm._e()]}},{key:"item.created",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateWithTime")(_vm._f("gmtTime")(item.created)))+" ")]}},{key:"loading",fn:function(){return [_c('v-skeleton-loader',{staticClass:"mx-auto",attrs:{"max-width":"80%","type":"table-row@3"}})]},proxy:true}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }