<template>
  <v-card class="fill-height">
    <ManagementBar :title="$tc('common.user', 2).capitalize()" :listsize="users.length">
      <v-text-field
        v-model="search"
        prepend-inner-icon="mdi-magnify"
        :label="`${$t('entity.find', { entity: $tc('common.user', 1) })}...`.capitalize()"
        single-line
        hide-details
      />
      <!-- <template #actions>
        <v-btn color="success" small @click.stop="openDialog">
          <v-icon small>
            mdi-plus
          </v-icon>
          {{ $t("entity.new", { entity: $tc("common.user", 1) }) }}
        </v-btn>
      </template> -->
      <v-select
        v-if="isSuperAdmin"
        v-model="selectedPartner"
        :loading="loading"
        :items="partners"
        item-text="name"
        item-value="partner_id"
        class="v-select"
        flat
        solo
        :label="$t('common.partner')"
        clearable
      />
    </ManagementBar>
    <v-data-table
      :headers="headers"
      :items="usersToDisplay"
      :search="search"
      :custom-filter="customSearch"
      :loading="loading"
      :expanded.sync="expanded"
      :footer-props="{
        itemsPerPageOptions: [40, 80, 160, -1]
      }"
      :disable-pagination="false"
      item-key="user_id"
      fixed-header
    >
      <template #[`item.user_has_clis`]="{item}">
        <span v-if="item.user_has_clis[0]">
          {{ item.user_has_clis[0].n3client.name }}
        </span>
        <v-btn v-if="item.user_has_clis[1]" icon @click="expandRow(item)">
          <v-icon v-if="expanded.includes(item)" size="16">
            mdi-chevron-up
          </v-icon>
          <span v-else class="caption"> {{ item.user_has_clis.length - 1 }}+ </span>
        </v-btn>
      </template>

      <template #[`item.user_has_loc`]="{item}">
        <span v-if="item.user_has_loc[0] && isAdmin">
          {{ item.user_has_loc[0].n3location.loc_name }}
        </span>
        <v-btn v-if="item.user_has_loc[1] && isAdmin" icon @click="expandRow(item)">
          <v-icon v-if="expanded.includes(item)" size="16">
            mdi-chevron-up
          </v-icon>
          <span v-else class="caption"> {{ item.user_has_loc.length - 1 }}+ </span>
        </v-btn>
      </template>

      <template #[`item.partners`]="{item}">
        <span v-if="item.partners && item.partners[0] && isAdmin">
          {{ item.partners[0].name }}
        </span>
        <v-btn v-if="item.partners && item.partners[1] && isAdmin" icon @click="expandRow(item)">
          <v-icon v-if="expanded.includes(item)" size="16">
            mdi-chevron-up
          </v-icon>
          <span v-else class="caption"> {{ item.partners.length - 1 }}+ </span>
        </v-btn>
      </template>

      <template #expanded-item="{ item }">
        <td :colspan="2" />
        <td :colspan="3">
          <p class="mt-4 mb-4">
            <span v-for="user in item.user_has_clis" :key="user.n3client.name">
              <li>
                {{ user.n3client.name }}
              </li>
            </span>
          </p>
        </td>
        <td v-if="isAdmin" :colspan="3">
          <p class="mt-4 mb-4">
            <span v-for="user in item.user_has_loc" :key="user.n3location.loc_name">
              <li>
                {{ user.n3location.loc_name }}
              </li>
            </span>
          </p>
        </td>
        <td v-if="isAdmin" :colspan="2">
          <p class="mt-4 mb-4">
            <span v-for="partner in item.partners" :key="partner.name">
              <li>
                {{ partner.name }}
              </li>
            </span>
          </p>
        </td>
      </template>

      <template #[`item.email`]="{item}">
        <span>
          {{ item.email }}
        </span>
      </template>

      <template #[`item.email_confirmed`]="{item}">
        <span>
          <v-icon v-if="item.email_confirmed">mdi-account-check-outline</v-icon>
        </span>
      </template>

      <template #[`item.n3funct.fun_name`]="{item}">
        <span v-if="item.n3funct === null">
          n3-superadmin
        </span>
        <span v-else>
          {{ item.n3funct.fun_name }}
        </span>
      </template>

      <template #loading>
        <v-skeleton-loader class="mx-auto" max-width="80%" type="table-row@3" />
      </template>

      <!-- <template #no-data>
        <v-card-text class="pa-8">
          <v-icon slot="icon" size="64" class="mb-6">
            mdi-city
          </v-icon>
          <p class="display-1 text--primary">
            {{ $t("entity.empty.headline", { entities: $tc("common.user", 2) }) }}
          </p>
          <p>
            {{ $t("entity.empty.description", { entities: $tc("common.user", 2) }) }}
          </p>
          <v-btn tile color="blue lighten-4" @click="addUser">
            {{ $t("entity.add", { entity: $tc("common.user", 1) }) }}
          </v-btn>
        </v-card-text>
      </template> -->

      <!-- <template #[`item.action`]="{ item }">
        <span
          v-if="item.n3funct !== null && usersAllowToEdit.includes(item.n3funct.fun_name)"
          class="actions"
        >
          <v-icon small @click.stop="editItem(item)">
            mdi-pencil
          </v-icon>
          <v-icon
            v-if="item.n3funct.fun_name !== 'n3-superadmin'"
            small
            @click.stop="confirmDeleteItem(item)"
          >
            mdi-delete
          </v-icon>
          <v-icon small @click.stop="sendAgainActivationMail(item)">
            mdi-email-outline
          </v-icon>
        </span>
      </template> -->
    </v-data-table>
    <!-- <v-dialog v-model="dialog" max-width="560px">
      <UserForm
        :user="userToEdit"
        :clients="clients"
        :functs="functsAllowedtoAdd"
        :partners="partners"
        @submit="submit"
        @close="closeDialog"
      />
    </v-dialog>
    <DialogConfirm
      :show="confirmDialog"
      :danger="true"
      @cancel="closeConfirmDialog"
      @confirm="deleteItem"
    >
      {{ $t("confirmation.delete.title") }}
    </DialogConfirm> -->
  </v-card>
</template>

<script>

import {
  addUser,
  deleteNotificationWithEmail,
  deleteUser,
  fetchClients,
  fetchFuncts,
  fetchUsers,
  updateNotification,
  updateUser,
  fetchPartners,
} from '@/data';
// import UserForm from '@/views/Management/Users/Form';
import ManagementBar from '@/components/ManagementBar';
// import DialogConfirm from '@/components/Dialog/Confirm';
import { EventBus } from '@/eventBus';
import { resetPassword } from '@/data/user';

export default {
  name: 'ManagementUsers',
  components: {
    // DialogConfirm,
    ManagementBar,
    // UserForm,
  },
  inject: ['authGuard'],
  data() {
    return {
      users: [],
      clients: [],
      functs: [],
      expanded: [],
      emails: [],
      search: '',
      userToEdit: null,
      userToRemove: null,
      dialog: false,
      confirmDialog: false,
      loading: false,
      partners: [],
      selectedPartner: null,
    };
  },
  computed: {
    isAdmin() {
      return this.authGuard.user.role === 'n3-admin' || this.authGuard.user.role === 'n3-superadmin' || this.authGuard.user.role === 'n3-partner';
    },
    isSuperAdmin() {
      return this.authGuard.user.role === 'n3-superadmin';
    },
    alignAllowedLocations() {
      if (!this.isAdmin) {
        return ' d-none';
      }

      return '';
    },
    usersToDisplay() {
      const usersToDisplay = this.users.map((e) => {
        // eslint-disable-next-line camelcase
        if (e.n3funct?.fun_name === 'n3-partner') {
          const element = { ...e, partners: e.n3partner ? [e.n3partner] : [] };

          return element;
        }
        const partners = e.partner_has_users.map((element) => element.n3partner);
        const element = { ...e, partners };

        return element;
      });

      if (this.selectedPartner) {
        return usersToDisplay.filter((e) => e.partners.find((element) => element.partner_id === this.selectedPartner));
      }

      return usersToDisplay;
    },
    usersAllowToEdit() {
      if (this.authGuard.user.role === 'n3-superadmin') {
        return ['n3-admin', 'n3-superadmin', 'n3-siteinstaller', 'n3-admin-mid-leader', 'n3-user', 'n3-partner'];
      } if (this.authGuard.user.role === 'n3-partner') {
        return ['n3-admin', 'n3-siteinstaller', 'n3-admin-mid-leader', 'n3-user'];
      } if (this.authGuard.user.role === 'n3-admin') {
        return ['n3-admin', 'n3-siteinstaller', 'n3-admin-mid-leader', 'n3-user'];
      } if (this.authGuard.user.role === 'n3-admin-mid-leader') {
        return ['n3-user'];
      }

      return [];
    },
    functsAllowedtoAdd() {
      const functs = Array.from(this.functs);
      if (this.authGuard.user.role === 'n3-superadmin') {
        return functs;
      } if (this.authGuard.user.role === 'n3-partner') {
        return functs.filter((e) => e.fun_name !== 'n3-superadmin' && e.fun_name !== 'n3-partner');
      }
      if (this.authGuard.user.role === 'n3-admin') {
        return functs.filter((e) => e.fun_name !== 'n3-superadmin' && e.fun_name !== 'n3-partner');
      } if (this.authGuard.user.role === 'n3-admin-mid-leader') {
        return functs.filter((e) => e.fun_name === 'n3-user');
      }

      return [];
    },
    headers() {
      return [
        { text: this.$t('table.user.header.name'), value: 'full_name' },
        { text: 'Email', value: 'email' },
        { text: this.$t('table.user.header.phone'), value: 'phone', align: 'center' },
        { text: this.$t('table.user.header.emailConfirmed'), value: 'email_confirmed', align: 'center' },
        { text: this.$t('table.user.header.allowedClients'), value: 'user_has_clis', sortable: false },
        {
          text: this.$t('table.user.header.allowedLocations'), value: 'user_has_loc', sortable: false, align: this.alignAllowedLocations,
        },
        { text: this.$t('table.user.header.roles'), value: 'n3funct.fun_name' },
        { text: this.$t('table.user.header.client'), value: 'n3client.name' },
        { text: this.$t('table.user.header.partners'), value: 'partners', sortable: false },
        {
          text: '',
          value: 'action',
          filterable: false,
          sortable: false,
          width: '80px',
        },
      ];
    },
  },
  watch: {
    dialog(newVal, oldVal) {
      if (oldVal === true && newVal === false) {
        this.userToEdit = null;
      }
    },
  },
  created() {
    this.loadUsers().then(this.loadEmails);
    this.loadClients();
    this.loadFuncts();
    this.loadPartners();
  },
  methods: {
    async loadUsers() {
      this.loading = true;
      try {
        this.users = await fetchUsers();
        this.loading = false;
      } catch (e) {
        EventBus.$emit('generalErrorOccurred', {
          message: 'Cannot load data. Try again in a few seconds',
        });
          console.error(e) // eslint-disable-line
      }
    },
    async loadClients() {
      this.loading = true;
      try {
        this.clients = await fetchClients();
        this.loading = false;
      } catch (e) {
        EventBus.$emit('generalErrorOccurred', {
          message: 'Cannot load data. Try again in a few seconds',
        });
          console.error(e) // eslint-disable-line
      }
    },
    async loadFuncts() {
      this.loading = true;
      try {
        this.functs = await fetchFuncts();
        this.loading = false;
      } catch (e) {
        EventBus.$emit('generalErrorOccurred', {
          message: 'Cannot load data. Try again in a few seconds',
        });
          console.error(e) // eslint-disable-line
      }
    },
    loadEmails() {
      this.users.forEach((user) => {
        this.emails.push(user.email);
      });
    },
    async loadPartners() {
      try {
        this.partners = await fetchPartners();
        this.loading = false;
      } catch (e) {
        EventBus.$emit('generalErrorOccurred', {
          message: 'Cannot load data. Try again in a few seconds',
        });
        console.error(e); // eslint-disable-line
      }
    },
    openDialog() {
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
    },
    closeConfirmDialog() {
      this.confirmDialog = false;
    },
    confirmDeleteItem(item) {
      this.userToRemove = item;
      this.confirmDialog = true;
    },
    addUser() {
      this.openDialog();
    },
    editItem(item) {
      this.userToEdit = item;
      this.openDialog();
    },
    submit(submitted) {
      const {
        email, name, funId, clientsId, clientId, clientsToDeleteId, clientsToAddId, phone,
        allowedLocations, locationsToAdd, locationsToDelete, partnersToAdd, partnersToDelete, partner,
      } = submitted;
      if (this.userToEdit) {
        const index = this.usersToDisplay.indexOf(this.userToEdit);
        updateUser(
          this.userToEdit.user_id,
          {
            full_name: name, client_id: clientId, function: funId, phone, partner_id: partner,
          },
          clientsToAddId,
          clientsToDeleteId,
          locationsToAdd,
          locationsToDelete,
          partnersToAdd,
          partnersToDelete,
        )
          .then(updateNotification(email, name))
          .then((updatedUser) => {
            this.users.splice(index, 1, updatedUser);
          })
          .catch((error) => {
            EventBus.$emit('generalErrorOccurred');
              console.error(error) // eslint-disable-line
          })
          .finally(this.closeDialog);
      } else if (this.emails.includes(email)) {
        EventBus.$emit('generalErrorOccurred', {
          message: 'User with that email already exists',
        });
      } else {
        addUser(email, name, clientId, funId, clientsId, phone, allowedLocations, partnersToAdd, partner)
          .then((createdUser) => {
            this.users.push(createdUser);
            this.emails.push(createdUser.email);
          })
          .catch((graphQLErrors) => {
            if (graphQLErrors.message.includes('unique_email_deleted_at')) {
              EventBus.$emit('generalErrorOccurred', {
                message: 'User with that email already exists',
              });
            } else {
              throw graphQLErrors;
            }
          })
          .finally(this.closeDialog);
      }
    },

    deleteItem() {
      const index = this.users.findIndex((e) => e.user_id === this.userToRemove.user_id);
      if (index < 0) {
          console.error('This should not happen') // eslint-disable-line

        return;
      }
      this.users.splice(index, 1);
      this.emails.splice(index, 1);
      this.closeConfirmDialog();
      deleteUser(this.userToRemove.user_id, this.users)
        .then(deleteNotificationWithEmail(this.userToRemove.email))
        .catch((error) => {
          EventBus.$emit('generalErrorOccurred', {
            message: 'Cannot remove user',
          });
          console.error(error) // eslint-disable-line
          this.users.splice(index, 0, this.userToRemove);
          this.emails.splice(index, 0, this.userToRemove.email);
        });
    },
    expandRow(value) {
      if (this.expanded.includes(value)) {
        this.expanded = [];
      } else {
        this.expanded = [value];
      }
    },
    customSearch(value, search, users) {
      let toReturn;
      if (users.user_has_clis.length) {
        toReturn = false;
        for (let i = 0; i < users.user_has_clis.length; i += 1) {
          if (toReturn === false) {
            const clientSearched = users.user_has_clis[i].n3client.name.toString().toLowerCase();
            toReturn = value != null
                && search != null
                && (clientSearched.indexOf(search.toString().toLowerCase()) !== -1
                  || value
                    .toString()
                    .toLowerCase()
                    .indexOf(search.toString().toLowerCase()) !== -1);
          }
        }
      }

      return toReturn;
    },
    sendAgainActivationMail(item) {
      resetPassword(item.email)
        .then(() => EventBus.$emit('successInfo', 'Email sent successfully'))
        .catch((error) => {
          EventBus.$emit('generalErrorOccurred', {
            message: 'Send email failed',
          });
            console.error(error) // eslint-disable-line
        });
    },
  },
};
</script>
<style lang="scss" scoped>
table {
  tr {
    .actions {
      .v-icon {
        visibility: hidden;
      }
    }
    &:hover {
      .actions {
        .v-icon {
          visibility: visible;
        }
      }
    }
  }
}
.v-select {
    width: 1%;
    justify-content: center;
    margin-top: 25px;
}
</style>
