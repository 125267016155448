import { SubcategoriesNames } from '@/model/CategoriesObjects';
import {
  N3Evo, N3Robot, N3IAQ, N3Connector, Beacon, N3RobotB,
} from '@/model/devices/SubDevices';

export default class Zone {
  constructor(payload) {
    this.device = null;
    this.setDevice(payload.n3device);
    this.id = payload.zone_id;
    this.name = payload.name;
    this.location = payload.n3location;
    this.sensor = this.sensorIdValue;
    this.locationId = this.locationIdentifier;
    this.gpsLatitude = payload.gps_latitude;
    this.gpsLongitude = payload.gps_longitude;
    this.created = payload.created;
    this.sendNotification = payload.send_notification;
    this.zoneStatus = payload.zoneStatus;
    this.partnerId = payload.partner_id;
    this.n3partner = payload.n3partner;
    this.setDevice(payload.n3device);
  }

  assignDeviceToCategory(device) {
    if (device) {
      switch (SubcategoriesNames[device.sub_category_name]) {
        case SubcategoriesNames.N3Evo:
          return new N3Evo(device);
        case SubcategoriesNames.N3Robot:
          return new N3Robot(device);
        case SubcategoriesNames.Beacon:
          return new Beacon(device);
        case SubcategoriesNames.N3RobotB:
          return new N3RobotB(device);
        case SubcategoriesNames.N3IAQ:
          return new N3IAQ(device);
        case SubcategoriesNames.N3Connector:
          return new N3Connector(device);
        default:
          return null;
      }
    }

    return null;
  }

  setDevice(device) {
    this.device = this.assignDeviceToCategory(device);
  }

  get client() {
    return this.location?.n3client;
  }

  get sensorIdValue() {
    return this.device?.id;
  }

  get sensorName() {
    return this.device?.name;
  }

  get sensorSubcategory() {
    return this.device?.subcategory;
  }

  get locationIdentifier() {
    return this.location?.loc_id; // eslint-disable-line
  }
}
