<template>
  <GoogleMapLoader :api-key="apiKey" :bounds="bounds" :map-options="mapOptions">
    <template slot-scope="{ google, map }">
      <GoogleMapMarker
        v-for="marker in markers"
        :key="marker.id"
        :marker="marker"
        :google="google"
        :map="map"
        :icon="marker.icon"
      />
    </template>
  </GoogleMapLoader>
</template>

<script>

import GoogleMapLoader from '@/components/Map/GoogleMapLoader';
import GoogleMapMarker from '@/components/Map/GoogleMapMarker.js';
import { GOOGLE_API_KEY, GOOGLE_MAP_SETTINGS } from '@/configuration';
import zoneIcon from '@/assets/zoneMarker.png';
import robotIcon from '@/assets/robotMarker.png';
import gatewayIcon from '@/assets/gatewayMarker.png';
import environmentIcon from '@/assets/environmentMarker.png';

export default {
  name: 'WidgetZoneMap',
  components: {
    GoogleMapLoader,
    GoogleMapMarker,
  },
  props: {
    zones: {
      type: Array,
      default: () => [],
    },
    category: {
      type: String,
      required: true,
    },
    gateways: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      apiKey: GOOGLE_API_KEY,
    };
  },
  computed: {
    markers() {
      const devices = this.zones.map(({
        id, gpsLatitude, gpsLongitude, name: title,
      }) => {
        const position = { lat: gpsLatitude, lng: gpsLongitude };
        let icon;
        if (this.category === 'robots') {
          icon = robotIcon;
        } else if (this.category === 'environments') {
          icon = environmentIcon;
        } else {
          icon = zoneIcon;
        }

        return {
          id, position, title, icon,
        };
      });

      const gateways = this.gateways.map(({
        id, gpsLatitude, gpsLongitude, name,
      }) => ({
        id,
        position: { lat: gpsLatitude, lng: gpsLongitude },
        title: name,
        icon: gatewayIcon,
      }));

      return devices.concat(gateways);
    },
    bounds() {
      return this.zones.map(({ gpsLatitude, gpsLongitude }) => ({ lat: gpsLatitude, lng: gpsLongitude }));
    },
    mapOptions() {
      return {
        ...GOOGLE_MAP_SETTINGS,
        mapTypeId: 'satellite',
        zoom: 17,
      };
    },
  },
};

</script>
