<template>
  <v-app-bar app elevation="4" color="white">
    <v-toolbar-title>
      <v-badge
        color="blue"
        inline
        :content="listsize"
        :value="listsize"
      >
        {{ title }}
      </v-badge>
    </v-toolbar-title>
    <slot />
    <v-spacer />
    <slot name="actions" />
  </v-app-bar>
</template>

<script>

export default {
  name: 'ManagementBar',
  props: {
    title: {
      type: String,
      required: true,
    },
    listsize: {
      type: Number,
      default: 0,
    },
  },
};
</script>

<style lang="scss" scoped>

  @import './src/styles/variables';

  .v-app-bar {
    background: $sheet-bg;
  }
  .v-toolbar {
    &__title {
      margin-right: 20px;
      font-size: 1.15rem;
      line-height: 1.35;
    }
  }
  .v-btn {
    margin-left: 5px;
  }
</style>
