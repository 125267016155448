function getLowestMiddleHighest(rgbIntArray) {
  let highest = { val: -1, index: -1 };
  let lowest = { val: Infinity, index: -1 };
  
  /* eslint-disable-next-line */
  rgbIntArray.map((val, index) => {
    if (val > highest.val) {
      highest = { val, index };
    }
    if (val < lowest.val) {
      lowest = { val, index };
    }
  });
  
  if (lowest.index === highest.index) {
    lowest.index = highest.index + 1;
  }
    
  const middle = { index: (3 - highest.index - lowest.index) };
  middle.val = rgbIntArray[middle.index];

  return [lowest, middle, highest];
}
  
export function lightenColor(rgb) {
  const rgbIntArray = rgb.replace(/ /g, '').slice(4, -1).split(',').map((e) => parseInt(e, 10));
  // Grab the values in order of magnitude
  // This uses the getLowestMiddleHighest function from the saturate section
  const [lowest, middle, highest] = getLowestMiddleHighest(rgbIntArray);
    
  if (lowest.val === 255) {
    return rgb;
  }
    
  const returnArray = [];
  
  // First work out increase on lower value
  returnArray[lowest.index] = Math.round(lowest.val + (Math.min(255 - lowest.val, 70)));
  
  // Then apply to the middle and higher values
  const increaseFraction = (returnArray[lowest.index] - lowest.val) / (255 - lowest.val);
  returnArray[middle.index] = Math.round(middle.val + (255 - middle.val) * increaseFraction);
  returnArray[highest.index] = Math.round(highest.val + (255 - highest.val) * increaseFraction);
    
  // Convert the array back into an rgb string
  return (`rgb(${returnArray.join()})`);
}
