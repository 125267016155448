import LocationReport from '@/model/reports/LocationReport';

export class LocationReportCleaning extends LocationReport {
  constructor(payload, startDate, stopDate, color) {
    super(payload, startDate, stopDate, color);
    this.type = 'cleaning';
  }
}

export class LocationReportExpected extends LocationReport {
  constructor(payload, startDate, stopDate, color) {
    super(payload, startDate, stopDate, color);
    this.type = 'expected';
  }
}
