<template>
  <div class="fill-height">
    <v-container v-if="!loading">
      <v-row no-gutters align="center" justify="center">
        <v-col cols="2" align="center" justify="left">
          <v-card
            elevation="3"
            outlined
          >
            <v-row no-gutters style="">
              <v-col cols="1">
                <div :style="divStyle" />
              </v-col>
              <v-col cols="11">
                <v-list-item>
                  <v-icon x-large :color="iconColor">
                    {{ icon }}
                  </v-icon>
                  <v-list-item-content>
                    <v-list-item-title class="text-h2 text-left mb-3 mt-4 font-weight-medium">
                      {{ title }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content style="padding: 0">
                    <v-list-item-title class="text-h2 mb-1">
                      <v-row no-gutters align="center">
                        <v-col cols="3">
                          <span class="font-weight-medium" style="font-size: 12px;  text-align: left;">
                            {{ $tc('dashboard.table.average') }}:
                          </span>
                        </v-col>
                        <v-col cols="9">
                          <span class="font-weight-medium" style="font-size:32px;">
                            {{ averageValue }}
                          </span>
                          <span style="font-size:14px;">{{ unit }}</span>
                        </v-col>
                      </v-row>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <v-col cols="10">
          <VueApexCharts height="200" type="line" :options="chartOptions" :series="series" />
        </v-col>
      </v-row>
    </v-container>
    <v-container v-else>
      <v-row no-gutters align="center" justify="center">
        <v-progress-circular
          indeterminate
          :color="iconColor"
          :size="200"
          :width="10"
        />
      </v-row>
    </v-container>
  </div>
</template>
<script>

import VueApexCharts from 'vue-apexcharts';
import {
  sub, differenceInMinutes, add, format,
} from 'date-fns';
import { fetchDataForEnvironemntalChart } from '@/data/locations';
import {
  DATETIME_FORMAT, TIME_INTERVAL_30_DAYS, TIME_INTERVAL_7_DAYS, TIME_INTERVAL_1_DAY,
} from '@/configuration';
import i18n from '@/plugin/i18n';
import { approximationToQuarterHour } from '@/helpers/time';

const internalBoundriesOffset = 5;
const externalBoundriesOffset = 5;

export default {
  name: 'WidgetDataLineChartWithGradient',
  components: {
    VueApexCharts,
  },
  props: {
    minY: {
      type: Number,
      default: 0,
    },
    maxY: {
      type: Number,
      default: 0,
    },
    name: {
      type: String,
      default: 'temperature',
    },
    title: {
      type: String,
      default: 'Temperature',
    },
    timeRangeIndex: {
      type: Number,
      default: 0,
    },
    unit: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
    iconColor: {
      type: String,
      default: '',
    },
    deviceId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      loading: true,
      fetchedData: [],
      minX: sub(new Date(), { days: 1 }).getTime(),
      maxX: new Date().getTime(),
    };
  },
  computed: {
    divStyle() {
      return `height: 100%; border-right: 6px solid ${this.inRangeColor};`;
    },
    inRangeColor() {
      return (this.averageValue >= this.minY && this.averageValue <= this.maxY) ? '#52ad70' : '#cc3633';
    },
    dataCharts() {
      let values = [];
      if (this.fetchedData) {
        values = this.fetchedData
          .map(({ dtime: x, [this.name]: y }) => ({ x, y }));
      }

      return values.sort((a, b) => {
        if (a.x > b.x) return 1;

        return -1;
      });
    },
    dataForCharts() {
      const data = this.dataCharts;
      const lastTenElements = data.slice(Math.max(data.length - 10, 1));
      let sum = 0;
      for (let i = lastTenElements.length - 2; i >= 0; i -= 1) {
        sum += differenceInMinutes(new Date(data[i + 1].x).getTime(), new Date(data[i].x).getTime());
      }
      const calculatedInterval = (sum / (lastTenElements.length - 2)) * 1.1;
      // if time between samples is bigger than half hour, add sample with NaN
      for (let i = data.length - 2; i >= 0; i -= 1) {
        const timeDiff = differenceInMinutes(new Date(data[i + 1].x).getTime(),
          new Date(data[i].x).getTime());
        if (timeDiff > calculatedInterval) {
          const insert = {
            x: format(add(new Date(data[i].x), { minutes: calculatedInterval }), 'yyyy-MM-dd HH:mm'),
            y: null,
          };
          data.splice(i + 1, 0, insert);
        }
      }

      return data;
    },
    averageValue() {
      return parseInt(
        (
          this.dataCharts
            .reduce((total, next) => total + next.y, 0) / this.dataCharts.length
        ).toFixed(0),
        10,
      );
    },
    maxValue() {
      return Math.max(...this.dataCharts.map((o) => o.y).filter((val) => val !== null));
    },
    minValue() {
      return Math.min(...this.dataCharts.map((o) => o.y).filter((val) => val !== null));
    },
    timePeriod() {
      switch (this.timeRangeIndex) {
        case 0:
          return i18n.t('dashboard.day');
        case 1:
          return i18n.t('dashboard.week');
        case 2:
          return i18n.t('dashboard.month');
        default:
          return '';
      }
    },
    startDate() {
      switch (this.timeRangeIndex) {
        case 1:
          return new Date(new Date().valueOf() - TIME_INTERVAL_7_DAYS);
        case 2:
          return new Date(new Date().valueOf() - TIME_INTERVAL_30_DAYS);
        default:
          return new Date(new Date().valueOf() - TIME_INTERVAL_1_DAY);
      }
    },
    chartOptions() {
      return {
        chart: {
          id: this.title,
          animations: {
            enabled: false,
          },
          zoom: {
            type: 'xy',
            autoScaleYaxis: false,
          },
          events: {
            beforeZoom: (e, { xaxis }) => {
              const maindifference = (new Date(
                this.maxX,
              ).valueOf() - new Date(this.minX).valueOf());
              const zoomdifference = xaxis.max - xaxis.min;
              if (zoomdifference > maindifference) {
                return {
                  xaxis: {
                    min: new Date(this.minX).getTime(),
                    max: new Date(this.maxX).getTime(),
                  },
                };
              }

              return {
                xaxis: {
                  min: xaxis.min,
                  max: xaxis.max,
                },
              };
            },
            beforeResetZoom: () => ({
              xaxis: {
                min: new Date(this.minX).getTime(),
                max: new Date(this.maxX).getTime(),
              },
            }),
          },
          toolbar: {
            export: {
              csv: {
                filename: this.title.concat('_', format(new Date().getTime(), 'yyyy-MM-dd'), '_', this.timePeriod),
                headerCategory: 'Date',
                columnDelimiter: ';',
                dateFormatter(timestamp) {
                  return format(new Date(timestamp).getTime(), 'yyyy-MM-dd HH:mm');
                },
              },
            },
          },
        },
        stroke: {
          width: 3,
        },
        markers: {
          showNullDataPoints: false,
        },
        xaxis: {
          type: 'datetime',
          min: new Date(this.minX).getTime(),
          max: new Date(this.maxX).getTime(),
          labels: {
            show: true,
            datetimeUTC: false,
          },
          tooltip: {
            enabled: false,
          },
        },
        yaxis: {
          min: this.minValue - ((this.maxValue !== this.minValue) ? this.maxValue * 0.1 : 1),
          max: this.maxValue + ((this.maxValue !== this.minValue) ? this.maxValue * 0.1 : 1),
        },
        annotations: {
          yaxis: [
            {
              y: this.minValue - this.maxValue * 0.1,
              y2: this.minY,
              borderColor: 'black',
              opacity: 0.2,
            },
            {
              y: this.maxY,
              y2: this.maxValue + this.maxValue * 0.1,
              borderColor: 'black',
              opacity: 0.2,
            },
            {
              y: this.averageValue,
              borderColor: 'black',
              opacity: 1,
              strokeDashArray: 5,
            }],
        },
        tooltip: {
          x: {
            show: true,
            format: 'MM-dd HH:mm',
            formatter: undefined,
          },
        },
        fill: {
          type: 'gradient',
          opacity: 0.9,
          gradient: {
            type: 'vertical',
            shadeIntensity: 0.5,
            opacityFrom: 1,
            opacityTo: 1,
            colorStops: [
              [
                {
                  offset: this.topBorderOffset - externalBoundriesOffset,
                  color: this.colorTop,
                  opacity: 1,
                },
                {
                  offset: this.topBorderOffset + internalBoundriesOffset,
                  color: '#52ad70',
                  opacity: 1,
                },
                {
                  offset: this.bottomBorderOffset - internalBoundriesOffset,
                  color: '#52ad70',
                  opacity: 1,
                },
                {
                  offset: this.bottomBorderOffset + externalBoundriesOffset,
                  color: this.colorBottom,
                  opacity: 1,
                },
              ],
            ],
          },
        },
      };
    },
    series() {
      return [{
        name: this.title + this.unit,
        data: this.dataForCharts,
      }];
    },
    topBorderOffset() {
      if (this.maxValue < this.maxY) {
        return 0;
      }

      return this.scaleOffset(this.maxValue - this.maxY);
    },
    bottomBorderOffset() {
      if (this.minValue < this.minY) {
        return this.scaleOffset(this.maxValue - this.minY);
      }

      return 100;
    },
    colorTop() {
      if (this.maxValue < this.maxY) {
        return '#52ad70';
      }

      return '#cc3633';
    },
    colorBottom() {
      if (this.minValue >= this.minY) {
        return '#52ad70';
      }

      return '#cc3633';
    },
  },
  watch: {
    timeRangeIndex() {
      this.loadData();
    },
  },
  mounted() {
    this.loadData();
  },
  methods: {
    scaleOffset(value) {
      const wholeRange = this.maxValue - this.minValue;

      return (100 * value) / wholeRange;
    },
    async loadData() {
      this.loading = true;
      const dateStartT = format(approximationToQuarterHour(this.startDate), DATETIME_FORMAT);
      const dateStopT = format(approximationToQuarterHour(Date.now()), DATETIME_FORMAT);
      this.fetchedData = await fetchDataForEnvironemntalChart(
        this.deviceId,
        this.name,
        dateStartT,
        dateStopT,
      );
      this.minX = new Date(dateStartT);
      this.maxX = new Date(dateStopT);
      this.loading = false;
    },
  },
};

</script>

<style lang="scss" scoped>

  .chart-container {
    position: relative;
    height: 200px;
    width: 100%;
  }

</style>
