var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"9"}},[_c('v-card',{staticClass:"fill-height widget "},[_c('div',{staticClass:"wrapper"},[(_vm.locationData)?_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.zones,"loading":_vm.loading,"item-key":"id","expanded":_vm.expanded,"disable-pagination":"","hide-default-footer":"","show-select":""},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([_vm._l((_vm.headers),function(h){return {key:("header." + (h.value)),fn:function(ref){
var header = ref.header;
return [_c('span',{key:h.value},[_vm._v(_vm._s(h.text)+" ")]),(h.value == 'cleaningTimesSumForToday' ||
                    h.value == 'expectedCleaningTime' ||
                    h.value == 'cleaningTimeDiscrepancy' ||
                    h.value == 'todayCleaningDates' ||
                    h.value == 'cleaningStatus')?_c('v-tooltip',{key:h.text,attrs:{"top":"","max-width":"200","open-delay":"200"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary","dark":"","small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-help-circle-outline ")])]}}],null,true)},[(h.value == 'cleaningTimesSumForToday')?_c('span',[_vm._v(_vm._s(_vm.$t('tooltip.zones.CleaningTime'))+" ")]):_vm._e(),(h.value == 'expectedCleaningTime')?_c('span',[_vm._v(_vm._s(_vm.$t('tooltip.zones.ExpectedTime'))+" ")]):_vm._e(),(h.value == 'cleaningTimeDiscrepancy')?_c('span',[_vm._v(_vm._s(_vm.$t('tooltip.zones.Discrepancy'))+" ")]):_vm._e(),(h.value == 'todayCleaningDates')?_c('span',[_vm._v(_vm._s(_vm.$t('tooltip.zones.FinishTime'))+" ")]):_vm._e(),(h.value == 'cleaningStatus')?_c('span',[_vm._v(_vm._s(_vm.$t('tooltip.zones.Status'))+" ")]):_vm._e()]):_vm._e()]}}}),{key:"header.data-table-select",fn:function(){return [_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"return-value":_vm.date,"offset-y":"","offset-x":"","right":"","disabled":!_vm.checkIfZoneSelected},on:{"update:returnValue":function($event){_vm.date=$event},"update:return-value":function($event){_vm.date=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","color":"white","depressed":""},on:{"click":_vm.displayMessage}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-file-export")]),_vm._v(" "+_vm._s(_vm.exportText)+" ")],1)]}}],null,false,3781281351)},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":"","range":"","locale":_vm.language,"color":"grey","first-day-of-week":"1","max":_vm.todayDate},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":[function($event){return _vm.$refs.menu.save(_vm.date)},function($event){$event.stopPropagation();return _vm.exportToFile($event)}]}},[_vm._v(" OK ")])],1)],1)]},proxy:true},{key:"item.todayCleaningDates",fn:function(ref){
                    var item = ref.item;
return [_vm._l((item.todayCleaningDates),function(cleaningDate,index){return _c('span',{key:cleaningDate},[(index < 5)?_c('span',[_vm._v(" "+_vm._s(_vm._f("formatDate")(cleaningDate,'HH:mm'))+", ")]):_vm._e()])}),(item.todayCleaningDates[5])?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.expandRow(item)}}},[(_vm.expanded.includes(item))?_c('v-icon',{attrs:{"size":"16"}},[_vm._v(" mdi-chevron-up ")]):_c('span',{staticClass:"caption"},[_vm._v(" "+_vm._s(item.todayCleaningDates.length - 5)+"+ ")])],1):_vm._e()]}},{key:"expanded-item",fn:function(ref){
                    var item = ref.item;
return [_c('td'),_c('td',{attrs:{"colspan":_vm.headers.length - 1}},[_c('p',{staticClass:"mt-4 mb-4"},_vm._l((item.todayCleaningDates),function(cleaningDate){return _c('span',{key:cleaningDate},[_vm._v(" "+_vm._s(_vm._f("formatDate")(cleaningDate,'HH:mm'))+", ")])}),0)])]}},{key:"item.expectedCleaningTime",fn:function(ref){
                    var item = ref.item;
return [(item.cleaningStatus===_vm.cleaningStatusNotRequired)?_c('span',[_vm._v(" off ")]):_c('span',[_vm._v(" "+_vm._s(item.expectedCleaningTime)+" ")])]}},{key:"item.cleaningTimeDiscrepancy",fn:function(ref){
                    var item = ref.item;
return [(item.cleaningStatus===_vm.cleaningStatusNotRequired)?_c('span',[_vm._v(" 0 ")]):_c('span',[_vm._v(" "+_vm._s(item.cleaningTimeDiscrepancy)+" ")])]}},{key:"item.cleaningStatus",fn:function(ref){
                    var item = ref.item;
return [_c('BadgeCleaningStatus',{attrs:{"cleaning-status":item.todayCleaningStatus}})]}}],null,true),model:{value:(_vm.selectedZones),callback:function ($$v) {_vm.selectedZones=$$v},expression:"selectedZones"}}):_vm._e()],1)])],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-card',{staticClass:"fill-height widget "},[_c('div',{staticClass:"chart-wrapper"},[_c('WidgetZoneCleaningStatusChart',{attrs:{"title":_vm.$tc('dashboard.location.todayStatus'),"zones-chart":_vm.zonesChart},on:{"chart:clicked":_vm.onChartClicked}})],1)])],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"fill-height widget"},[_c('div',{staticClass:"wrapper"},[_c('WidgetCleaningStatusWeekly',{attrs:{"category":_vm.category,"location":_vm.location,"dates":_vm.dates}})],1)])],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"5"}},[_c('v-card',{staticClass:"fill-height widget "},[_c('div',{staticClass:"wrapper"},[_c('WidgetZoneCleaningActivityChart',{attrs:{"location":_vm.location,"status":_vm.cleaningStatuses,"title":_vm.$tc('dashboard.cleaningStatusChart'),"category":_vm.category}})],1)])],1),_c('v-col',{attrs:{"cols":"7"}},[_c('v-card',{staticClass:"fill-height widget"},[_c('WidgetZoneMap',{attrs:{"zones":_vm.zones,"gateways":_vm.gateways,"category":_vm.category}})],1)],1)],1),_c('v-row')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }