<template>
  <div>
    <div v-if="locations.length" class="data-wrapper" :class="{'loading': loading}">
      <v-container fluid class="px-0">
        <grid-layout
          :layout.sync="layout"
          :col-num="12"
          :row-height="30"
          :margin="[16, 16]"
          :responsive="true"
          @layout-updated="layoutUpdatedEvent"
        >
          <grid-item
            v-for="(item) in layout"
            :key="item.i"
            :x="item.x"
            :y="item.y"
            :w="item.w"
            :h="item.h"
            :i="item.i"
            :max-w="12"
            :min-w="4"
            :min-h="8"
            :margin="[12, 12]"
            drag-allow-from=".widget__header"
            drag-ignore-from="a, button, .widget__subtitle"
            @resized="resized"
            @moved="moved"
          >
            <Widget
              :id="item.i"
              :type="item.id"
              :title="item.config.title"
              :widget-props="widgetProps"
              @location:selected="handleLocationSelect"
              @status:selected="handleFiltersSelect"
            />
          </grid-item>
        </grid-layout>
      </v-container>
    </div>
    <div v-else>
      <EmptyData v-if="!loading" :place="place" />
    </div>
    <LoaderWidgetLoader v-if="loading" :size="66" :width="5" class="widget-loader" />
  </div>
</template>
<script>

import VueGridLayout from 'vue-grid-layout';
import Widget from '@/components/Widget';
import LoaderWidgetLoader from '@/components/Loader/WidgetLoader';
import EmptyData from '@/components/EmptyData';
import {
  getStatus, getMidLeader, getClient, getPartner,
} from '@/router/helpers';
import { getDashboardConfig, saveDashboardConfig } from '@/data/dashboard';
import { getDeviceLocations } from '@/data/locations';
import { capitalize } from '@/helpers/string';

export default {
  name: 'Dashboard',
  components: {
    EmptyData,
    GridLayout: VueGridLayout.GridLayout,
    GridItem: VueGridLayout.GridItem,
    LoaderWidgetLoader,
    Widget,
  },
  props: {
    category: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      userId: 0,
      clientId: 0,
      cleaningStatuses: [],
      layout: [],
      layoutModified: false,
      loading: true,
      locations: [],
      locationsCharts: [],
      place: 'daily',
      reportRoutes: [
        { label: 'All Locations', name: 'report.daily' },
      ],
      widgetProps: {},
      urlValues: [0, 5, 10],
      partnerId: 0,
    };
  },
  computed: {
    title() {
      return `N3${capitalize(this.category)}`;
    },

  },
  watch: {
    async category() {
      this.layout = [];
      await this.loadLocations();
      this.loadLayout();
    },
    $route(to) {
      this.userId = getMidLeader(to.query);
      this.clientId = getClient(to.query);
      this.cleaningStatuses = getStatus(to.query);
      this.partnerId = getPartner(to.query);
      this.widgetProps = this.computeWidgetProps();
    },
  },
  async beforeMount() {
    this.userId = getMidLeader(this.$route.query);
    this.clientId = getClient(this.$route.query);
    this.cleaningStatuses = getStatus(this.$route.query);
    this.partnerId = getPartner(this.$route.query);
  },
  created() {
    this.loadLayout();
    this.loadLocations();
  },
  methods: {
    async loadLayout() {
      this.layout = await getDashboardConfig(this.category);
    },
    async loadLocations() {
      this.loading = true;
      try {
        this.locations = await getDeviceLocations(this.category, new Date());
        this.widgetProps = this.computeWidgetProps();
        this.loading = false;
      } catch (e) {
        console.error(e); // eslint-disable-line
      }
    },
    computeWidgetProps() {
      return {
        locations: this.locations.filter((location) => !((this.clientId && location.clientIdentifier !== this.clientId)
          || (this.userId && location.usersIds.indexOf(this.userId) < 0)
          || (this.cleaningStatuses.length && this.cleaningStatuses.indexOf(location.status) < 0)
          || (this.partnerId && !location.partnersIds.includes(this.partnerId)))),
        category: this.category,
        locationsCharts: this.locations.filter((location) => !((this.clientId && location.clientIdentifier !== this.clientId)
          || (this.userId && location.usersIds.indexOf(this.userId) < 0)
          || (this.partnerId && !location.partnersIds.includes(this.partnerId)))),
      };
    },
    moved() {
      this.layoutModified = true;
    },
    resized() {
      this.layoutModified = true;
    },
    layoutUpdatedEvent(newLayout) {
      if (!this.layoutModified) {
        return;
      }

      this.layoutModified = false;
      saveDashboardConfig(newLayout, this.category)
        .catch((error) => {
          // @toodo show user info
          this.layoutModified = true;
          console.error('Cannot save layout due:', error); // eslint-disable-line
        });
    },
    handleLocationSelect({ id }) {
      const { partner } = this.$route.query;
      this.$router.push({ name: 'dashboard.location', params: { locationId: id }, query: { partner } });
    },
    handleFiltersSelect(filters) {
      const { name, params, path } = this.$route;
      const url = getStatus(this.$route.query);
      const { midLeader, client, partner } = this.$route.query;
      let query = null;
      if (!url.includes(filters)) {
        url.unshift(filters);
        query = {
          midLeader, status: url.join(','), client, partner,
        };
        this.$router.push({ name, params, query });
      } else {
        const urlFiltered = url.filter((item) => item !== filters);
        query = {
          midLeader, status: urlFiltered.join(','), client, partner,
        };
        if (!urlFiltered.length) {
          query = { midLeader, client, partner };
          this.$router.push({ path, query });
        } else {
          this.$router.push({ name, params, query });
        }
      }
    },
  
  },
};

</script>

<style lang="scss" scoped>
@import './src/styles/variables';

* ::v-deep .vue-grid-item {
  &.vue-grid-placeholder {
    background: $primary-brand-color;
    opacity: 0.13;
  }
}

.inline-menu {
  &__item {
    text-decoration: none;
    color: rgba($link-color, 0.55);
    padding: 0 $list-item-action-icon-margin;
    &:hover {
      color: rgba($link-color, 0.90);
    }
    &--current {
      color: $link-color;
    }
  }
}
.center {
  justify-content: center;
}

.widget-loader {
  position: fixed;
  top: 33%;
  left: 50%;
}

.data-wrapper {
  &.loading {
    opacity: 0.5;
  }
}
</style>
