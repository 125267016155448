<template>
  <div class="fill-height">
    <ChartHorizontalBar
      class="chart-container"
      :chart-data="chartData"
      :options="chartOptions"
    />
  </div>
</template>

<script>

import ChartHorizontalBar from '@/components/Chart/HorizontalBar';

/**
 * @TODO needs to be rethinked then redesigned then refactored
 * Note that `WidgetZoneCleaningActivityChart` is very similar
 */
export default {
  name: 'WidgetCleaningActivityChart',
  components: {
    ChartHorizontalBar,
  },
  props: {
    category: {
      type: String,
      required: true,
    },
    locations: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      rawChartData: [],
      chartOptions: {
        maintainAspectRatio: false,
        legend: {
          display: false,
        },
        scales: {
          xAxes: [
            {
              ticks: {
                suggestedMin: 0,
              },
            },
          ],
        },
        tooltips: {
          filter: (tooltipItem, data) => {
            const label = data.labels[tooltipItem.index];
            if (label === this.$t('dashboard.otherActivity')) {
              /* eslint-disable-next-line */
              tooltipItem.label = `${this.$t('dashboard.otherActivity')}- ${this.$t('tooltip.zones.OtherActivity')}`;
            }

            return true;
          },
          
        },
      },
    };
  },
  computed: {
    chartData() {
      let cleaningTime = 0;
      let expected = 0;
      let otherActivity = 0;
      this.locations.forEach((location) => {
        cleaningTime += location.times.cleaning_time;
        expected += location.times.expected_time;
        otherActivity += location.times.other_activity;
      });

      return {
        labels: this.labels(),
        datasets: [
          {
            backgroundColor: ['#0D91E3', '#8644A2', '#737373'],
            data: this.dataToDisplay(cleaningTime, expected, otherActivity),
          },
        ],
      };
    },
  },
  methods: {
    labels() {
      if (this.category === 'zones') {
        return [this.$t('dashboard.location.cleaningTime'), this.$t('dashboard.expected'), this.$t('dashboard.otherActivity')];
      }

      return [this.$t('dashboard.location.cleaningTime'), this.$t('dashboard.expected')];
    },
    dataToDisplay(cleaningTime, expected, otherActivity) {
      if (this.category === 'zones') {
        return [cleaningTime, expected, otherActivity];
      }

      return [cleaningTime, expected];
    },
  },
};

</script>

<style lang="scss" scoped>

.chart-container {
  position: relative;
  height: 100%;
  width: 100%;
}

</style>
