<template>
  <div class="fill-height">
    <v-data-table
      :headers="headers"
      :items="locations"
      :sort-by="['status','name']"
      :sort-asc="[true]"
      item-key="id"
      disable-pagination
      hide-default-footer
      fixed-header
      fixed
      @click:row="toLocationDetails"
    >
      <template #[`item.status`]="{item}">
        <BadgeCleaningStatus :cleaning-status="item.status" />
      </template>
    </v-data-table>
  </div>
</template>
<script>

import BadgeCleaningStatus from '@/components/Badge/CleaningStatus';

export default {
  name: 'WidgetCleaningStatus',
  components: {
    BadgeCleaningStatus,
  },
  props: {
    locations: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      headers: [
        { text: this.$t('form.label.location'), value: 'name' },
        { text: this.$t('dashboard.status'), value: 'status', align: 'right' },
      ],
    };
  },
  methods: {
    toLocationDetails(location) {
      this.$emit('location:selected', location);
    },
  },
};

</script>

<style lang="scss" scoped>

::v-deep {
  .v-data-table {
    tr {
      &:hover {
        cursor: pointer;
      }
    }
    td.text-right{
      padding-right: 30px;
    }

    &--fixed-header {
      height: 100%;

      .v-data-table__wrapper {
        max-height: 100%;
      }
    }
  }
}

</style>
