import { render, staticRenderFns } from "./DashboardFilter.vue?vue&type=template&id=ad70839a&scoped=true&v-slot%3Aextension=true&"
import script from "./DashboardFilter.vue?vue&type=script&lang=js&"
export * from "./DashboardFilter.vue?vue&type=script&lang=js&"
import style0 from "./DashboardFilter.vue?vue&type=style&index=0&id=ad70839a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ad70839a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
installComponents(component, {VBadge,VBtn,VCard,VCheckbox,VIcon,VList,VListItem,VMenu})
