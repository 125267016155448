import gql from 'graphql-tag';
import { apolloClient } from '@/integration/apollo';
import { authentication } from '@/security/Authentication';
import i18n from '@/plugin/i18n';

const defaultLayout = [
  {
    id: 'WidgetCleaningStatus',
    config: {
      title: i18n.t('dashboard.cleaningStatus'),
    },
    x: 0,
    y: 8,
    w: 5,
    h: 10,
    i: '0',
  },
  {
    id: 'WidgetCleaningStatusChart',
    config: {
      title: i18n.t('dashboard.cleaningStatusChart'),
    },
    x: 0,
    y: 0,
    w: 5,
    h: 8,
    i: '1',
  },
  {
    id: 'WidgetCleaningActivityChart',
    config: {
      title: i18n.t('dashboard.cleaningActivityChart'),
    },
    x: 5,
    y: 0,
    w: 4,
    h: 8,
    i: '2',
  },
  {
    id: 'WidgetLocationMap',
    config: {
      title: '',
    },
    x: 5,
    y: 8,
    w: 4,
    h: 10,
    i: '3',
  },
];

const defaultLayoutEnvironemnt = [
  {
    id: 'WidgetEnvironmentStatus',
    config: {
      title: i18n.t('dashboard.environmentStatus'),
    },
    x: 0,
    y: 8,
    w: 5,
    h: 10,
    i: '0',
  },
  {
    id: 'WidgetEnvironmentStatusChart',
    config: {
      title: i18n.t('dashboard.environmentStatusChart'),
    },
    x: 0,
    y: 0,
    w: 5,
    h: 8,
    i: '1',
  },
  {
    id: 'WidgetLocationMap',
    config: {
      title: '',
    },
    x: 5,
    y: 8,
    w: 4,
    h: 10,
    i: '2',
  },
];

/**
 * @todo remove userId
 */
function getDashboardSettingsQuery(category) {
  return gql` query getDashboardSettings($email: String!) { 
    layout: n3dashboard_settings(where: {n3user: {email: {_eq: $email}}}) { 
      dashboard_setting: dashboard_setting_${category} 
      } 
      }`;
}

export async function getDashboardConfig(category) {
  const { email } = authentication.currentUser();
  const query = getDashboardSettingsQuery(category);

  const response = await apolloClient.query({
    query,
    variables: {
      email,
    },
  });
  if (!response.data.layout.length || !response.data.layout[0].dashboard_setting) {
    if (category !== 'environment') {
      return defaultLayout;
    }

    return defaultLayoutEnvironemnt;
  }
  const { dashboard_setting: layout } = response.data.layout[0];
  if (!layout) {
    throw Error('Response for: "getDashboardSettings" is invalid');
  }

  return layout;
}

/**
 * @todo remove userId
 * @param {Object} layout
 * @param {Object} name
 * @returns {Promise<Boolean>}
 */
export async function saveDashboardConfig(layout, category) {
  const { email } = authentication.currentUser();
  const query = getDashboardSettingsQuery(category);

  const userIdQuery = gql`
    query getUserId($email: String!) {
      users: n3user(where: {email: {_eq: $email}}) {
        user_id
      }
    }
  `;
  const mutation = gql`
    mutation insertDashboardSettings($data: [n3dashboard_settings_insert_input!]!, $userId: Int!) {
      create: insert_n3dashboard_settings(
        objects:  $data,
        on_conflict: {
          constraint: n3dashboard_settings_userid_key,
          update_columns: dashboard_setting_${category},
          where: {user_id: {_eq: $userId}}
        }
      ) {
        affected_rows
        returning {
          id
          dashboard_setting_${category}
        }
      }
    }
  `;
 
  const newLayout = [{
    dashboard_setting: layout,
    __typename: 'n3dashboard_settings',
  }];

  apolloClient.writeQuery({
    query,
    variables: {
      email,
    },
    data: {
      layout: newLayout,
    },
  });

  const userIdResponse = await apolloClient.query({
    query: userIdQuery,
    variables: { email },
  });
  const userId = userIdResponse.data.users[0].user_id;
  const data = { user_id: userId, [`dashboard_setting_${category}`]: layout };
  const response = await apolloClient.mutate({
    mutation,
    variables: {
      data,
      userId,
    },
  });
  const { affected_rows: upserts } = response.data.create;
  if (typeof upserts === 'undefined') {
    throw Error('Response for: "insertDashboardSettings" is invalid');
  }

  switch (upserts) {
    case 0:
      throw Error('Configuration was not saved');
    case 1:
      return true;
    default:
      throw Error('Layout was stored for more than one user.');
  }
}
