var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('vue-html2pdf',{ref:"html2Pdf",attrs:{"show-layout":false,"float-layout":true,"enable-download":true,"preview-modal":false,"paginate-elements-by-height":1400,"manual-pagination":false,"html-to-pdf-options":{
    margin:10,
    filename: _vm.fileName,
    image: {
      type: 'jpeg',
      quality:2
    },
    jsPDF: {
      unit: 'mm',
      format: 'a4',
      orientation: 'landscape'
    },
    pagebreak: {
      mode: ['css','legacy'],
    }
  },"pdf-content-width":"1000px"}},[_c('section',{attrs:{"slot":"pdf-content"},slot:"pdf-content"},[_c('section',{staticClass:"pdf-item"},[_c('div',{staticStyle:{"position":"relative","height":"75px"}},[_c('div',{staticStyle:{"position":"absolute","right":"0"}},[_c('div',{staticStyle:{"text-align":"right"}},[_c('img',{attrs:{"src":require("@/assets/logoReport.png"),"height":"50"}})]),_c('h2',{staticStyle:{"text-align":"right","font-size":"10px"}},[_vm._v(" "+_vm._s(_vm.currentTime)+" ")])]),_c('div',{staticStyle:{"position":"absolute","left":"0","right":"0","margin-left":"auto","margin-right":"auto"}},[_c('h2',{staticStyle:{"text-align":"center"}},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('br'),_c('h3',{staticStyle:{"text-align":"center"}},[_vm._v(" "+_vm._s(_vm.reportName)+" ")])])]),_c('br')]),_c('section',{staticClass:"pdf-item"},[_c('CleaningMonthlyReport',{attrs:{"report":_vm.report,"dates":_vm.dates,"type-of-report":_vm.type}})],1),(_vm.type==='many')?_c('div',{staticClass:"html2pdf__page-break"}):_vm._e(),_c('section',{staticClass:"pdf-item"},[_c('CleaningMonthlyReportChart',{attrs:{"total-expected-time":_vm.expectedTimes,"total-cleaning-time":_vm.cleaningTimes}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }