import Zone from '@/model/zones/Zone';
import { CategoriesNames } from '@/model/CategoriesObjects';
import { EnvironmentStatus } from '@/model/EnvironmentStatus';
import { format } from 'date-fns';
import { calculateTrend } from '@/helpers/trend';
import { TIME_INTERVAL_1_HOUR } from '@/configuration';
import i18n from '@/plugin/i18n';

export default class EnvironmentZone extends Zone {
  constructor(payload) {
    super(payload);
    this.roomId = payload.room_id;
    this.floorId = payload.floor_id;
    this.maxCo2 = payload.max_co2;
    this.minCo2 = payload.min_co2;
    this.maxDust = payload.max_dust;
    this.minDust = payload.min_dust;
    this.maxHumidity = payload.max_humidity;
    this.minHumidity = payload.min_humidity;
    this.maxTemperature = payload.max_temperature;
    this.minTemperature = payload.min_temperature;
    this.category = CategoriesNames.N3Environment;
  }

  get lastMeasureDate() {
    const lastMeasureDate = this.device?.chartData.length > 0
      ? format(new Date(this.device.chartData[0].dtime), 'yyyy-MM-dd HH:mm:ss')
      : i18n.t('dashboard.table.NoLastMeasure');
    Object.defineProperty(this, 'lastMeasureDate', { value: lastMeasureDate });

    return lastMeasureDate;
  }

  get lastEightSamples() {
    const lastEightSamples = (this.chartData.length > 0) ? this.chartData.slice(0, 8) : [];
    Object.defineProperty(this, 'lastEightSamples', { value: lastEightSamples });

    return lastEightSamples;
  }

  get averageForLastDay() {
    const isSomeNotNullTemperature = this.chartData.some((e) => e.temperature !== null);
    const isSomeNotNullHumidity = this.chartData.some((e) => e.humidity !== null);
    const isSomeNotNullDustLevel = this.chartData.some((e) => e.dust_level !== null);
    const isSomeNotNullCo2 = this.chartData.some((e) => e.co2 !== null);
    const averageForLastDay = {
      temperature: (isSomeNotNullTemperature)
        ? this.chartData.reduce((a, b) => a + b.temperature, 0) / this.chartData.length : null,
      humidity: (isSomeNotNullHumidity)
        ? this.chartData.reduce((a, b) => a + b.humidity, 0) / this.chartData.length : null,
      dustLevel: (isSomeNotNullDustLevel)
        ? this.chartData.reduce((a, b) => a + b.dust_level, 0) / this.chartData.length : null,
      co2: (isSomeNotNullCo2)
        ? this.chartData.reduce((a, b) => a + b.co2, 0) / this.chartData.length : null,
    };
    Object.defineProperty(this, 'averageForLastDay', { value: averageForLastDay });

    return averageForLastDay;
  }

  get averageForLastHour() {
    const timeHourAgo = new Date(new Date().valueOf() - TIME_INTERVAL_1_HOUR).getTime();
    const dataLastHour = this.chartData.filter(
      (e) => new Date(e.dtime).getTime() > timeHourAgo,
    );

    const averageForLastHour = {
      temperature: dataLastHour.reduce((a, b) => a + b.temperature, 0) / dataLastHour.length,
      humidity: dataLastHour.reduce((a, b) => a + b.humidity, 0) / dataLastHour.length,
      dustLevel: dataLastHour.reduce((a, b) => a + b.dust_level, 0) / dataLastHour.length,
      co2: dataLastHour.reduce((a, b) => a + b.co2, 0) / dataLastHour.length,
    };
    Object.defineProperty(this, 'averageForLastHour', { value: averageForLastHour });

    return averageForLastHour;
  }

  get chartData() {
    const chartData = this.device?.chartData ? this.device?.chartData : [];
    Object.defineProperty(this, 'chartData', { value: chartData });

    return chartData;
  }

  get zoneStatuses() {
    const zoneStatuses = [];
    if (this.zoneStatus) {
      this.zoneStatus.forEach((singleStatusData) => {
        zoneStatuses.push(
          {
            measureStatus: (singleStatusData.status === 1
              ? EnvironmentStatus.GOOD_CONDITIONS : EnvironmentStatus.BAD_CONDITIONS),
            measureDate: singleStatusData.status_date,
          },
        );
      });
      zoneStatuses.sort((a, b) => {
        if (a.measureDate > b.measureDate) return 1;

        return -1;
      });
    }
    Object.defineProperty(this, 'zoneStatuses', { value: zoneStatuses });

    return zoneStatuses;
  }

  get todayEnvironmentStatus() {
    if (this.zoneStatuses.length > 0) {
      if (this.zoneStatuses[0].measureDate === format(new Date(), 'yyyy-MM-dd')) {
        return this.zoneStatuses[0].measureStatus;
      }

      return 0;
    }

    return 0;
  }

  get averagedMeasures() {
    const averagedMeasures = {
      temperature: this.averageForLastDay.temperature !== null
        ? this.averageForLastDay.temperature.toFixed(1) : null,
      humidity: this.averageForLastDay.humidity !== null
        ? this.averageForLastDay.humidity.toFixed(0) : null,
      dustLevel: this.averageForLastDay.dustLevel !== null
        ? this.averageForLastDay.dustLevel.toFixed(0) : null,
      co2: this.averageForLastDay.co2 !== null
        ? this.averageForLastDay.co2.toFixed(0) : null,
    };
    Object.defineProperty(this, 'averagedMeasures', { value: averagedMeasures });

    return averagedMeasures;
  }

  get measureStatuses() {
    const measureStatuses = {
      temperature: this.getStatusForLastHour(
        this.averageForLastHour.temperature.toFixed(1),
        this.minTemperature,
        this.maxTemperature,
      ),
      humidity: this.getStatusForLastHour(
        this.averageForLastHour.humidity.toFixed(0),
        this.minHumidity,
        this.maxHumidity,
      ),
      dustLevel: this.getStatusForLastHour(
        this.averageForLastHour.dustLevel.toFixed(0),
        this.minDust,
        this.maxDust,
      ),
      co2: this.getStatusForLastHour(
        this.averageForLastHour.co2.toFixed(0),
        this.minCo2,
        this.maxCo2,
      ),
    };
    Object.defineProperty(this, 'measureStatuses', { value: measureStatuses });

    return measureStatuses;
  }

  get trend() {
    const trend = {
      temperature: calculateTrend(this.getLastEightSamples('temperature')),
      humidity: calculateTrend(this.getLastEightSamples('humidity')),
      dustLevel: calculateTrend(this.getLastEightSamples('dust_level')),
      co2: calculateTrend(this.getLastEightSamples('co2')),
    };
    Object.defineProperty(this, 'trend', { value: trend });

    return trend;
  }

  get chartsData() {
    const chartsData = [{
      id: this.device.id,
      title: i18n.t('dashboard.table.pm'),
      unit: ' \u00b5g/m3',
      icon: 'mdi-smog',
      iconColor: 'brown',
      name: 'dust_level',
      minY: this.minDust,
      maxY: this.maxDust,
    },
    {
      id: this.device.id,
      title: i18n.t('dashboard.table.ppm'),
      unit: ' \u00b5g/m3',
      icon: 'mdi-molecule-co2',
      iconColor: 'grey',
      name: 'co2',
      minY: this.minCo2,
      maxY: this.maxCo2,
    },
    {
      id: this.device.id,
      title: i18n.t('dashboard.table.temperature'),
      unit: ' °C',
      icon: 'mdi-thermometer',
      iconColor: 'green',
      name: 'temperature',
      minY: this.minTemperature,
      maxY: this.maxTemperature,
    },
    {
      id: this.device.id,
      title: i18n.t('dashboard.table.humidity'),
      unit: ' %',
      icon: 'mdi-water-percent',
      iconColor: 'blue',
      name: 'humidity',
      minY: this.minHumidity,
      maxY: this.maxHumidity,
    }];
    Object.defineProperty(this, 'chartsData', { value: chartsData });

    return chartsData;
  }

  getLastEightSamples(name) { // private
    const samples = this.lastEightSamples.map((e) => e[name]);
    const reverseSamples = samples.reverse();

    return reverseSamples;
  }

  getStatusForLastHour(lastHourAverage, min, max) { // private
    if (min <= parseFloat(lastHourAverage) && parseFloat(lastHourAverage) <= max) {
      return EnvironmentStatus.GOOD_CONDITIONS;
    }

    return EnvironmentStatus.BAD_CONDITIONS;
  }
}
