<template>
  <v-card class="fill-height widget">
    <v-toolbar height="32" dense flat class="widget__header">
      <span class="widget__subtitle">{{ title }}</span>
    </v-toolbar>

    <div class="widget__content widget__content--scrollable">
      <component
        :is="type"
        v-bind="widgetProps"
        @location:selected="toLocationDetails"
        @chart:clicked="onChartClicked"
      />
    </div>
  </v-card>
</template>

<script>
import WidgetCleaningActivityChart from '@/components/Widget/CleaningActivityChart';
import WidgetCleaningStatus from '@/components/Widget/CleaningStatus';
import WidgetCleaningStatusChart from '@/components/Widget/CleaningStatusChart';
import WidgetLocationMap from '@/components/Widget/LocationMap';
import WidgetEnvironmentStatus from '@/components/Widget/EnvironmentStatus';
import WidgetEnvironmentStatusChart from '@/components/Widget/EnvironmentStatusChart';

const availableDashboardWidgets = [
  'dpTable',
  'dpDashboardGraphPie',
  WidgetCleaningStatus.name,
  WidgetCleaningStatusChart.name,
  WidgetCleaningActivityChart.name,
  WidgetLocationMap.name,
  WidgetEnvironmentStatus.name,
  WidgetEnvironmentStatusChart.name,
];

export default {
  name: 'WidgetCustom',
  components: {
    WidgetCleaningActivityChart,
    WidgetCleaningStatus,
    WidgetCleaningStatusChart,
    WidgetLocationMap,
    WidgetEnvironmentStatus,
    WidgetEnvironmentStatusChart,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
      validator: (value) => {
        if (availableDashboardWidgets.indexOf(value) !== -1) {
          return true;
        }
        // eslint-disable-next-line no-console
        console.warn(`Component "${value}" is not registered as valid custom widget.`);

        return true;
      },
    },
    title: {
      type: String,
      default: '',
    },
    widgetProps: {
      type: Object,
      required: true,
    },
  },
  methods: {
    removeComponent() {
      this.$emit('remove', this.id);
    },
    toLocationDetails(location) {
      this.$emit('location:selected', location);
    },
    onChartClicked(cleaningstatus) {
      this.$emit('status:selected', cleaningstatus);
    },
  },
};
</script>

<style lang="scss" scoped>
  $headerHeight: 32px;
  $headerBorderHeight: 1px;

  .widget {
    &__header {
      height: $headerHeight;
      &:after {
        content: "";
        position: absolute;
        bottom: -1px;
        left: 16px;
        right: 16px;
        background-color: rgba(0, 42, 76, 0.1);
        height: 1px;
        transition: left 0.3s ease, right 0.3s ease;
      }
    }
    &__actions {
      &:before {
        display: none;
      }
    }
    &__subtitle {
      font-size: 13px;
      line-height: 30px;
      font-weight: bold;
    }
    &__content {
      padding: 16px;
      &--scrollable {
        position: relative;
        height: calc(100% - #{$headerHeight} - #{$headerBorderHeight});
        margin: $headerBorderHeight auto auto auto;
      }
    }
  }

</style>
