<template>
  <div>
    <v-app-bar elevation="2" app color="white">
      <v-toolbar-title>
        {{ title }}
      </v-toolbar-title>
      <slot />
      <template #extension>
        <DashboardFilter />
        <div v-if="isSuperAdmin">
          <PartnerFilter />
        </div>
        <div v-if="isAdminLeader">
          <ClientFilter :email="email" />
        </div>
        <div v-if="isAdminLeader">
          <MidLeaderFilter :email="email" />
        </div>
      </template>
      <div>
        <v-autocomplete
          v-model="currentLocation"
          flat
          solo
          :loading="$apollo.queries.locations.loading"
          :items="locations"
          item-text="name"
          item-value="id"
          :label="$t('form.label.allLocations')"
          hide-no-data
          hide-details
          clearable
          prepend-icon="mdi-map-marker"
          @change="toLocationDetails"
        />
      </div>
      <v-spacer />
      <v-menu
        v-if="isNotEnvironment"
        ref="menu"
        :close-on-content-click="false"
        offset-y
        offset-x
        right
      >
        <template #activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            small
            color="white"
            
            v-on="on"
          >
            <v-icon>mdi-file-export</v-icon>
            {{ exportText }}
          </v-btn>
        </template>
        <v-date-picker
          v-model="month"
          type="month"
          :locale="language"
          color="grey"
          :max="maxMonth"
        >
          <v-spacer />
          <v-btn
            text
            class="mr-5"
            color="primary"
            @click="$refs.menu.save(month)"
            @click.stop="generateReport"
          >
            OK
          </v-btn>
        </v-date-picker>
      </v-menu>
      <v-btn
        class="ml-1"
        small
        color="white"
        @click="shareLink"
      >
        {{ $t('form.label.share') }}
      </v-btn>
    </v-app-bar>
    <Report
      ref="report"
      :category="categoryForReport"
      :month="monthForReport"
    />
    <v-dialog v-model="enableForm" max-width="500px">
      <Form
        :locations="locationsForForm"
        @close="closeForm"
        @submit="submit"
      />
    </v-dialog>
  </div>
</template>

<script>
import { shareLink } from '@/helpers/share';
import { GET_LOCATIONS_FOR_DEVICES } from '@/graphql/location.graphql';
import { capitalize } from '@/helpers/string';
import DashboardFilter from '@/components/DashboardFilter';
import MidLeaderFilter from '@/components/MidLeaderFilter';
import ClientFilter from '@/components/ClientFilter';
import PartnerFilter from '@/components/PartnerFilter';
import { getKeysArrayByValue } from '@/helpers/objectExtraction';
import { SubcategoriesCategories } from '@/model/CategoriesObjects';
import i18n from '@/plugin/i18n';
import Report from '@/components/Reports/Report';
import Form from '@/components/Reports/Form';

import {
  format,
} from 'date-fns';

export default {
  name: 'DashboardBar',
  components: {
    DashboardFilter,
    Report,
    Form,
    ClientFilter,
    PartnerFilter,
    MidLeaderFilter,
  },
  inject: ['authGuard'],
  props: {
    category: {
      type: String,
      required: true,
    },
    locationId: {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      currentLocation: parseInt(this.locationId, 10),
      locations: [],
      shareLink,
      exportText: this.$t('report.title'),
      language: i18n.locale,
      month: format(new Date(), 'yyyy-MM'),
      maxMonth: format(new Date(), 'yyyy-MM'),
      selectedLocations: [],
      enableForm: false,
    };
  },
  computed: {
    email() {
      return this.authGuard.user.email;
    },
    isAdminLeader() {
      return this.authGuard.user.role === 'n3-admin' || this.authGuard.user.role === 'n3-superadmin';
    },
    isSuperAdmin() {
      return this.authGuard.user.role === 'n3-superadmin';
    },
    isNotEnvironment() {
      return this.category !== 'environment';
    },
    title() {
      if (this.category === 'robots') {
        return `N3${capitalize('assets')}`;
      }

      return `N3${capitalize(this.category)}`;
    },
    subcategoryNames() {
      let categoryName;
      if (this.category === 'robots') {
        categoryName = `N3${capitalize('assets')}`;
      } else {
        categoryName = `N3${capitalize(this.category)}`;
      }

      return getKeysArrayByValue(SubcategoriesCategories, categoryName);
    },
    categoryForReport() {
      return this.category;
    },
    monthForReport() {
      return this.month;
    },
    locationsForForm() {
      return this.selectedLocations;
    },
  },
  watch: {
    locationId(newVal) {
      this.currentLocation = parseInt(newVal, 10);
    },
  },
  // TODO
  // Leaving this here for later work <- when we remove categories from db
  apollo: {
    locations: {
      query: GET_LOCATIONS_FOR_DEVICES,
      variables() {
        return {
          subcategoryNames: this.subcategoryNames,
        };
      },
    },
  },
  methods: {
    toLocationDetails(locationId) {
      if (locationId) {
        this.$router.push({ name: 'dashboard.location', params: { locationId } });
      }
    },
    updateUrl(cleaningStatuses) {
      const { name, params } = this.$route;
      let query = null;
      if (cleaningStatuses.length) {
        query = { status: cleaningStatuses.join(',') };
      }

      this.$router.push({ name, params, query });
    },
    generateReport() {
      if (this.locationId) {
        const locationName = this.locations.filter((e) => e.id === parseInt(this.locationId, 10));
        this.selectedLocations = [parseInt(this.locationId, 10)];
        this.$refs.report.generateReport(this.selectedLocations, 'single', locationName[0].name);
      } else {
        this.selectedLocations = this.locations;
        this.enableForm = true;
      }
    },
    closeForm() {
      this.enableForm = false;
    },
    submit({ locations, client }) {
      this.$refs.report.generateReport(locations, 'many', client);
      this.enableForm = false;
    },
  },
};
</script>

<style lang="scss" scoped>

@import './src/styles/variables';

.v-toolbar {
  &__title {
    margin-right: 10px;
    font-size: 1.15rem;
    line-height: 1.35;
  }
}

::v-deep .v-toolbar__extension {
  border-top: thin solid rgba(0, 0, 0, 0.12);
}

.table {
  margin-right: 10px;
}

.reports-filter {
  display: flex;
  color: lighten($text-color, 20%);

  &__label {
    margin-right: 10px;
  }

  &__option {
    margin-top: 0;
  }

  &__button {
    &--active {
      color: green;

      span {
        color: black;
      }
    }
  }
}
</style>
