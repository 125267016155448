<template>
  <div>
    <LoaderWidgetLoader v-if="loading" />
    <div v-else class="v-data-table theme--light">
      <div class="v-data-table__wrapper">
        <table>
          <thead class="v-data-table-header">
            <tr>
              <th role="columnheader" class="text-start">
                <span>{{ $t('dashboard.last7') }}</span>
              </th>
              <th v-if="category==='robots'" role="columnheader" class="text-start">
                <span>{{ $t('dashboard.externalEquipment') }}</span>
              </th>
              <th role="columnheader" class="text-start">
                <span>{{ $t('dashboard.average') }}</span>
              </th>
              <th
                v-for="(date, index) in dates"
                :key="index"
                role="columnheader"
                class="text-start"
              >
                <span>{{ new Intl.DateTimeFormat(language,
                                                 { weekday: 'short' }).format(date) }}</span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="text-start" colspan="2">
                {{ $t('dashboard.status') }}
              </td>
              <td v-if="category==='robots'" />
              <td
                v-for="(date, index) in dates"
                :key="index"
                class="text-start"
              >
                <BadgeCleaningStatus
                  :cleaning-status="location.getStatusForSpecificDay(date, category)"
                />
              </td>
            </tr>
            <tr v-for="zone in location.zone" :key="zone.id">
              <td class="text-start">
                {{ zone.name }}
              </td>
              <td v-if="category==='robots'" class="text-start">
                <span>{{ zone.device.externalEquipment }}</span>
              </td>
              <td class="text-start">
                {{ zone.averageCleaningDuration }}
              </td>
              <td
                v-for="(date, index) in dates"
                :key="index"
                class="text-start"
              >
                <span v-if="zone.isWorkingAt(date)">
                  {{ zone.getCleaningDuration(date) }}
                </span>
                <span v-else>
                  <span v-if="zone.getCleaningDuration(date) > 0">
                    off ({{ zone.getCleaningDuration(date) }})
                  </span>
                  <span v-else>off</span>
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>

import BadgeCleaningStatus from '@/components/Badge/CleaningStatus';
import LoaderWidgetLoader from '@/components/Loader/WidgetLoader';
import i18n from '@/plugin/i18n';

export default {
  name: 'WidgetCleaningStatusWeekly',
  components: {
    BadgeCleaningStatus,
    LoaderWidgetLoader,
  },
  props: {
    category: {
      type: String,
      required: true,
    },
    location: {
      type: Object,
      required: true,
    },
    dates: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      language: i18n.locale,
    };
  },
  computed: {
    loading() {
      if (Object.keys(this.location).length) {
        return false;
      }

      return true;
    },
  },
};

</script>
