<template>
  <v-chip :color="color" dark>
    {{ text }}
  </v-chip>
</template>
<script>

import { CleaningStatus } from '@/model/CleaningStatus';
import { theme } from '@/configuration';

export default {
  name: 'BadgeCleaningStatus',
  props: {
    cleaningStatus: {
      type: Number,
      required: true,
    },
  },
  computed: {
    color() {
      return theme.colors.cleaningStatusColor[this.cleaningStatus];
    },
    text() {
      switch (this.cleaningStatus) {
        case CleaningStatus.CLEANED_FULL:
          return 'C';
        case CleaningStatus.CLEANED_PARTLY:
          return 'P';
        case CleaningStatus.CLEANED_NONE:
          return 'N';
        case CleaningStatus.CLEANED_NOT_REQUIRED:
          return 'O';
        default:
          return '';
      }
    },
  },
};

</script>
