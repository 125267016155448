<template>
  <vue-html2pdf
    ref="html2Pdf"
    :show-layout="false"
    :float-layout="true"
    :enable-download="true"
    :preview-modal="false"
    :paginate-elements-by-height="1400"
    :manual-pagination="false"
    :html-to-pdf-options="{
      margin:10,
      filename: fileName,
      image: {
        type: 'jpeg',
        quality:2
      },
      jsPDF: {
        unit: 'mm',
        format: 'a4',
        orientation: 'landscape'
      },
      pagebreak: {
        mode: ['css','legacy'],
      }
    }"
    pdf-content-width="1000px"
  >
    <section slot="pdf-content">
      <section class="pdf-item">
        <div style="position: relative; height: 75px">
          <div style="position: absolute; right: 0;">
            <div style="text-align: right;">
              <img src="@/assets/logoReport.png" height="50">
            </div>
            <h2 style="text-align:right;font-size:10px">
              {{ currentTime }}
            </h2>
          </div>
          <div style="position: absolute; left: 0; right: 0; margin-left: auto; margin-right: auto;">
            <h2 style="text-align:center;">
              {{ title }}
            </h2>
            <br>
            <h3 style="text-align:center;">
              {{ reportName }}
            </h3>
          </div>
        </div>
        <br>
      </section>
      <section class="pdf-item">
        <CleaningMonthlyReport
          :report="report"
          :dates="dates"
          :type-of-report="type"
        />
      </section>
      <div v-if="type==='many'" class="html2pdf__page-break" />
      <section class="pdf-item">
        <CleaningMonthlyReportChart
          :total-expected-time="expectedTimes"
          :total-cleaning-time="cleaningTimes"
        />
      </section>
    </section>
  </vue-html2pdf>
</template>
<script>

import VueHtml2pdf from 'vue-html2pdf';
import {
  format, sub, eachDayOfInterval, setDate,
} from 'date-fns';
import { en, nb } from 'date-fns/locale';
import { fetchMonthlyDataForLocations } from '@/data/locations';
import CleaningMonthlyReportChart from '@/components/Widget/CleaningMonthlyReportChart';
import CleaningMonthlyReport from '@/components/Widget/CleaningMonthlyReport';
import i18n from '@/plugin/i18n';

export default {
  name: 'Report',
  components: {
    VueHtml2pdf,
    CleaningMonthlyReportChart,
    CleaningMonthlyReport,
  },
  props: {
    month: {
      type: String,
      required: true,
    },
    category: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      report: {},
      dates: [],
      expectedTimes: [],
      cleaningTimes: [],
      title: '',
      reportName: '',
      language: i18n.locale,
      type: '',
    };
  },
  computed: {
    fileName() {
      const date = new Date();

      return `${this.reportName.split(' ').join('_')}_${this.category}_Oversikt_for_${this.title}_${format(date, 'yyyy-MM-dd_HH:mm')}`;
    },
    currentTime() {
      return format(new Date(), 'yyyy.MM.dd HH:mm:ss');
    },
    is_categoryZone() {
      return (this.category === 'zones');
    },
  },
  methods: {
    async generateReport(locations, type, mainName) {
      const [year, month] = this.month.split('-');
      const monthDate = setDate(new Date(parseInt(year, 10), parseInt(month, 10) - 1, 2), 1);
      const nextMonthDate = setDate(new Date(parseInt(year, 10), parseInt(month, 10), 2), 1);
      const locale = (this.language === 'en') ? en : nb;
      this.type = type;
      this.title = format(monthDate, 'LLLL', { locale });
      this.reportName = mainName;
      this.dates = this.getAllDatesForSpecificMonth(monthDate, nextMonthDate);
      this.report = await fetchMonthlyDataForLocations(locations, format(monthDate, 'yyyy-MM-dd'), format(nextMonthDate, 'yyyy-MM-dd'), this.is_categoryZone, !this.is_categoryZone);
      this.cleaningTimes = this.report.cleaningTimes(this.dates);
      this.expectedTimes = this.report.expectedTimes(this.dates);
      this.$refs.html2Pdf.generatePdf();
    },
    getAllDatesForSpecificMonth(monthDate, nextMonthDate) {
      const formattedDates = [];
      const dates = eachDayOfInterval({
        start: new Date(format(monthDate, 'yyyy-MM-dd')),
        end: sub(new Date(format(nextMonthDate, 'yyyy-MM-dd')), { days: 1 }),
      });
      dates.forEach((date) => {
        formattedDates.push(format(date, 'yyyy-MM-dd'));
      });

      return formattedDates;
    },
  },
};
</script>
