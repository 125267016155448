import { apolloClient } from '@/integration/apollo';
import { Zones, ZoneDataToExport } from '@/graphql/query.graphql';
import { findById } from '@/data/locations';

export async function findForLocations(locationIds = []) {
  const loc = await apolloClient.query({
    query: Zones,
    variables: {
      locations: locationIds,
    },
  });

  return loc.data.zones;
}

/**
 * Rename this method
 * @param {Number} locationId
 * @param {Date} from
 * @param {Date} to
 * @param {String} category
 * @returns {Promise<Location>}
 */
export async function getZoneCleanings(locationId, from, to, category) {
  const response = await findById(locationId, category === 'zones', from, to);

  return response[0];
}

async function getDeviceDataForExport(ids, onlyZones, dateStart, dateStop) {
  const response = await apolloClient.query({
    query: ZoneDataToExport,
    variables: {
      ids,
      date_start: dateStart,
      date_stop: dateStop,
      categoryZone: !!onlyZones,
      categoryRobot: !onlyZones,
    },
    fetchPolicy: 'no-cache',
  });

  return response.data.zone;
}

export function getZonesDataForExport(ids, dateInterval) {
  return getDeviceDataForExport(ids, true, dateInterval.start, dateInterval.end);
}

export function getRobotsDataForExport(ids, dateInterval) {
  return getDeviceDataForExport(ids, false, dateInterval.start, dateInterval.end);
}
